import * as React from "react";
import { useEffect } from "react";
import { Field } from "@progress/kendo-react-form";
import {
    FormInput,
    FormAutoComplete,
    FormRadioGroup,
    FormTextArea,
} from "./form-components";
import { nameValidator, requiredValidator } from "./validators";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function YourContactInfo(props) {

    return (
        <div className="kendo-form">
            <p className="font-weight-bold mt-3 d-block d-sm-none text-center">
                Your Contact Info
            </p>
            <br />
            <h5 className="wizardStepHeadLabel">
                Please enter the following information below. 
                The person listed in this section will be the person responsible for running and coordinating all supply kit sale details.
                Your coordinator will be contacting this person throughout the sale via email and phone. At SchoolKidz, we take privacy of our customers 
                information seriously.  We will never rent or sell your personal information to any 3rd parties. 
            </h5>
            <div className="row text-xs tracking-wide font-bold">
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">First name</label>
                    <Field
                        key={"contact_Step2.firstname"}
                        id={"contact_Step2.firstname"}
                        name={"contact_Step2.firstname"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Residential Address</label>
                    <Field
                        key={"contact_Step2.address1_line1"}
                        id={"contact_Step2.address1_line1"}
                        name={"contact_Step2.address1_line1"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Last name</label>
                    <Field
                        key={"contact_Step2.lastnam"}
                        id={"contact_Step2.lastname"}
                        name={"contact_Step2.lastname"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Ste/Apt #</label>
                    <Field
                        key={"contact_Step2.address1_line2"}
                        id={"contact_Step2.address1_line2"}
                        name={"contact_Step2.address1_line2"}
                        component={FormInput}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Phone</label>
                    <Field
                        key={"contact_Step2.telephone1"}
                        id={"contact_Step2.telephone1"}
                        name={"contact_Step2.telephone1"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">City</label>
                    <Field
                        key={"contact_Step2.address1_city"}
                        id={"contact_Step2.address1_city"}
                        name={"contact_Step2.address1_city"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Email</label>
                    <Field
                        key={"contact_Step2.emailaddress1"}
                        id={"contact_Step2.emailaddress1"}
                        name={"contact_Step2.emailaddress1"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">State</label>
                    <Field
                        key={"contact_Step2.tt_stateprovinceid"}
                        id={"contact_Step2.tt_stateprovinceid"}
                        name={"contact_Step2.tt_stateprovinceid"}
                        textField={"state_province_name"}
                        dataItemKey={"state_province_id"}
                        data={props.stateProvinceListModel}
                        component={DropDownList}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Zip</label>
                    <Field
                        key={"contact_Step2.address1_postalcode"}
                        id={"contact_Step2.address1_postalcode"}
                        name={"contact_Step2.address1_postalcode"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
            </div>
            <br />
        </div>
    );
}
