import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes,useNavigate } from 'react-router-dom'
import Changepassword from './changepassword.js';
import Userdetails from './userdetails'
import APIConnector from '../../../apiconnector';
import CommonVariables from '../../../variables.js';
import Notifications from '../../../Notifications/Messages';
import userImage from '../../../images/user.png';
import $ from 'jquery';
import "./profile.css"

/**
 * This component enables user to edit his details
 * 
 */

function Profile(props) {
    const navigation=useNavigate()
    const [userData, setUserData] = useState({});
    useEffect(function () {
        props.onLoaderRaise(true, "Getting profile info ....", { marginTop: "145px" });
        var userdata = {
            userId: CommonVariables.getlocalstorageData().userId
        };
        APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            resp.firstName = resp.firstName ? resp.firstName : "";
            resp.lastName = resp.lastName ? resp.lastName : "";
            resp.gender = resp.gender ? resp.gender : "";
            resp.phone = resp.phone ? resp.phone : "";
            resp.state = resp.state ? resp.state : "";
            resp.email = resp.email ? resp.email : "";
            setUserData(resp);
        });
    }, []);

    //camerea functions
    /***************************************/
    function stopBothVideoAndAudio() {
        var video = document.querySelector("video");
        $(".askchoice-profile").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: false })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    }

    var constraints = {
        video: true,
    };

    function capture() {
        $("#player").addClass("hidden")
        $("#retake").removeClass("hidden")
        $(".showCanvasAndBtn").removeClass("hidden")
        const player = document.getElementById('player');
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            // video.srcObject = stream;
            player.srcObject = stream;
            // video.srcObject = stream;
        });
        // Draw the video frame to the canvas.
        context.drawImage(player, 0, 0, canvas.width, canvas.height);

        const imageurl = canvas.toDataURL();
        var UpdateProfile = {
            userId: CommonVariables.getlocalstorageData().userId,
            image: imageurl,
        };
        debugger;
        document.querySelector(".uploadBtn").addEventListener("click", () => {
            var userdata = {
                userId: localStorage.getItem("userId"),
            };
            props.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
            APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
                props.onLoaderRaise(false);
                if (!CommonVariables.isError(resp))
                    return;
                if (resp.userName) {
                    setUserData(resp);
                }
                var payload = userData;
                payload.image = UpdateProfile.image;
                props.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                APIConnector.Post(CommonVariables.urls.UpdateProfile, payload).then(function (response) {
                    props.onLoaderRaise(false);
                    if (!CommonVariables.isError(response))
                        return;
                    if (response.isSuccess) {
                        stopBothVideoAndAudio();
                        Notifications.ShowSuccessMessage("Image uploaded Successfully")
                        Notifications.ShowSuccessMessage("Please Login again to effect the changes");
                        // window.location.reload(true);
                        navigation("/")
                    }

                    else {
                        Notifications.ShowErrorMessage("Some error occured, Try again")
                    }
                })
            });
        });
    }
    function retake() {
        $("#player").removeClass("hidden")
        $("#canvas").addClass("hidden")
    };

    function uploadPhoto() {
        document.querySelector(".uploaded-image").onchange()
    };


    function takePicture() {
        var video = document.querySelector("video");
        $(".askchoice-profile").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    //camerea functions end



    return (<>
        <div className=" mx-4 bg-gray-100 profile-main-div rounded-xl gap-8 gap-x-0 shadow border p-8 m-10 mt-20" style={{}}>
            <div className='userdiv flex justify-between p-1'>
                <p className="text-3xl text-gray-600  font-bold sm:self-end">
                    Profile
                </p>
                <div className='flex flex-col gap-2 justify-end'>
                    <Link to="Userdetails" className='button-edit self-end'>Edit</Link>
                    <Link to="Changepassword" className='button-edit'>Change Password</Link>
                </div>
                </div>
            <div className='sm:flex flex-col md:flex-row   flex gap-5 justify-start flex-div'>
                <div className='mx-auto sm:w-3/12   flex flex-col bg-white justify-center p-3 rounded-xl'>   
                    <img className='profile-div-image inline-block w-12/12 rounded-xl' alt="Account" src={userData.image != null ? userData.image : userImage} style={{height:"50%",width:"50%",marginLeft:"20%"}}/>
                    <ul className='py-2 pt-4 flex flex-column gap-2 text-gray-600'>
                        <li className='text-3xl text-center sm:text-left font-bold' >{userData.firstName + " " + userData.lastName}</li>
                    </ul>     
                    <div className='askchoice-profile absolute hidden bg-gray-100 w-60 h-72 pt-12' style={{border:"1px solid",textAlign:"center"}}>
        <input className='border-2 border-gray-400 text-black text-sm w-full uploaded-image' accept="image/png, .jpeg, .jpg" type="file" onChange={(e) => {
            if (e.target.files && e.target.files.length) {
                (getBase64(e.target.files[0])).then(function (data) {
                    var userdata = {
                        userId: localStorage.getItem("userId"),
                    };
                    props.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                    APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
                        props.onLoaderRaise(false);
                        if (!CommonVariables.isError(resp))
                            return;
                        if (resp.userName) {
                            setUserData(resp);
                        }
                        var payload = userData;
                        payload.image = data;
                        props.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                        APIConnector.Post(CommonVariables.urls.UpdateProfile, payload).then(function (response) {
                            props.onLoaderRaise(false);
                            if (!CommonVariables.isError(response))
                                return;
                            if (response.isSuccess) {
                                Notifications.ShowSuccessMessage("Image uploaded Successfully")
                                Notifications.ShowSuccessMessage("Please Login again to effect the changes");
                                window.location.reload(true);
                            }
                            else {
                                Notifications.ShowErrorMessage("Some error occured, Try again")
                                }
                            })
                            });
                        });
                    }
                }} /><br />
            <button className='border-2 text-sm w-full take-picture border-gray-400 text-black' onClick={takePicture}>take picture</button><br /> <br />
            <button className='button-save mr-2' onClick={uploadPhoto}>Upload</button>
            <button className='button-cancel' onClick={() => { $(".askchoice-profile").addClass("hidden") }}>Cancel</button>
            </div>
            <div className='video absolute hidden text-black z-10' style={{}}>
            <video id="player" autoPlay={true} ></video>

            <canvas id="canvas" width="" height="225vh" className='w-full showCanvasAndBtn hidden'></canvas>
            <button id="retake" className='bg-green-400 cam-btns p-2 m-1 hidden' onClick={retake} >Retake</button>
            <button id="capture" className='bg-green-400 cam-btns px-2 py-1 m-1' onClick={capture} ><span className="p-0 material-symbols-outlined">
                photo_camera
            </span></button>
            <button className='bg-blue-400 p-2 cam-btns hidden showCanvasAndBtn uploadBtn' onClick={() => {
                var video = document.querySelector("video");
                debugger;
                if (true) {
                    navigator.mediaDevices.getUserMedia({ video: false })
                        .then(mediaStream => {
                            const stream = mediaStream;
                            const tracks = stream.getTracks()
                            tracks[0].stop();
                        })
                }
            }}>Upload</button>
            </div>            
                    <span style={{marginLeft:"38%"}}  onClick={() => {
                    $(".askchoice-profile").removeClass("hidden"); $(".video").addClass("hidden");}} 
                    class="camera-icon-profile text-dark material-symbols-outlined hover:cursor-pointer">
                    photo_camera</span>
                    </div>
                <div className='text-gray-700 w-full px-3 sm:px-10 bg-white inline-block pt-4 rounded-xl'>
                    <Routes>
                        <Route path="/" element={
                            <ul className='grid sm:grid-cols-2 w-4/12 sm:w-9/12 md:w-8/12 xl:w-4/12 2xl:w-3/12 text-md gap-x-0 gap-y-2 sm:gap-y-2 md:gap-y-0 xl:gap-y-4 2xl:gap-y-4'>
                                <li className='label'>Full Name</li>
                                <li className='self-center prompt'>{userData.firstName + " " + userData.lastName}</li>
                                <li className='label'>Email Address</li>
                                <li className='self-center prompt'>{userData.email}</li>
                                <li className='label'>Phone no.</li>
                                <li className='self-center prompt'>{userData.phone}</li>
                            </ul>
                        } />
                        <Route path="Changepassword" element={<Changepassword onLoaderRaise={props.onLoaderRaise} userData={userData} />} />
                        <Route path="Userdetails" element={<Userdetails onLoaderRaise={props.onLoaderRaise} userData={userData} />} />
                    </Routes>
                </div>
            </div>
        </div>
        
    </>);
}
export default Profile;