import * as React from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CancelIcon from '@mui/icons-material/Cancel';
import { NumericTextBox } from "@progress/kendo-react-inputs";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import { data, event } from "jquery";
import { Store } from "@mui/icons-material";
export const NotesCell = (props) => {

    const { dataItem } = props
    return (
        <td colSpan="1" className="k-grid-edit-cell" aria-colindex="9" aria-selected="false" data-grid-col-index="8" role="gridcell">
            {dataItem.inEdit ? <textarea className="k-input" value={dataItem.new_item} /> : dataItem.new_item}</td>
    );
};

export const QtyCell = (props) => {


    const { dataItem } = props
    return (
        <td colSpan="1" className="k-grid-edit-cell" aria-colindex="9" aria-selected="false" data-grid-col-index="8" role="gridcell">
            {dataItem.inEdit ? <NumericTextBox value={dataItem.new_qty} /> : dataItem.new_qty}</td>
    );
};

export const EditCell = (props) => {
    const { dataItem } = props
    return (
        <td colSpan="1" className="k-grid-edit-cell" aria-colindex="9" aria-selected="false" data-grid-col-index="8" role="gridcell" style={{ textAlign: "center" }}>
            {
                dataItem.inEdit ?
                    (
                        dataItem.isNotSaved ?
                            <span title="save">
                                <SaveIcon onClick={() => props.save(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                            </span> :
                            (
                                <span title="update">
                                    <SaveIcon onClick={() => props.update(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                                </span>
                            )
                    ) :
                    (
                        <span title="Edit">
                            <EditIcon onClick={() => props.editField(dataItem)} title="Print" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                        </span>
                    )
            }
        </td>
    );
};

export const CancelCell = props => {
    const { dataItem } = props;
    return <td className="k-command-cell" style={{ textAlign: "center" }}>
        {
            ((!dataItem.changed || dataItem.isNotSaved) && (dataItem.request_type !== "Delete Item")) ? <p></p>
                : <span title={dataItem.request_type !== "Delete Item" ? "cancel" : "Undo change"}>
                    <CancelIcon disabled={dataItem.changed} onClick={(e) => props.cancel(dataItem,e)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                </span>
        }
    </td>
};


export const DeleteCell = props => {
    const { dataItem } = props;
    return <td className="k-command-cell" style={{ textAlign: "center" }}>
        <span title="Delete">
            <DeleteIcon onClick={() => props.remove(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
        </span>
    </td>
};

export const NewItemCell = props => {
    const [newItemFieldValue, setNewItemFieldValue] = React.useState();

    React.useEffect(() => {
    setNewItemFieldValue(dataItem.new_item)
    }, []);

    const { dataItem } = props;


    const onNewItemFieldChange = (event) => {
        if (dataItem.request_type === "Delete Item") {
            return;
        }
        setNewItemFieldValue(event.target.value)
    };

    return <td className="k-command-cell" style={{ textAlign: "center" }}>
        <input onChange={(event) => onNewItemFieldChange(event)} onBlur={() => props.updateInputField(dataItem, newItemFieldValue)} value={newItemFieldValue} style={{ "display": "inline-block", "borderColor": "transparent"}} />
    </td>
};

