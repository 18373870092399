import React, { useEffect, useState } from 'react';
import APIConnector from '../../../../apiconnector';
import CommonVariables from '../../../../variables';
import './authUser.css'
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Edituser from './edituser';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Notifications from '../../../../Notifications/Messages';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import userImage from '../../../../images/user.png';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

/**
 * This component enables to impersonate, approve,accept a contract, sending emails and remove the users 
 * 
 */

function AuthUser(props) {

    //#region " Initialization of State  Values       "
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState()
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "custnmbr",
            operator: "contains",
            value: '',
        }]
    };
    const [filter, setFilter] = React.useState(initialFilter);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    //#endregion

    //#region "     On Load         "

    useEffect(() => {
        if (CommonVariables.getlocalstorageData().RoleName.toUpperCase() === "SCHOOLUSER") {
            navigate('/externaldashboard');
            return;
        }
        RefreshUserGrid();
    }, []);

    const RefreshUserGrid = (msg) => {
        props.onLoaderRaise(true, "Getting users list ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetAuthUsers).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;

            response = response.map((item) => {
                item.RoleDisplayName = GetRoleDisplayname(item.roleName);
                return item;
            });
            setData(response);
            setEditItem({});
        });
    };

    const GetRoleDisplayname = (RoleName) => {
        switch (RoleName) {
            case "InternalAdmin":
                return 'Admin';
                break;
            case "SchoolCoordinator":
                return 'Sales Coordinator';
                break;
            case "SchoolRep":
                return 'Sales Rep';
                break;
            case "InternalUser":
                return 'Sales Operations';
                break;
            case "SchoolUser":
                return 'External User';
                break;
            default:
                return RoleName;
        }
    };
    //#endregion

    //#region "     Modal Dialog Events        "

    const EditCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                <span title="Edit">
                    <EditIcon onClick={() => props.enterEdit(props.dataItem)} title="Edit"
                        style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };

    const ImpersonateCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                <span title="Impersonate">
                    {
                        (props.dataItem.roleName && props.dataItem.roleName.toUpperCase() === "SCHOOLUSER") &&
                        (
                            <button onClick={() => props.enterImpersonate(props.dataItem)} className="button-delete" title="Impersonate">Impersonate</button>
                        )
                    }
                </span>
            </td>
        );
    };


    const DeleteCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                <span title="Delete">
                    {
                        (props.dataItem.roleName && props.dataItem.roleName.toUpperCase() !== "SCHOOLUSER") &&
                        (
                            <DeleteIcon onClick={() => props.enterdelete(props.dataItem)}
                                style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                        )
                    }
                </span>
            </td>
        );
    };

    const ProfileCommandCell = props => {
        return (
            <td>
                <span title="Profile Photo" style={{ "textAlign": "-webkit-center" }}>
                    <img src={props.dataItem.image != null ? props.dataItem.image : userImage}
                        style={{ "width": 70, "height": 70, "borderRadius": "50%" }} alt="Account" />
                </span>
            </td>
        );
    };

    const LockCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                {
                    (props.dataItem.roleName && props.dataItem.roleName.toUpperCase() === "SCHOOLUSER") &&
                    (
                        <>
                            {!props.dataItem.isAdminApproved && <span title="Waiting for approval">
                                <PersonOffIcon onClick={() => props.enterLock(props.dataItem)}
                                    style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                            </span>
                            }
                            {props.dataItem.isAdminApproved && <span title="Already Approved. Click to lock user">
                                <HowToRegIcon onClick={() => props.enterLock(props.dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "green", "cursor": "pointer" }} />
                            </span>
                            }
                        </>
                    )
                }
            </td>
        );
    };
    const ContractCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                {
                    (props.dataItem.roleName && props.dataItem.roleName.toUpperCase() === "SCHOOLUSER") &&
                    (
                        <>
                            {!props.dataItem.isContractAccepted && <span title="Contract not signed">
                                <DisabledByDefaultIcon onClick={() => props.signContract(props.dataItem)}
                                    style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                            </span>
                            }
                            {props.dataItem.isContractAccepted && <span title="Contract Signed">
                                <AssignmentTurnedInIcon style={{ "display": "inline-block", "borderColor": "transparent", "color": "green", "cursor": "not-allowed" }} />
                            </span>
                            }
                        </>
                    )
                }
            </td>
        );
    };

    const ResendEmailCommandCell = props => {
        return (
            <td style={{ textAlign: "center" }}>
                <span title="Resend Email">
                    {
                        (props.dataItem.roleName && props.dataItem.roleName.toUpperCase() === "SCHOOLUSER") &&
                        <ForwardToInboxIcon onClick={() => props.ResendEmail(props.dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "green", "cursor": "pointer" }} />
                    }
                </span>
            </td>
        );
    };

    //Sign in the Contract as a internal user
    const signContract = (dataItem) => {
        var alertDataObj = {
            title: "Please Confirm",
            body: "This action will sign in the contract on behalf of you as " + CommonVariables.getlocalstorageData().RoleName + ". Are you sure you want to sign in the contract?",
            No: AlertNo,
            NoTitle: "No",
            YesTitle: "Yes",
            Yes: signContractSubmit,
            datatoPassOnYes: dataItem
        };
        props.onAlert(true, alertDataObj);

    };

    const signContractSubmit = (dataItem) => {
        props.onAlert(false);
        let data = {
            Signature: "",
            initials: "Mr",//Only 3 characters are accepting
            enrollment: "Enrld",//Only 6 characters are accepting
            contacttypeother: "Contract signed on behalf of " + CommonVariables.getlocalstorageData().RoleName,
            firstname: dataItem.firstName,
            lastname: dataItem.lastName,
            mobilephone: dataItem.phone,
            telephone1: dataItem.phone,
            userId: dataItem.userId
        };
        props.onLoaderRaise(true, "Signing Contract....", { marginTop: "147px" });
        APIConnector.Post(CommonVariables.urls.contractAccept, data).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response || !response.isSuccess) {
                Notifications.ShowErrorMessage(response.message ? response.message : "Contract submission failed. Please try again later");
                return;
            }
            Notifications.ShowSuccessMessage("Contract submitted successfully");
            RefreshUserGrid();
        });
    };

    const enterLock = (item) => {
        if (!item.isContractAccepted) {
            Notifications.ShowWarningMessage("Selected User not signed any contract");
            return;
        }
        item.isNotActive = !item.isAdminApproved ? false : true;

        props.onLoaderRaise(true, "Approving please wait ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.ApproveUserAfterContract, item).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;

            if (item.isNotActive)
                Notifications.ShowErrorMessage("User In activated Successfully.");
            else
                Notifications.ShowSuccessMessage("User Activated Successfully.");
            RefreshUserGrid();
        });
    };

    const DeleteUserYes = (item) => {
        props.onAlert(false);

        props.onLoaderRaise(true, "Deleting User please wait ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.DeletUser, item).then(function (response) {
            props.onLoaderRaise(false);

            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            Notifications.ShowSuccessMessage("User Deleted Successfully");
            RefreshUserGrid();
        });
    };






    const enterdelete = (item) => {

        // if (item.roleName.toUpperCase()==="SCHOOLUSER") {
        //     Notifications.ShowInformationMessage("You cannot delete a school user.Please contact portal administrator.");
        //     return;
        // }


        var alertDataObj = {
            title: "Please Confirm",
            body: "This action will delete the user from the system.Are you sure you want to delete?",
            No: AlertNo,
            NoTitle: "No",
            YesTitle: "Yes",
            Yes: DeleteUserYes,
            datatoPassOnYes: item
        };
        props.onAlert(true, alertDataObj);

    };

    const handleSubmit = (event) => {
        if(event.isValid===false){
            return;
        }
        let inputData = event.values;

        if (inputData.contact && inputData.contact.contactid)
            inputData.contactId = inputData.contact.contactid;

        if (inputData.account && inputData.account.accountId)
            inputData.accountId = inputData.account.accountId;

        if (inputData.systemUser && inputData.systemUser.systemuserid)
            inputData.systemUserId = inputData.systemUser.systemuserid;

        // inputData.userName = event.email;

        props.onLoaderRaise(true, inputData.isCreateNewUserMode ? "Saving" : "Updating", { marginTop: "145px" });
        let url = editItem.isCreateNewUserMode ? CommonVariables.urls.CreateAuthUser : CommonVariables.urls.UpdateAuthUser;
        APIConnector.Post(url, inputData).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response || !response.isSuccess) {
                Notifications.ShowErrorMessage((response && response.message) ? response.message : "Failed to save");
                return;
            }
            setOpenForm(false);
            Notifications.ShowSuccessMessage(event.isCreateNewUserMode ? "User data saved successfully" : "User data updated successfully");
            RefreshUserGrid();
        });
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };
    const enterEdit = (item) => {
        setEditItem(item);
        setOpenForm(true);
    };

    const AlertNo = () => {
        props.onAlert(false);
    };
    const AlertYes = (item) => {
        props.onAlert(false);

        props.onLoaderRaise(true, "Impersonating please wait ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.Impersonate, item).then(function (response) {
            props.onLoaderRaise(false);

            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess || !response.tokenModel.tokens || !response.tokenModel.tokens.accessToken) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            Notifications.ShowSuccessMessage("Impersonation success");
            CommonVariables.setlocalStorageData({ tokens: response.tokenModel.tokens, userId: response.tokenModel.userId, email: item.email, RoleName: response.tokenModel.role });
            navigate('/externaldashboard');
        });
    };

    const enterImpersonate = (item) => {
        if (!item.isAdminApproved) {
            Notifications.ShowInformationMessage("Selected User is not approved");
            return;
        }
        if (!item.isContractAccepted) {
            Notifications.ShowWarningMessage("Selected User not signed any contract");
            return;
        }

        var alertDataObj = {
            title: "Please Confirm",
            body: "This action will logout from the current user and impersonate as the selected user. Are you sure you want to impersonate?",
            No: AlertNo,
            NoTitle: "No",
            YesTitle: "Yes",
            Yes: AlertYes,
            datatoPassOnYes: item
        };
        props.onAlert(true, alertDataObj);
    };

    const enterAdd = (roleName) => {
        setEditItem({ roleName: roleName, isCreateNewUserMode: true });
        setOpenForm(true);
    };


    const ResendEmail = (item) => {
        var alertDataObj = {
            title: "Please Confirm",
            body: "This action will resend an activation email to school coordinator. Are you sure you want to send email?",
            No: AlertNo,
            NoTitle: "No",
            YesTitle: "Yes",
            Yes: ResendEmailYes,
            datatoPassOnYes: item
        };
        props.onAlert(true, alertDataObj);
    };

    const ResendEmailYes = (item) => {
        props.onAlert(false);

        props.onLoaderRaise(true, "Resending email please wait ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.ResendEmail, item).then(function (response) {
            props.onLoaderRaise(false);

            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            Notifications.ShowSuccessMessage("Email sent successfully");;
        });
    };

    //#endregion

    const MyEditCommandCell = props => <EditCommandCell {...props} enterEdit={enterEdit} />;
    const MyImpersonateCommandCell = props => <ImpersonateCommandCell {...props} enterImpersonate={enterImpersonate} />;
    const MyDeleteCommandCell = props => <DeleteCommandCell {...props} enterdelete={enterdelete} />;
    const MyProfileCommandCell = props => <ProfileCommandCell {...props} />;
    const MyLockCommandCell = props => <LockCommandCell {...props} enterLock={enterLock} />;
    const MyContractCommandCell = props => <ContractCommandCell {...props} signContract={signContract} />;
    const MyResendEmailCommandCell = props => <ResendEmailCommandCell {...props} ResendEmail={ResendEmail} />;
    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">{props.heading}</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-6">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "userName",
                                label: "User Name",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "custnmbr",
                                label: "Acct #",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "schoolName",
                                label: "School Name",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "email",
                                label: "Email",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "RoleDisplayName",
                                label: "Roles",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-6" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <div className="dropdown">
                        <button className="dropbtn button-edit">Add user</button>
                        <div className="dropdown-content">
                            {
                                CommonVariables.getlocalstorageData().RoleName.toUpperCase() != "SCHOOLCOORDINATOR" &&
                                (
                                    <>
                                        <a onClick={() => enterAdd("InternalAdmin")}>Admin</a>
                                        <a onClick={() => enterAdd("SchoolCoordinator")}>Sales Coordinator</a>
                                        <a onClick={() => enterAdd("SchoolRep")}>Sales Rep</a>
                                        <a onClick={() => enterAdd("InternalUser")}>Sales Operations</a>
                                    </>
                                )
                            }
                            <a onClick={() => enterAdd("SchoolUser")}>External User</a>
                        </div>
                    </div>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshUserGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
                data={filterBy(data, filter)}
                pageable={true}
                sortable={true}
                take={2000}
                total={data.length}
                resizable={true}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "single"
                }}>
                <Column field="image" width="100" title="Image" cell={MyProfileCommandCell} />
                <Column field="userName" width="150" title="User Name" />
                <Column field="custnmbr" width="80" title="Acct #" />
                <Column field="schoolName" title="School Name" width="150" />
                <Column field="email" width="200" title="Email" />
                <Column field="RoleDisplayName" title="Role" width="150" />
                <Column field="isContractAccepted" width="160" title="Contract Accepted" cell={MyContractCommandCell} />
                <Column field="isAdminApproved" width="150" title="Approval status" cell={MyLockCommandCell} />
                <Column field="Edit" width="70" title="Edit" cell={MyEditCommandCell} />
                <Column field="Impersonate" width="130" cell={MyImpersonateCommandCell} />
                <Column field="ResendEmail" width="135" title="Resend Email" cell={MyResendEmailCommandCell} />
                CommonVariables.getlocalstorageData().RoleName.toUpperCase() === "INTERNALADMIN" &&
                (
                <Column field="Delete" width="80" cell={MyDeleteCommandCell} />
                )
            </Grid>
            {openForm && (
                <Edituser
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                    onLoaderRaise={props.onLoaderRaise}
                />
            )}
        </div>
    );
};
export default AuthUser;