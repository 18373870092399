import * as React from "react";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import EditQuoteRequest from "./EditQuoteRequest";
import APIConnector from "../../../../apiconnector";
import CommonVariables from "../../../../variables";
import { filterBy } from "@progress/kendo-data-query";
import Notifications from "../../../../Notifications/Messages";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateOnly } from '../../commonfunctions';

/**
 * Quote Queue Component enables users to edit the status and download the attachments
 * 
 */

const QuoteRequest = (props) => {

    //#region " Initialization of State  Values       "
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "accountNumber",
            operator: "contains",
            value: '',
        }]
    };

    const [filter, setFilter] = React.useState(initialFilter);
    const [data, setData] = React.useState([]);
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState();
    //#endregion

    //#region "     On Load         "
    React.useEffect(() => {
        RefreshQuoteRequestGrid()
    }, []);

    const RefreshQuoteRequestGrid = (msg) => {
        let crmInput =
        {
            pageNumber: 1,
            queryCount: 500,
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetQuoteQueue, crmInput).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            for (let index = 0; index < response?.length; index++) {
                response[index].requestDate=formatDateOnly(response[index].requestDate);
                response[index].needbydate=formatDateOnly(response[index].needbydate);
            }
            setData(response);
            if (msg)
                Notifications.ShowSuccessMessage(msg);
        });
    };
    //#endregion

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion


    //#region "     Modal Dialog Events        "

    const handleSubmit = (event) => {
        if (!event || !event.statusValue)
            return;
        let updateQRinputJSON = {
            Id: editItem.id,
            responseNotes: event.responseNotes,
            statusId: event.statusValue.statusValue,
            accountNumber: event.accountNumber.trim(),
            requestType:event.requestType
        };
        props.onLoaderRaise(true, "Updating quote ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateQuoteRequest, updateQRinputJSON).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (response == true) {
                setOpenForm(false);
                RefreshQuoteRequestGrid("Quote Request Successfully Updated.");
            }
            else {
                Notifications.ShowErrorMessage("Something went wrong . Please try again");
            }
        });
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
        RefreshQuoteRequestGrid("Grid refreshed...")
    };

    //#endregion

    //#region "     grid        "
    //#region "     Custom Cells    "

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );

    const EditCommandCell = (props) => {
        return (
            <td className="flex justify-evenly">
                <span title="Edit">
                    <EditIcon onClick={() => props.enterEdit(props.dataItem)} title="Edit" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };

    const enterEdit = (item) => {
        let QRInputJSON = {
            entityName: "tt_quoterequest",
            id: item.id,
        };
        props.onLoaderRaise(true, "Verifying assignment...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetQuoteQueueById, QRInputJSON).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess) {
                Notifications.ShowWarningMessage((response && response.message) ? response.message : "Quote assignment to user failed");
                return;
            }
            setOpenForm(true);
            setEditItem(item);
        });
    };
    //#endregion
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={1000}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
        <GridNoRecords>
            No Quote's available to request
        </GridNoRecords>
        <Column field="needbydate" width="150" title="Need By Date" />
            <Column field="accountNumber" title="Acct #" width="80" />
        <Column field="accountName" title="School Name" width="250" />
        <Column field="createdby" title="Quote Requested By" width="210" />
        <Column  field="requestDate" width="150" title="Request Date" />
        <Column field="requestType" title="Request Type" width="150" />
        <Column field="extAssignedIdName" title="Assigned To" width="200" />
            <Column field="statusValue" className="getStatus" title="Status" width="200" />
        <Column cell={MyEditCommandCell} width="60" title="Edit"/>
    </Grid>);
    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">Quote Queue</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-8">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "needbydate",
                                label: "Need By Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "accountNumber",
                                label: "Acct #",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "accountName",
                                label: "School Name",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "createdby",
                                label: "Quote Requested By",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "requestDate",
                                label: "Request Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "requestType",
                                label: "Request Type",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "extAssignedIdName",
                                label: "Assigned To",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "statusValue",
                                label: "Status",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-4" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end",paddingLeft:"10px" }}>
                    {/*<span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print*/}
                    {/*    <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />*/}
                    {/*</span>*/}
                    <span title="Excel" style={{ marginRight: "15px", font: "caption" }}>Export to Excel
                        <img onClick={exportExcel}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRIidWVv0sjURDHP7MmJxjBVpOAYUUE8T+w8Ed5tdUVlgdWgnHxrFyvEnNXWlhYWVlZ6T8gXC9Y2GQvniZBG/HACxeTzBWbQjYv2eda3bd8uzOfmfk+3sD/LokeuPv1j4geAvnBobobeDk/DuD0Ii2SCxWQHbdUTQCIrRxosaBKEELqe28FxCr4kv0lHZZCiG4NgiQCvAWSGGALeRfABIl+TyVJ6pZqGj3rue9dWXUgsB54WQGugBfaTKKs2MRaARS2s35tRNFDRI4Dd6KK8NUm1nZE48OjsqZDf4/opC/cm9oqyKxNoLXJorpZeSr8/lnMX4L0mNlP9iaLnBeofMgcPKQaf1pnwAyAODpfLuZ+9A2LHphuCNAQR2dQZ7mDpoebndNm2ikDY13z+8pyRLpf3sjeqeqmKN51M/cowrdoYabiLEck01Pf6ycKcwBupt5WU58G2Xrw6XXCuLm/VqKnwjY5mE2+JWYnPOOM3nvjz+HCkR3zX+HG6+lARD4Dt4MAI6JHi76mwpWpu/2SGztIIrdU3QLpPtXiB96EAfp+iG/a0f8Ajj+0Auj2X9kAAAAASUVORK5CYII="
                            style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }}
                            alt="Export to Excel"
                        />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshQuoteRequestGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
            <ExcelExport data={data} ref={(exporter) => { _export = exporter; }}>
                <Column field="needbydate" title="Need By Date" />
                <Column field="accountNumber" title="Acct #" />
                <Column field="accountName" title="School Name" />
                <Column field="createdby" title="Quote Requested By" />
                <Column field="requestDate" title="Request Date" />
                <Column field="requestType" title="Request Type" />
                <Column field="extAssignedIdName" title="Assigned To" />
                <Column field="statusValue" title="Status" />
            </ExcelExport>
            {openForm && (
                <EditQuoteRequest cancelEdit={handleCancelEdit} onSubmit={handleSubmit} obj={editItem} onLoaderRaise={props.onLoaderRaise} />
            )}
        </div>
    );
};

export default QuoteRequest;
