import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { WhereAmI } from "../../../UserActivity";

const DynamicProgressBar = (props) => {
    return (
        <>
            <div style={{ margin: 25 }}>
                <ProgressBar percent={100 * ((props.stage - 1) / 4)} filledBackground="#003087 !important">
                    {Object.keys(WhereAmI).map((step, index, arr) => {
                        return (
                            <Step
                                key={index}
                                position={100 * (index / arr.length)}
                                transition="scale"
                                children={({ accomplished }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "50%",
                                            width: 34,
                                            height: 34,
                                            color: "white",
                                            backgroundColor: accomplished ? "green" : "gray"
                                        }}
                                    >
                                        {index + 1}
                                    </div>
                                )}
                            />
                        );
                    })}
                </ProgressBar>
            </div>
        </>
    );
}
export default DynamicProgressBar;