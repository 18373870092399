import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { useEffect } from "react";
import { FormDatePicker } from "./form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";
import { RadioButton } from "@progress/kendo-react-inputs";

export default function Programs(props) {
    const [is_selected, setIs_selected] = React.useState(props.schoolProgramModel_Step3.is_selected);
    const handleKWMChange = React.useCallback(
        (e) => {
            props.schoolProgramModel_Step3.is_selected = !props.schoolProgramModel_Step3.is_selected;
            setIs_selected(!is_selected);
        },
        [props.schoolProgramModel_Step3.is_selected]
    );


    return (
        <div className="kendo-form" >
            <p className="font-weight-bold mt-3 d-block d-sm-none text-center">
                Programs
            </p>
            <br />
            <h5 className="wizardStepHeadLabel">
                Kindergarten Welcome Mat
            </h5>
            <div className="text-xs tracking-wide font-bold fieldlabel" style={{ marginTop: "25px" }}>
                <div style={{ margin: "10px", marginTop: "20px" }}>
                    <RadioButton
                        name={"schoolProgramModel_Step3.is_selected"}
                        value={is_selected}
                        checked={is_selected}
                        label="Yes, I would like to participate"
                        onChange={handleKWMChange}
                        style={{ borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }}
                    />
                </div>
                <div style={{ margin: "10px" }}>
                    <RadioButton
                        name={"schoolProgramModel_Step3.is_selected"}
                        value={!is_selected}
                        checked={!is_selected}
                        label="No thank you"
                        onChange={handleKWMChange}
                        style={{ borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }}
                    />
                </div>
            </div>
            {is_selected &&
                <div style={{ width: "fit-content", margin: "10px", marginTop: "20px" }}>
                    <label className="fieldlabel">Kindergarten Registration Date</label>
                    <Field
                        key={"schoolProgramModel_Step3.program_date"}
                        id={"schoolProgramModel_Step3.program_date"}
                        name={"schoolProgramModel_Step3.program_date"}
                        component={FormDatePicker}
                        validator={requiredValidator}
                    />
                </div>
            }
            <div className="text-xs tracking-wide fieldlabel" style={{ marginTop: "25px", padding: "6px" }}>
                <div><label style={{ fontSize: "large", padding: "3px" }}>Kindergarten Welcome Mat</label> <label style={{ borderRadius: "50%", border: "2px solid", font: "9px Arial, sans-serif", padding: "1px", position: "absolute" }}>R</label></div>
                <div style={{ padding: "5px", fontSize: "15px" }}>
                    <p>SchoolKidz has a special program designed to include incoming Kindergarten families in your supply kit sale. Your Kindergarten Registration or Open Enrollment event may be the only opportunity these parents have to receive information regarding the school supply kit order. There is no additional cost to run this program.
                    </p>
                    <li>If you choose to participate (and have more then 40 incoming Kindergarteners) we will send you:
                        <ul style={{ marginLeft: "35px" }}>
                            <li style={{ listStyle: "disc" }}>FREE sample kit to display at registration</li>
                            <li style={{ listStyle: "disc" }}>FREE posters to hang at registration</li>
                            <li style={{ listStyle: "disc" }}>FREE Flyers/Order Forms **(You must have finalized supply lists for Kindergarten)**</li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    );
};

