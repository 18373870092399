import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import { dynamicSpinner } from "../commonfunctions";
import { triggerBase64Download } from 'common-base64-downloader-react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Notifications from "../../../Notifications/Messages";
import DownloadIcon from '@mui/icons-material/Download';
import { DatePic } from "../../../ExternalDashboard/components/ProgramScheduleWizard/form-components";
const EditOrderFormProof = (props) => {

    //#region " Initialization of State  Values       "
    const [OrderFormCountsGridData, setOrderFormCountsGridData] = React.useState([]);

    const Statusdropdown = [
        { text: "Open/Unassigned", statusValue: "0" },
        { text: "Submitted to School", statusValue: "1" },
        { text: "Proof Approved", statusValue: "206340000" },
        { text: "Print Completed", statusValue: "206340001" },
        { text: "Printing Assigned", statusValue: "206340002" },
        { text: "Ready to Print", statusValue: "206340003" },
        { text: "Uploaded", statusValue: "206340005" },
        { text: "Back to Program Schedule", statusValue: "206340006" }
    ];
    const [dialogDimension, setDialogDimension] = React.useState([]);
    let statusValFromMainDialog = Statusdropdown.filter(function (each) { return each.statusValue == props.item.statuscode })[0];
    const [files, setFiles] = React.useState([]);
    const [loadingQAfiles, setloadingQAfiles] = React.useState(true);
    const [rowitem, setRowItem] = React.useState({
        tt_ReceiveDidYouForgetflyer: props.item.tt_ReceiveDidYouForgetflyer,
        tt_orderformtype: props.item.tt_orderformtype,
        parentNotes: props.item.parentNotes,
        forgetflyer: props.item.forgetflyer,
        tt_ReceiveElectronicFlyer: props.item.tt_ReceiveElectronicFlyer,
        statusValue: statusValFromMainDialog,
        tt_ShipName: props.item.tt_ShipName,
        tt_FirstName: props.item.tt_FirstName,
        tt_LastName: props.item.tt_LastName,
        tt_Title: props.item.tt_Title,
        tt_Address_line1: props.item.tt_Address_line1,
        tt_address_line2: props.item.tt_address_line2,
        tt_City: props.item.tt_City,
        tt_StateName: props.item.tt_StateName,
        tt_PostalCode: props.item.tt_PostalCode,
        tt_ShippingPhoneNumber: props.item.tt_ShippingPhoneNumber,
        tt_name: props.item.tt_name
    });

    //#endregion

    //#region "     On Load     "
    React.useEffect(() => {
        props.onLoaderRaise(true);
        RefreshAttachmentsList();
        APIConnector.Post(CommonVariables.urls.GetUBSSchoolGradeList, props.item).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (response && response.length > 0)
                setOrderFormCountsGridData(response);
        });
        var neededheight = window.innerHeight - (window.innerHeight / 5)
        var neededwidth = window.innerWidth - (window.innerWidth / 10)
        setDialogDimension([neededheight, neededwidth]);
    }, []);
    //#endregion


    const RefreshAttachmentsList = () => {
        setloadingQAfiles(true);
        APIConnector.Post(CommonVariables.urls.GetQuotePrintAttachmentListById, { id: props.item.tt_orderformproofid }).then(function (response) {
            if (!CommonVariables.isError(response))
                return;
            if (response && response.length > 0)
                setFiles(response);
            setloadingQAfiles(false);
        });
    };

    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion

    //#region "     Upload Files      "
    const uploadFile = (e) => {
        const formData = new FormData();
        formData.append("Form", e.target.files[0]);
        formData.append("tt_orderformproofid", props.item.tt_orderformproofid);
        formData.append("userId", CommonVariables.getlocalstorageData().userId);
        try {
            props.onLoaderRaise(true, "Uploading file ...", { marginTop: "145px" });
            APIConnector.FileUpload(CommonVariables.urls.OrderFormUploadFile, formData).then(function (response) {
                props.onLoaderRaise(false);
                if (!CommonVariables.isError(response))
                    return;
                if (response && response.isSuccess == true) {
                    Notifications.ShowSuccessMessage("File Uploaded successfully");
                    RefreshAttachmentsList();
                }
                else {
                    let message = "";
                    if (response && response.message)
                        message = response.message;
                    else
                        message = "Uploading Failed";
                    Notifications.ShowErrorMessage(message);
                }
            });
        } catch (ex) {
            Notifications.ShowErrorMessage(ex);
        }
    };
    //#endregion

    return (
        <Dialog className="w-screen" title={`Edit flyer/order form`} onClose={props.cancelEdit} height={dialogDimension[0]} width={dialogDimension[1]} >
            {
                <div className="modalStyle">
                    <Form className="rounded-t-2xl w-screen flex" initialValues={rowitem} onSubmit={props.onSubmit}
                        render={(formRenderProps) => (
                            <FormElement className="quote-modal rounded-t-2xl font-normal w-full">
                                <fieldset className="k-form-fieldset grid md:grid-cols-4 gap-2">
                                    <div>
                                        <label className="fieldlabel">Acct #</label>
                                        <Field name={"tt_name"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Do you want printed flyer/order forms ?</label>
                                        <Field name={"tt_ReceiveDidYouForgetflyer"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Flyer/order form type</label>
                                        <Field name={"tt_orderformtype"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Did you forget flyer ?</label>
                                        <Field name={"forgetflyer"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Do you also want a PDF copy ?</label>
                                        <Field name={"tt_ReceiveElectronicFlyer"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <Label style={{ marginBottom: "1px" }} >Parent Notes</Label>
                                        <Field name={"parentNotes"} component={TextArea} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Status</label>
                                        <Field name={"statusValue"} component={DropDownList}
                                            textField="text" dataItemKey="statusValue" data={Statusdropdown} />
                                    </div>
                                </fieldset>
                                <label style={{ marginTop: "10px", textDecoration: "underline" }}><b>Shipping details</b></label>
                                <fieldset className="k-form-fieldset grid grid-cols-4 gap-2" style={{ margin: "0px" }}>
                                    <div>
                                        <label className="fieldlabel">Ship to option</label>
                                        <Field name={"tt_ShipName"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">First name</label>
                                        <Field name={"tt_FirstName"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Ship to option</label>
                                        <Field name={"tt_LastName"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Organization</label>
                                        <Field name={"tt_Title"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Address line 1</label>
                                        <Field name={"tt_Address_line1"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Address line 2</label>
                                        <Field name={"tt_address_line2"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">City</label>
                                        <Field name={"tt_City"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">State</label>
                                        <Field name={"tt_StateName"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Zip code</label>
                                        <Field name={"tt_PostalCode"} component={Input} disabled={true} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Shipping phone</label>
                                        <Field name={"tt_ShippingPhoneNumber"} component={Input} disabled={true} />
                                    </div>
                                </fieldset>
                                <div style={{ display: "flex" }}>
                                    <div className="col-sm-8" style={{ textAlign: "left" }}>
                                        <Label className="fieldlabel">Attachments</Label>
                                    </div>
                                    <div className="col-sm-4" style={{ textAlign: "right", cursor: "pointer" }}>
                                        <input style={{ display: "none" }} id="choose-file" type="file" onChange={uploadFile} />
                                        <label htmlFor="choose-file">
                                            <span title="Upload File" style={{ font: "caption" }}>Upload File
                                                <UploadFileIcon style={{ marginBottom: "6px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {
                                    <div className="sok-tile-row" style={{ border: "2px solid", borderColor: "#b4b1b1", height: "auto" }}>
                                        {loadingQAfiles && dynamicSpinner({ width: "80px", height: "80px", marginTop: "31px", marginLeft: "12px" })}
                                        {!loadingQAfiles && files.length <= 0 && <div style={{ textAlign: "center" }}><label>No Attachments avaialble</label></div>}
                                        {!loadingQAfiles && files.length > 0 && files.map((EachFile, i) => (
                                            <div className="sok-tile" key={i}>
                                                <DownloadIcon />
                                                <div className="sok-tile-name" onClick={() => { PdfDownload(EachFile.fileNmae, EachFile.documentBody) }}>
                                                    {EachFile.fileNmae}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                <Label className="fieldlabel">Flyers/Order Forms Counts</Label>
                                <Grid className="rounded-2xl border-b-4"
                                    resizable={true}
                                    sortable={true}
                                    pageable={true}
                                    data={OrderFormCountsGridData}
                                    style={{ height: "300px" }}
                                    selectable={{
                                        enabled: true,
                                        drag: false,
                                        cell: false,
                                        mode: "single"
                                    }}>
                                    <GridColumn field="grade" title="Grade" width="120" />
                                    <GridColumn field="type" title="Type" width="120" />
                                    <GridColumn field="itemdesc" title="Kit Description" />
                                    <GridColumn field="quantity" title="Quantity to Print" width={150} />
                                    <GridColumn field="orderNote" title="Important Parent Notes" />
                                </Grid>
                                <div className="k-form-buttons">
                                    <button type="submit" className="button-save" onClick={formRenderProps.onSubmit} disabled={!formRenderProps.allowSubmit}>
                                        Update
                                    </button>
                                    <button className="button-cancel" onClick={() => props.cancelEdit()}>
                                        Cancel
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            }
        </Dialog>
    );
};

export default EditOrderFormProof;
