import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { NotesCell, DeleteCell, EditCell, QtyCell, CancelCell, NewItemCell } from "./cell-components";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PublishIcon from '@mui/icons-material/Publish';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import Notifications from "../../../Notifications/Messages";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";
const EditForm = props => {

    //#region "     On Load of Component    "
    const [data, setData] = React.useState();
    const [sdata, setSdata] = React.useState();
    React.useEffect(() => {
        RefreshGrid();
    }, []);

    function RefreshGrid(message, loaderMessage = "Loading Grade Items....") {
        let inputJson = {
            CUSTNMBR: props.item.custnmbr,
            Grade: props.item.grade,
            Type: props.item.type
        }
        //"Loading Grade Items...." : "Auto Saving...." 
        props.onLoaderRaise(true, loaderMessage, { marginTop: "145px" });
        setTimeout(function () {
            APIConnector.Post(CommonVariables.urls.GetGradeWorksheetById, inputJson).then(function (response) {
                if (!CommonVariables.isError(response))
                    return;

                let temp = response.map((item) => { return { ...item, inEdit: true } })
                setData(temp);
                setSdata(temp);
                props.onLoaderRaise(false);
                if (message)
                    Notifications.ShowSuccessMessage(message);
            })
        }
            , 500)
    };
    //#endregion

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Delete functionality        "
    const Delete = (props) => (
        <DeleteCell
            {...props}
            remove={remove}
        />
    );

    const NewItemUpdate = (props) => (
        <NewItemCell
            {...props}
            updateInputField={newItemFieldUpdate}
        />
    );

    const newItemFieldUpdate = (dataItem, newItemFieldValue) => {
        if (dataItem.request_type === "Delete Item") {
            return;
        }
        const newData = data.map((item) =>
            item.grade_item_row_id === dataItem.grade_item_row_id
                ? { ...item, "new_item": newItemFieldValue, changed: "true" }
                : item
        );
        var updatedNewItemData = newData
        setData(newData);
        saveAll(true, updatedNewItemData);//Autosave
    }
    function CreateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const Cancel = (props) => (
        <CancelCell
            {...props}
            cancel={cancelField}
        />
    );

    const remove = (dataItem) => {
        if (dataItem.isNotSaved) {
            let temp1 = [...data]
            let temp2 = [...sdata]
            let index = temp1.findIndex(record => record.grade_item_row_id === dataItem.grade_item_row_id);
            temp1.splice(index, 1)
            temp2.splice(index, 1)
            setData(temp1)
            setSdata(temp2)
            return;
        }



        let inputJson = {
            rowid: dataItem.grade_item_row_id,
            CUSTNMBR: props.item.custnmbr,
            Grade: dataItem.grade,
            Type: dataItem.type,
            request_type: "Delete Item",
            new_qty: 0,
            new_item: dataItem.new_item,
            cmptitnm: dataItem.cmptitnm,
            status_row_id: props.item.rowid,
            response_note: props.item.response_note
        };
        props.onLoaderRaise(true, "Removing Grade Item....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGradeKitUpdate, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            RefreshGrid("Item to be deleted");
        });
    };
    //#endregion

    const rowItemRender = (trElement, props) => {
        const trProps = {
            style: props.dataItem.request_type == "Delete Item" ? { textDecoration: "line-through red 2px solid" } : { textDecoration: "none" },
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    //#region "     Edit Functionality"
    const Edit = (props) => (
        <EditCell
            {...props}
            editField={editField}
            update={updateField}
            save={saveField}

        />
    );
    const editField = (dataItem) => {
        var temp = [...data]
        let index = temp.findIndex(record => record.grade_item_row_id === dataItem.grade_item_row_id);
        temp[index].inEdit = true;
        setData(temp);
    };
    const enterRowItemEdit = (rowEvent) => {
        if (props.item.status === "Approved") {
            return;
        }
        editField(rowEvent.dataItem);
    };

    const cancelField = (dataItem) => {

        if (dataItem.request_type === "Delete Item") {//providing undo action for already deleted items
            //  dataItem.request_type = "Update"
            const newData = data.map((item) =>
                item.grade_item_row_id === dataItem.grade_item_row_id
                    ? { ...item, request_type: "Update", changed: "true" }
                    : item
            );
            dataItem.request_type = "Update";
            var updatedData = newData
            setData(newData);
            updateField(dataItem);
            RefreshGrid("Item is reverted");
        }
        return;
    };
    const updateField = (dataItem) => {
        let inputJson = {
            rowid: dataItem.grade_item_row_id,
            CUSTNMBR: props.item.custnmbr,
            Grade: dataItem.grade,
            Type: dataItem.type,
            request_type: dataItem.request_type == "Add Item" ? dataItem.request_type : "Update",
            new_qty: dataItem.new_qty,
            new_item: dataItem.new_item,
            cmptitnm: dataItem.cmptitnm,
            status_row_id: props.item.rowid,
            response_note: props.item.response_note
        }

        props.onLoaderRaise(true, "Updating Grade Item....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGradeKitUpdate, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
        });
    };
    //#endregion

    //#region "     Add New Functonality    "
    const addNew = () => {

        const newDataItem = {
            inEdit: true,
            cmptitnm: "*NEW*",
            grade: props.item.grade,
            type: props.item.type,
            changed: "false",
            new_item: "",
            isNotSaved: true,
            grade_item_row_id: CreateUUID()
        };
        setData([newDataItem, ...data]);
        setSdata([newDataItem, ...data]);

    };

    const saveField = (dataItem) => {
        let inputJson = {
            CUSTNMBR: props.item.custnmbr,
            Grade: dataItem.grade,
            Type: dataItem.type,
            request_type: "Add Item",
            new_qty: dataItem.new_qty,
            new_item: dataItem.new_item,
            CMPTITNM: dataItem.cmptitnm,
            rowid: "",
            status_row_id: props.item.rowid,
            response_note: props.item.response_note
        }

        props.onLoaderRaise(true, "Saving....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.SchoolGradeKitUpdate, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
        });

    };

    const saveAll = (isFromAutoSave, updatedData) => {

        const temp = data.filter(item => item.isNotSaved)
        for (var i = 0; i < temp.length; i++) {
            var element = temp[i];
            if (element.new_qty === undefined || element.new_qty === "" || element.new_item === undefined || element.new_item === "") {
                if (isFromAutoSave === true)
                    return;
                Notifications.ShowErrorMessage("Quantity and Notes Fields are required")
                return
            }
        }
        if (isFromAutoSave===true) {
            updatedData.forEach(element => {
                if (element.changed) {
                    if (element.isNotSaved)
                        saveField(element)
                    else
                        updateField(element)
                }
            });
        }
        else {
            data.forEach(element => {
                if (element.changed) {
                    if (element.isNotSaved)
                        saveField(element)
                    else
                        updateField(element)
                }
            });
        }
        RefreshGrid("Grade item saved succesfully", isFromAutoSave ? "Auto Saving...." : undefined);
    }

    const submit = () => {
        const AlertNo = () => {
            props.onAlert(false)
        }

        const AlertYes = () => {
            saveAll()
            props.onSubmit()
            props.onAlert(false)
        }
        var alertDataObj = {
            title: "Please Confirm",
            body: "There are some unsaved changes. Press Save and Submit button to confirm",
            YesTitle: "Save and Submit",
            NoTitle: "Cancel",
            Yes: AlertYes,
            No: AlertNo,
            datatoPassOnYes: "",
        };
        props.onAlert(true, alertDataObj)
    };

    const itemChange = (event) => {
        if (event.dataItem && event.dataItem.request_type === "Delete Item") {
            return;
        }
        const field = event.field || "";

        if (field === "new_item")//New item text is autosaved already in onblur event
            return;

        if (field === 'new_qty' && event.value < 0) {
            event.value = 0;
        }
        const newData = data.map((item) =>
            item.grade_item_row_id === event.dataItem.grade_item_row_id
                ? { ...item, [field]: event.value, changed: "true" }
                : item
        );
        var updatedData = newData
        setData(newData);
        saveAll(true, updatedData);//Autosave
    };
    //#endregion



    //#region "      grid      "
    const grid = (
        <Grid
            data={data}
            sortable={true}
            pageable={true}
            take={100}
            editField="inEdit"
            onItemChange={itemChange}
            style={{ marginTop: "5px", height: (window.innerHeight - 300) + "px" }}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}
            rowRender={rowItemRender}
            onRowClick={enterRowItemEdit}>
            <Column field="grade" filterable={false} title="Grade" width="70" editable={false} />
            <Column field="type" filterable={false} title="Type" width="70" editable={false} />
            <Column field="cmptitnm" filterable={false} title="Item #" width="100" editable={false} />
            <Column field="new_qty" style={{ "padding": "1px 4px !important" }} width="100" title="Qty" filterable={false} editor="numeric" editable={true} />
            <Column field="cmpituom" filterable={false} width="65" title="Unit" editable={false} />
            <Column field="brand" filterable={false} title="Brand" width="100" editable={false} />
            <Column field="kititemdesc" title="Item Description" width="350" editable={false} />
            {props.item.status != "Approved" && <Column cell={Cancel} title="Cancel" filterable={false} width="80" editable={true} />}
            {props.item.status != "Approved" && <Column cell={Delete} title="Delete" filterable={false} width="80" style={{ "display": props.item.status == "Approved" ? "none" : "inline-block" }} />}
            <Column field="request_type" filterable={false} title="Request type" width="150" editable={false} />
            <Column field="new_item" cell={NewItemUpdate} title="New Item Notes" width="230" editable={true} />
        </Grid>
    );
    //#endregion

    return <Dialog title={`Approve Grade`} onClose={() => props.cancelEdit(data)}>
        <div className="modalStyle">
            <div className="text-xs tracking-wide" style={{ marginTop: "10px", padding: "6px" }}>
                <h5 style={{ color: "red" }}>If changes are needed, follow the instructions below to
                    make changes and then click Submit when all changes have been entered and saved.</h5>
                <div className="fieldlabel" style={{ padding: "5px" }}>
                    <ul style={{ marginLeft: "20px" }}>
                        <li style={{ listStyle: "disc" }}>Update Quantity &minus;
                            Change the quantity using the up/down arrow.</li>
                        <li style={{ listStyle: "disc" }}>Add New Item &minus; Click on "Add New Item +" in the top right of the screen and use available fields to identify what item needs to be added.</li>
                        <li style={{ listStyle: "disc" }}>Change Brand &minus; Add the brand, color,
                            and/or style you want to request in the New Item Notes.</li>
                        <li style={{ listStyle: "disc" }}>Delete an Item &minus; Click Red trash can. If you mistakenly deleted an item, click the red circle with the X, under cancel column and it will revert to original. </li>
                        <li style={{ listStyle: "disc" }}> Click on Save All(disk image) to save all the changes. </li>
                        <li style={{ listStyle: "disc" }}> Please DO NOT approve lists until all revisions have been SUBMITTED and COMPLETED. </li>
                    </ul>
                </div>
            </div>
            <div style={{ textAlign: "right" }}>
                <span title="Save All" style={{ marginRight: "15px", font: "caption", display: props.item.status == "Approved" ? "none" : "inline-block" }}>Save All
                    <SaveIcon onClick={saveAll} style={{ marginBottom: "9px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
                <span title="Add New Item" style={{ marginRight: "15px", font: "caption", display: props.item.status == "Approved" ? "none" : "inline-block" }}>Add New Item
                    <NoteAddIcon onClick={addNew} style={{ marginBottom: "9px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
                <span title="Submit Revision" style={{ marginRight: "15px", font: "caption", display: props.item.status == "Approved" ? "none" : "inline-block" }}>Submit
                    <PublishIcon onClick={submit} style={{ marginBottom: "6px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
                <span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print
                    <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </div>
            <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                <Column field="cmptitnm" title="Item #" />
                <Column field="new_qty" title="Qty" />
                <Column field="new_item" title="New Item Notes" />
                <Column field="cmpituom" title="Unit" />
                <Column field="kititemdesc" title="ItemDescription" />
                <Column field="grade" title="Grade" />
                <Column field="type" title="Type" />
                <Column field="brand" title="Brand" />
                <Column field="request_type" title="Request type" />
                {grid}
            </GridPDFExport>
            {grid}
        </div>
    </Dialog>;
};

export default EditForm;