import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import { filterBy } from "@progress/kendo-data-query";
import Notifications from "../../../Notifications/Messages";
import { Filter, Operators, TextFilter, NumericFilter, BooleanFilter } from "@progress/kendo-react-data-tools";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const OrderTallySheet = (props) => {
    const [data, setData] = React.useState([]);
    const [rowData, setRowData] = React.useState({ totalKitSales: "2000", totalKits: "10" });
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const dockDropdown = [{ text: "I'm not sure", value: 0 }, { text: "Yes", value: 2 }, { text: "No", value: 1 }];

    useEffect(() => {
        RefreshOrderTallySheetGrid();
    }, []);

    const RefreshOrderTallySheetGrid = (message) => {
        props.onLoaderRaise(true, "Getting Order Tally sheet details ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetTallySheetContainerOutModel).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;

            if (!response.sheetGrid)
                response.sheetGrid = [];

            let totalKits = 0;
            let totalKitSales = 0;
            if (!response.tallySheetInfoModel) {
                response.tallySheetInfoModel = {};
            }

            response.sheetGrid.forEach((Each) => {
                totalKits = totalKits + Each.kits_ordered
                totalKitSales = totalKitSales + Each.total
            });
            response.tallySheetInfoModel.totalKits = totalKits;
            response.tallySheetInfoModel.totalKitSales = totalKitSales.toFixed(2);
            response.tallySheetInfoModel.sub_total = totalKitSales.toFixed(2);

            response.tallySheetInfoModel.discountamount = (response.tallySheetInfoModel.sub_total * response.tallySheetInfoModel.pcd / 100).toFixed(2);

            response.tallySheetInfoModel.net_sale = (response.tallySheetInfoModel.sub_total - response.tallySheetInfoModel.discountamount).toFixed(2);

            setData(response.sheetGrid);


            props.formState.tallySheetData = { ...response };
            if (!response.tallySheetInfoModel.loading_dock)
                response.tallySheetInfoModel.loading_dock = 0;

            response.tallySheetInfoModel.loading_dock = (dockDropdown.filter(
                function (each) {
                    return each.value === response.tallySheetInfoModel.loading_dock;
                }
            )[0]);

            setRowData(response.tallySheetInfoModel);

            setIsDataLoaded(true);
            if (message)
                Notifications.ShowSuccessMessage(message);
        });
    };

    const formatcurrency = (val) => {
        var dollarUSLocale = Intl.NumberFormat('en-US',{maximumFractionDigits: 2,minimumFractionDigits: 2});
        return dollarUSLocale.format(val);
    }


    //#region " Save Progress Functionlaity        "
    const SaveProgress = (event) => {
        let inputJson = {};
        inputJson.tallySheetInfoModel = { ...event };
        inputJson.tallySheetInfoModel.submit_flag = false;
        inputJson.tallySheetInfoModel.userId = CommonVariables.getlocalstorageData().userId;
        inputJson.tallySheetInfoModel.loading_dock = event.loading_dock.value;
        props.formState.tallySheetData = inputJson;
        props.onLoaderRaise(true, "Saving...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.OrderTallySheetSave, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("Order Entry successfully saved.");
        });
    };
    //#endregion
    const onLoadingDockChangeEvent = (event) => {
        props.formState.tallySheetData.tallySheetInfoModel.loading_dock = event.value;
    };
    return (<div className="PageStyle">
        <div>
            <label className="wizardStepHeadLabel">
                Order Tally Sheet
            </label>
            <h5 className="wizardStepHeadLabel" style={{width:"750px"}}>Answer the question below regarding your school having a dock so we can properly indicate to the freight carrier if we need to order a lift gate. 
                <br /> Then click NEXT and then SUBMIT.
            </h5>
        </div>
        <div>
            {
                isDataLoaded &&
                <Form initialValues={rowData} onSubmit={SaveProgress}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div>
                                <label className="Headlabel">
                                    Kit Items
                                </label>
                                <Grid className=" rounded-2xl border-b-4" style={{ height: "250px" }}
                                    data={data}
                                    sortable={true}
                                    resizable={true}
                                    selectable={{
                                        enabled: true,
                                        drag: false,
                                        cell: false,
                                        mode: "single"
                                    }}>
                                    <Column field="grade" title="Grade" width={70} />
                                    <Column field="type" title="Type" width={70} />
                                    <Column field="itemDescription" title="Description" width={200} />
                                    <Column field="kitPrice" format="{0:c2}" width={150} title="Kit Cost Each" />
                                    <Column field="kits_ordered" title="# of Kits Ordered" width={150} />
                                    <Column field="total" format="{0:c2}" title="Total Cost($$)" width={150} />
                                </Grid>
                                <div style={{ textAlign: "right", marginRight: "20px" }} >
                                    <label className="fieldlabel" style={{ marginRight: "100px" }}>Total Kits Ordered (Units) : {rowData.totalKits}</label>
                                    <label className="fieldlabel">Total Kits Sales: ${formatcurrency(rowData.totalKitSales)}</label>
                                </div>
                            </div>
                            <fieldset className="k-form-fieldset grid pt-1" style={{ width: "fit-content !important", float: "right" }}>
                                <div className="ml-auto">
                                    <label className="fieldlabel">Sub-total : ${formatcurrency(rowData.sub_total)}</label>
                                </div>
                                <div className="ml-auto">
                                    <label className="fieldlabel">Program Commitment discount : {rowData.pcd} %</label>
                                </div>
                                <div className="ml-auto">
                                    <label className="fieldlabel">Discount amount : ${formatcurrency(rowData.discountamount)}</label>
                                </div>
                                <div className="ml-auto">
                                    <label className="fieldlabel">Net Sale Amount : ${formatcurrency(rowData.net_sale)}</label>
                                </div>
                                <div>
                                    <label className="fieldlabel">Does your school have a loading dock?</label>
                                    <Field name={"loading_dock"} component={DropDownList} style={{ width: "fit-content !important" }}
                                        textField="text" dataItemKey="value" data={dockDropdown}
                                        onChange={onLoadingDockChangeEvent}
                                    />
                                </div>
                            </fieldset>
                        </FormElement>
                    )}
                />
            }
        </div>
    </div>);
};

export default OrderTallySheet;