import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import EditQuotePrint from "./EditQuotePrint";
import APIConnector from "../../../../apiconnector";
import CommonVariables from "../../../../variables";
import Notifications from "../../../../Notifications/Messages";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateOnly } from '../../commonfunctions';

/**
 * Quote Print Component enables users to edit the status and download the attachments.
 * 
 */

const QuotePrint = (props) => {

    //#region " Initialization of State  Values       "
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "tt_name",
            operator: "contains",
            value: '',
        }]
    };
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({
        tt_orderformproofid: 1,
    });
    const [filter, setFilter] = React.useState(initialFilter);
    const [data, setData] = React.useState([]);

    //#endregion

    //#region "     On Load         "
    React.useEffect(() => {
        refreshQuotePrintGrid();
    }, []);

    const refreshQuotePrintGrid = (msg) => {
        let crmInput = {
            pageNumber: 1,
            queryCount: 500
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetQuotePrint, crmInput).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            response = response.map(function (EachRow) {
                if (EachRow.shipdays && EachRow.takehomedate) {
                    EachRow.tt_shipbydate = new Date(EachRow.takehomedate)
                    EachRow.tt_shipbydate.setDate(EachRow.tt_shipbydate.getDate() - EachRow.shipdays);
                }
                EachRow.tt_shipbydate=formatDateOnly(EachRow.tt_shipbydate);
                EachRow.takehomedate=formatDateOnly(EachRow.takehomedate);
                EachRow.tt_senttoprintqueuedate=formatDateOnly(EachRow.tt_senttoprintqueuedate);
                return EachRow;
            })

            setData(response);
            if (msg) {
                Notifications.ShowSuccessMessage(msg);
            }
        });
    };
    //#endregion

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion

    //#region "     Modal Dialog Events        "
    const UpdateStatusToUploaded = () => {
        let obj = {
            tt_orderformproofid: editItem.tt_orderformproofid,
            statuscode: "206340005",
        };
        props.onLoaderRaise(true, "Updating status to uploaded...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateStatusToUploaded, obj).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            refreshQuotePrintGrid("Status updated to uploaded Successfully");
        });
    };

    const UpdateStatusToPrintingUploaded = () => {
        let obj = {
            tt_orderformproofid: editItem.tt_orderformproofid,
            statuscode: "206340001",
        };
        props.onLoaderRaise(true, "Updating status to printing completed...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateStatusToPrintComplete, obj).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            refreshQuotePrintGrid("Status updated to Print Complete Successfully");
        });
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
        refreshQuotePrintGrid("Grid refreshed");
    };

    //#endregion

    //#region "     Grid        "

    //#region "     Custom Cells    "

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const EditCommandCell = (props) => {
        return (
            <td className="flex justify-evenly">
                <span title="Edit">
                    <EditIcon onClick={() => props.enterEdit(props.dataItem)} title="Edit" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };
    const enterEdit = (item) => {
        let inputData = {
            "entityname": "tt_orderformproof",
            "Id": item.tt_orderformproofid,
            "planYear": item.planYear
        };
        props.onLoaderRaise(true, "Verifying assignment...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetQuotePrintById, inputData).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(true);
            setEditItem(item);
        });
    };

    //#endregion

    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={1000}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <GridNoRecords>
                No Quote's available to print
            </GridNoRecords>
            <Column field="tt_name" width="80" title="Acct #" />
            <Column field="tt_accountidname" width="200" title="Account Name" />
            <Column field="status_text" title="Status" width="200" />
            <Column field="tt_shipbydate" width="150" title="Ship By Date" />
            <Column field="tt_stateidname" width="90" title="State" />
            <Column field="takehomedate" width="150" title="Take Home Date" />
            <Column field="tt_senttoprintqueuedate" width="150" title="Submission Date" />
            <Column field="shipdays" title="Ship Days" width="90" />
            <Column field="tt_printassigneeidyominame" width="200" title="Assigned To" />
            <Column cell={MyEditCommandCell} width="60" title="Edit" />
        </Grid>
    );

    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">Print Queue</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-6">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "tt_name",
                                label: "Account Name",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_accountidname",
                                label: "Account #",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_shipbydate",
                                label: "Ship By Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_stateidname",
                                label: "State",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "takehomedate",
                                label: "Take Home Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_senttoprintqueuedate",
                                label: "Submission Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "shipdays",
                                label: "Ship Days",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "status_text",
                                label: "Status",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_printassigneeidyominame",
                                label: "Assigned To",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-6" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print
                        <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Excel" style={{ marginRight: "15px", font: "caption" }}>Export to Excel
                        <img onClick={exportExcel}

                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRIidWVv0sjURDHP7MmJxjBVpOAYUUE8T+w8Ed5tdUVlgdWgnHxrFyvEnNXWlhYWVlZ6T8gXC9Y2GQvniZBG/HACxeTzBWbQjYv2eda3bd8uzOfmfk+3sD/LokeuPv1j4geAvnBobobeDk/DuD0Ii2SCxWQHbdUTQCIrRxosaBKEELqe28FxCr4kv0lHZZCiG4NgiQCvAWSGGALeRfABIl+TyVJ6pZqGj3rue9dWXUgsB54WQGugBfaTKKs2MRaARS2s35tRNFDRI4Dd6KK8NUm1nZE48OjsqZDf4/opC/cm9oqyKxNoLXJorpZeSr8/lnMX4L0mNlP9iaLnBeofMgcPKQaf1pnwAyAODpfLuZ+9A2LHphuCNAQR2dQZ7mDpoebndNm2ikDY13z+8pyRLpf3sjeqeqmKN51M/cowrdoYabiLEck01Pf6ycKcwBupt5WU58G2Xrw6XXCuLm/VqKnwjY5mE2+JWYnPOOM3nvjz+HCkR3zX+HG6+lARD4Dt4MAI6JHi76mwpWpu/2SGztIIrdU3QLpPtXiB96EAfp+iG/a0f8Ajj+0Auj2X9kAAAAASUVORK5CYII="
                            style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }}
                            alt="Export to Excel"
                        />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={refreshQuotePrintGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
            <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                <Column field="tt_name" title="Account Name" />
                <Column field="tt_accountidname" title="Account #" />
                <Column field="tt_shipbydate" title="Ship By Date" />
                <Column field="tt_stateidname" title="State" />
                <Column field="takehomedate"  title="Take Home Date" />
                <Column field="tt_senttoprintqueuedate"  title="Submission Date" />
                <Column field="shipdays" title="Ship Days" />
                <Column field="status_text" title="Status" />
                <Column field="tt_printassigneeidyominame" title="Assigned To" />
                {grid}
            </GridPDFExport>
            <ExcelExport data={data} ref={(exporter) => { _export = exporter; }}>
                <Column field="tt_name" title="Account Name" />
                <Column field="tt_accountidname" title="Account #" />
                <Column field="tt_shipbydate"  title="Ship By Date" />
                <Column field="tt_stateidname" title="State" />
                <Column field="takehomedate" title="Take Home Date" />
                <Column field="tt_senttoprintqueuedate"  title="Submission Date" />
                <Column field="shipdays" title="Ship Days" />
                <Column field="status_text" title="Status" />
                <Column field="tt_printassigneeidyominame" title="Assigned To" />
            </ExcelExport>
            {openForm && (
                <EditQuotePrint cancelEdit={handleCancelEdit}
                    UpdateStatusToUploaded={UpdateStatusToUploaded}
                    UpdateStatusToPrintingUploaded={UpdateStatusToPrintingUploaded}
                    item={editItem} onLoaderRaise={props.onLoaderRaise} />
            )}
        </div>
    );
};

export default QuotePrint;

