import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList, MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import CommonVariables from '../../../../variables';
import APIConnector from '../../../../apiconnector';
import Notifications from '../../../../Notifications/Messages';
import $ from "jquery";
import {
    userNameValidator,
    emailValidator,
    passwordValidator, phoneValidator,
    requiredValidator,
} from "../../validators";
import { FormInput, FormDropDownList, FormMultiColumnComboBox } from "../../../../ExternalDashboard/components/SchoolInfoWizard/form-components";

const Edituser = (props) => {
    const [postData, setPostData] = useState(props.item);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [accountLoader, setAccountLoader] = useState(false);
    const [systemUserLoader, setSystemUserLoader] = useState(false);
    const [ContactList, SetContactList] = useState([]);
    const [AccountList, SetAccountList] = useState([]);
    const [SystemUsersList, SetSystemUsersList] = useState([]);

    useEffect(() => {
        let filterObj = {};
        if (props.item.roleName === "InternalUser" || props.item.roleName === "SchoolUser") {
            if (props.item.accountId && props.item.accountId.length > 0) {
                filterObj = {
                    accountId: props.item.accountId
                };
            }
            RefreshAccountsList(filterObj);//To add Normal Users we are loading Account based contacts list from CRM
        }
        else {
            if (props.item.systemUserId && props.item.systemUserId.length > 0) {
                filterObj = {
                    systemuserid: props.item.systemUserId
                };
            }
            RefreshSystemUsersList(filterObj);//To add Internal Admins and Coordinator we are loading the System Users list from CRM
        }
    }, []);

    const RefreshSystemUsersList = (filterObj) => {
        // props.onLoaderRaise(true, "Getting System users list please wait ....", { marginTop: "145px" });
        setSystemUserLoader(true);
        APIConnector.Post(CommonVariables.urls.GetAllSystemUserList, filterObj).then(function (response) {
            // props.onLoaderRaise(false);
            setSystemUserLoader(false);
            if (!CommonVariables.isError(response))
                return;
            SetSystemUsersList(response);
            if (!dataLoaded) {
                let temp = { ...postData };
                temp.systemUser = response.filter(function (each) { return each.systemuserid == props.item.systemUserId })[0];
                setPostData(temp);
                setDataLoaded(true);
            }
        });
    };

    const RefreshAccountsList = (filterObj) => {
        //props.onLoaderRaise(true, "Getting Account list please wait ....", { marginTop: "145px" });
        if (props.item.roleName === "InternalUser" && props.item.isCreateNewUserMode == true) {
            filterObj.accountnumber = "55555";
            // As per client Request we are hard wired 55555 account number for all the internal users
        }
        setAccountLoader(true);
        APIConnector.Post(CommonVariables.urls.GetAccountContactsList, filterObj).then(function (response) {
            //props.onLoaderRaise(false);
            setAccountLoader(false);
            if (!CommonVariables.isError(response))
                return;

            if (!(props.item.roleName === "InternalUser")) {
                if (postData.isCreateNewUserMode && response.enteredFilter != $('[placeholder="Search by Account number"]').val()) {
                    return;
                }
            }

            response = response.accountContactListOutModel;
            response = response.map(function (each) {
                each.accountnumber = each.accountnumber ? each.accountnumber : "";
                each.name = each.name ? each.name : "";
                return each;
            });
            SetAccountList(response);
            SetContactList([]);
            if (!dataLoaded) {
                let temp = { ...postData };
                if (props.item.roleName === "InternalUser" && props.item.isCreateNewUserMode == true) {
                    temp.account = response.filter(function (each) { return each.accountnumber == "55555" })[0];
                    // As per client Request we are hard wired 55555 account number for all the internal users
                }
                else {
                    temp.account = response.filter(function (each) { return each.accountId == props.item.accountId })[0];
                }
                if (!temp.account || !temp.account.contactGetListOutModel) {
                    SetContactList([]);
                }
                else {
                    SetContactList(temp.account.contactGetListOutModel);
                    temp.contact = temp.account.contactGetListOutModel.filter(function (each) { return each.contactid == props.item.contactId })[0];
                }
                setPostData(temp);
                setDataLoaded(true);
            }
        });
    };

    const onSystemFilterChange = (event) => {
        RefreshSystemUsersList({ fullname: event.filter.value });
    };

    const onAccountFilterChange = (event) => {
        RefreshAccountsList({ accountnumber: event.filter.value });
    };

    const onAccountSelected = (event) => {
        if (!event.value) {
            SetContactList([]);
            var temp = { ...postData };
            temp.contact = {};
            setPostData(temp);
            return;
        }
        SetContactList(event.value.contactGetListOutModel);
        var temp = { ...postData };
        temp.contact = {};
        temp.account = event.value;
        setPostData(temp);
    };

    const onContactSelected = (event) => {
        if (!event.value) {
            return;
        }
        var temp = { ...postData };
        temp.account = postData.account;
        temp.lastName = event.value.lastName;
        temp.firstName = event.value.firstName;
        temp.email = event.value.emailaddress;
        temp.userName = event.value.emailaddress;
        temp.phone = event.value.telephone1;
        temp.contact = event.value;
        setPostData(temp);
    };

    const onSystemUserSelected = (event) => {
        if (!event.value) {
            return;
        }
        var temp = { ...postData };
        temp.lastName = event.value.lastname;
        temp.firstName = event.value.firstname;
        temp.email = event.value.internalemailaddress;
        temp.userName = event.value.internalemailaddress;
        temp.phone = event.value.phonenumber;
        temp.systemUser = event.value;
        setPostData(temp);
    };

    const accountColumns = [
        {
            field: "accountnumber",
            header: "Account number",
            width: "150px"
        },
        {
            field: "name",
            header: "Name"
        }
    ];

    return (
        <Dialog style={{ width: "60vw !important", height: "43vh !important" }} className="w-screen font-normal" title={postData.isCreateNewUserMode ? 'Create User' : 'Edit User'} onClose={props.cancelEdit} draggable={"true"}>
            <Form className="rounded-t-2xl w-screen mb-2" key={JSON.stringify(postData)} onSubmitClick={props.onSubmit} initialValues={postData} render={(formRenderProps) => (
                <FormElement className="quote-modal rounded-t-2xl" style={{ "width": "400px", "overflowX": "display" }}>
                    <fieldset className="k-form-fieldset grid md:grid-cols-2 gap-2">
                        {
                            (props.item.roleName === "InternalUser" || props.item.roleName === "SchoolUser") &&
                            (
                                <>
                                    <div>
                                        <div>
                                            <label className="fieldlabel">Select account</label>
                                            <Field data={AccountList} disabled={!postData.isCreateNewUserMode || (props.item.roleName === "InternalUser" && props.item.isCreateNewUserMode == true)} name={"account"}
                                                columns={accountColumns} onFilterChange={onAccountFilterChange}
                                                textField={"accountnumber"} onChange={onAccountSelected} loading={accountLoader}
                                                filterable={true} placeholder="Search by Account number" component={FormMultiColumnComboBox}
                                                validator={requiredValidator} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label className="fieldlabel">Select contact</label>
                                            <Field data={ContactList} disabled={!postData.isCreateNewUserMode} name={"contact"}
                                                textField={"fullName"} dataItemKey={"contactid"}
                                                onChange={onContactSelected}
                                                component={FormDropDownList} validator={requiredValidator} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (props.item.roleName === "SchoolCoordinator" || props.item.roleName === "SchoolRep" || props.item.roleName === "InternalAdmin") &&
                            (
                                <>
                                    <div>
                                        <div>
                                            <label className="fieldlabel">Select system user</label>
                                            <Field data={SystemUsersList} disabled={!postData.isCreateNewUserMode} name={"systemUser"}
                                                loading={systemUserLoader} onFilterChange={onSystemFilterChange}
                                                textField={"fullname"} dataItemKey={"systemuserid"}
                                                onChange={onSystemUserSelected}
                                                filterable={true} component={FormDropDownList} validator={requiredValidator} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div>
                            <label className="fieldlabel">Email</label>
                            <Field name={"email"} component={FormInput} validator={emailValidator} />
                        </div>
                        <div className="mb-3">
                            <label className="fieldlabel">User name</label>
                            <Field name={"userName"} component={FormInput} disabled={!postData.isCreateNewUserMode} validator={requiredValidator} />
                        </div>
                        <div>
                            <label className="fieldlabel">First name</label>
                            <Field name={"firstName"} component={FormInput} validator={requiredValidator} />
                        </div>
                        <div>
                            <label className="fieldlabel">Last name</label>
                            <Field name={"lastName"} component={FormInput} validator={requiredValidator} />
                        </div>
                        <div>
                            <label className="fieldlabel">Phone number</label>
                            <Field name={"phone"} component={FormInput} validator={phoneValidator} />
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ justifyContent: "center", alignItems: "center" }}>
                        <button type={"submit"} className="button-save"
                            onClick={formRenderProps.onSubmit}>{postData.isCreateNewUserMode ? 'Save' : 'Update'}</button>
                        <button type={"submit"} className="button-cancel" onClick={props.cancelEdit} >Cancel</button>
                    </div>
                </FormElement>
            )} />
        </Dialog>
    );
};

export default Edituser;