import React, { useSyncExternalStore } from 'react';


/**
 * This component displays the Privacy Policy
 * 
 */

const Privacypolicy=(props)=> {
    return (
        <div>
            <h1 className='text-center pt-5 text-primary'>Privacypolicy </h1>
        </div>
    );
}

export default Privacypolicy;