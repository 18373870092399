import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
// import Review from "../ExternalDashboard/components/Grades/Review";
import SchoolInfoWizard from '../ExternalDashboard/components/SchoolInfoWizard/SchoolInfoWizard';
import Profile from "../ExternalDashboard/components/profile/profile";
import ProgramScheduleWizard from "../ExternalDashboard/components/ProgramScheduleWizard/ProgramScheduleWizard"
import Grades from "../ExternalDashboard/components/Grades/Grade";
import Home from "../ExternalDashboard/components/home/home"
import OrderEntryWizard from "../ExternalDashboard/components/OrderEntryWizard/OrderEntryWizard";
import Noaccess from '../Login/components/Noaccess';
import Forms from "../ExternalDashboard/components/Forms/Forms";
import Referral from "../ExternalDashboard/components/Referral/referral";

/**
 * This component contains all External Dashboard routes
 * 
 */

function ExternalDashboardRouting(props) {
    const [newheading, setNewheading] = useState("")
    const location = useLocation();
    useEffect(() => {
        if (window.location.pathname === "/externaldashboard") {

            $(".route-to-dashboard").addClass("hide-button")
        }
        else {
            $(".route-to-dashboard").removeClass("hide-button")
        }
    }, [location])
    
    return (
        <Routes>
            <Route exact path="/externaldashboard" element={<Home heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/externaldashboard/grades" element={<Grades heading={newheading} onLoaderRaise={props.onLoaderRaise} onAlert={props.onAlert}/>} />
            <Route exact path="/externaldashboard/ProgramScheduleWizard" element={<ProgramScheduleWizard heading={newheading} onLoaderRaise={props.onLoaderRaise} onAlert={props.onAlert} />} />
            <Route exact path='/externaldashboard/schoolinfo' element={<SchoolInfoWizard heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="externaldashboard/profile/*" element={<Profile onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/externaldashboard/OrderEntryWizard" element={<OrderEntryWizard heading={newheading} onLoaderRaise={props.onLoaderRaise} onAlert={props.onAlert} />} />
            <Route exact path="/externaldashboard/noaccess" element={<Noaccess onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/externaldashboard/forms" element={<Forms onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/externaldashboard/referral" element={<Referral onLoaderRaise={props.onLoaderRaise} />} />
        </Routes>
    );
}
export default ExternalDashboardRouting;