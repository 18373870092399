import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import DownloadIcon from '@mui/icons-material/Download';
import { formatDate } from "../commonfunctions";

const ViewErrorLog = (props) => {

    //#region " Initialization of State  Values       "

    const [dialogDimension, setDialogDimension] = React.useState([]);

    //#endregion
    const [rowitem, setRowItem] = React.useState({
        userName: props.item.userName,
        exception: props.item.exception,
        innerException: props.item.innerException,
        stackTrace: props.item.stackTrace,
        path: props.item.path,
        requestBody: props.item.requestBody ? (JSON.stringify(JSON.parse(props.item.requestBody), undefined, 4)) : "",
        originDate: formatDate(props.item.originDate),
    });

    //#region "     On Load     "
    React.useEffect(() => {
        var neededheight = window.innerHeight - (window.innerHeight / 5)
        var neededwidth = window.innerWidth - (window.innerWidth / 10)
        setDialogDimension([neededheight, neededwidth]);
    }, []);
    //#endregion



    return (
        <Dialog className="w-screen" title={`Detailed error log`} onClose={props.cancelEdit} height={dialogDimension[0]} width={dialogDimension[1]} >
            {
                <div>
                    <Form className="rounded-t-2xl w-screen flex" initialValues={rowitem}
                        render={(formRenderProps) => (
                            <FormElement className="quote-modal rounded-t-2xl font-normal w-full">
                                <fieldset className="k-form-fieldset grid md:grid-cols-3 gap-2">
                                    <div>
                                        <label className="fieldlabel">User name</label>
                                        <Field name={"userName"} component={Input} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">path</label>
                                        <Field name={"path"} component={Input}/>
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Time stamp</label>
                                        <Field name={"originDate"} component={Input}/>
                                    </div>
                                </fieldset>
                                <fieldset className="k-form-fieldset grid grid-cols gap-2">
                                    <div>
                                        <Label className="fieldlabel">Exception</Label>
                                        <Field name={"exception"} component={TextArea} />
                                    </div>
                                    <div>
                                        <Label className="fieldlabel">Inner exception</Label>
                                        <Field name={"innerException"} component={TextArea} />
                                    </div>
                                    <div>
                                        <Label className="fieldlabel">Stack trace</Label>
                                        <Field name={"stackTrace"} component={TextArea} />
                                    </div>
                                    <div>
                                        <Label className="fieldlabel">Request body</Label>
                                        <Field name={"requestBody"} component={TextArea} />
                                    </div>
                                </fieldset>
                                <div className="k-form-buttons">
                                    <button className="button-cancel" onClick={() => props.cancelEdit()}>
                                        Cancel
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            }
        </Dialog>
    );
};

export default ViewErrorLog;
