import React from 'react';
import Navbar from '../Dashboard/components/Navbar/Navbar';
import Notifications from '../Notifications/Messages';
import { useState, useEffect } from 'react';
import CommonVariables from '../variables';


function Dashboardlayout(props) {
    useEffect(function () {
        let localstorageData = CommonVariables.getlocalstorageData()
        if (localstorageData.accessToken) {
            Notifications.ShowSuccessMessage(localstorageData.email ? "Welcome " + localstorageData.email : "Welcome User");
        }
    }, []);
    return (
        <div>
            <Navbar {...props} />
            {props.RouterInfo.children}
        </div>
    );
}

export default Dashboardlayout;