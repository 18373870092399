import { useState, useEffect } from "react";
import React from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import '../style/logincommon.css';
import CommonVariables from "../../variables";
import Notifications from "../../Notifications/Messages";
import APIConnector from "../../apiconnector";
import { Input } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormDropDownList, FormMultiColumnComboBox } from "../../ExternalDashboard/components/SchoolInfoWizard/form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
    phoneValidator,
} from "../../Dashboard/components/validators";
import { Document, Page, pdfjs } from 'react-pdf';
import PortalAgreement from '../components/PortalAgreement.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * This component displays the user contract
 * 
 */

function Terms(props) {

    //#region " Initialization of State  Values       "
    const navigation = useNavigate();
    const [rowitem, setRowItem] = React.useState({});
    const [Contract, setContract] = useState();
    //#endregion

    //#region "     On Load         "
    //Commented this as of now not required
    useEffect(function () {
        $(".login-footer").hide();

    }, []);
    //#endregion

    //#region "    Contract Actions       "
    function isCanvasEmpty(canvas) {
        const blankCanvas = document.createElement('canvas');
        blankCanvas.width = canvas.width;
        blankCanvas.height = canvas.height;
        return canvas.toDataURL() === blankCanvas.toDataURL();
    }
    function clear() {
        document.querySelector("canvas").width = document.querySelector("canvas").width
    };

    function onSubmit(event) {
        let canvasElement = document.querySelector(".canvas");
        //if (isCanvasEmpty(canvasElement)) {
        //    Notifications.ShowWarningMessage("Please sign in the page");
        //    canvasElement.focus();
        //    return;
        //}
        let data = {
            Signature: canvasElement? canvasElement.toDataURL():"",
            initials: event.initials,
            firstname: event.firstname,
            lastname: event.lastname,
            mobilephone: event.mobilephone,
            telephone1: event.telephone1,
            //telephone2: event.telephone2,
            enrollment: event.enrollment,
            contacttypeother: event.contacttypeother,
            emailaddress1:event.ContactEMaiil,
        };
        window.scrollTo(0, 0);//Moving to top
        props.onLoaderRaise(true, "Submitting data....", { marginTop: "147px" });
        APIConnector.Post(CommonVariables.urls.contractAccept, data).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response || !response.isSuccess) {
                Notifications.ShowErrorMessage(response.message ? response.message : "Contract submission failed. Please try again later");
                return;
            }
            Notifications.ShowSuccessMessage("Contract submitted successfully");
            Notifications.ShowInformationMessage("Waiting for approval. Please login again after sometime");
            navigation("/");
        });
    };
    //#endregion

    //#region "     Signature Canvas Actions        "
    function createCanvas() {
        let isDrawing = false;
        let x = 0;
        let y = 0;
        const canvas = document.querySelector(".canvas");
        const context = canvas.getContext('2d');
        canvas.addEventListener('mousedown', e => {
            x = e.offsetX;
            y = e.offsetY;
            isDrawing = true;
        });
        canvas.addEventListener('mousemove', e => {
            if (isDrawing === true) {
                drawLine(context, x, y, e.offsetX, e.offsetY);
                x = e.offsetX;
                y = e.offsetY;
            }
        });
        window.addEventListener('mouseup', e => {
            if (isDrawing === true) {
                drawLine(context, x, y, e.offsetX, e.offsetY);
                x = 0;
                y = 0;
                isDrawing = false;
            }
        });
        function drawLine(context, x1, y1, x2, y2) {
            context.beginPath();
            context.strokeStyle = 'black';
            context.lineWidth = 1;
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.stroke();
            context.closePath();
        }
    };
    //#endregion


    return (
        <div style={{ "position": "relative" }} className=''>
            <div className="container-full">
                <h3 className="mb-5 mt-1 pt-1 primary-heading">Terms and Conditions</h3>
                <Document file={PortalAgreement} >
                    <Page pageNumber={1} width={1200} height={1400} />
                </Document>
                <div className="row ml-5 mr-5 mt-5">
                    <div className="mr-12">
                        <label style={{ fontSize: "20px", fontWeight: "normal" }}>Please supply us with a contact name,title,phone and email address in the event we need to contact someone when school is not in session.</label>
                    </div>
                    <Form className="rounded-t-2xl w-screen mb-2" onSubmit={onSubmit} initialValues={rowitem} render={(formRenderProps) => (
                        <FormElement className="quote-modal rounded-t-2xl" style={{}}>
                            <fieldset className="k-form-fieldset grid md:grid-cols-2 gap-2">
                                <div >
                                    <label className="fieldlabel">Contact first name</label>
                                    <Field name={"firstname"} component={FormInput} validator={requiredValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Contact last name</label>
                                    <Field name={"lastname"} component={FormInput} validator={requiredValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Contact e-mail</label>
                                    <Field name={"ContactEMaiil"} component={FormInput} validator={emailValidator} />
                                </div>
                                <div>
                                    <label className="fieldlabel">School phone</label>
                                    <Field name={"telephone1"} component={FormInput} validator={phoneValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Contact cell phone</label>
                                    <Field name={"mobilephone"} component={FormInput} validator={phoneValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Title</label>
                                    <Field name={"contacttypeother"} component={FormInput} validator={requiredValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">School enrollment</label>
                                    <Field name={"enrollment"} component={FormInput} maxLength="6" validator={requiredValidator} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Enter initials here.</label>
                                    <Field name={"initials"} component={FormInput} maxLength="3" validator={requiredValidator} />
                                </div>
                            </fieldset>
                            <div className="signaturepad float-right">
                                <label className="fieldlabel">Please sign in to accept the agreement:</label>
                                <canvas width={348} height={200} className="canvas" onMouseMove={createCanvas}></canvas>
                                <div className="flex flex-row justify-evenly mt-0">
                                    <button className=" my-3 mb-4 button-cancel" onClick={clear}>Clear</button>
                                    <button type="submit" className="button-save my-3 mb-4" disabled={!formRenderProps.allowSubmit}>Submit</button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                    />
                </div>
            </div>
        </div>
    );
}
export default Terms;