import React from 'react';
import {  toast } from 'react-toastify';

const Notifications={

ShowErrorMessage(msg){
    toast.error(msg,{position: toast.POSITION.TOP_CENTER});
   // toast.error(msg,{position: toast.POSITION.BOTTOM_RIGHT});    
},

 ShowSuccessMessage(msg){
    toast.success(msg,{position: toast.POSITION.TOP_CENTER});
   // toast.success(msg,{position: toast.POSITION.BOTTOM_RIGHT});    
 },

 ShowWarningMessage(msg){
    toast.warning(msg,{position: toast.POSITION.TOP_CENTER});
   // toast.warning(msg,{position: toast.POSITION.BOTTOM_RIGHT});    
 },

 ShowInformationMessage(msg){
    toast.info(msg,{position: toast.POSITION.TOP_CENTER});
   // toast.info(msg,{position: toast.POSITION.BOTTOM_RIGHT});    
 }
};

export default Notifications;
