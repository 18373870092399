import React, { useState } from 'react';
import "./navbar.css";
import SKLogo from '../../../images/SKLogo.png';
// import SKSLogo from '../../../images/SKSLogo.png';

function Navbar(props) {
    return (
        <div id="hide-navbar" className='login-navbar flex nav-tabs'>
            <img className="text-center pr-3" src={SKLogo} alt="SchoolKidz and School Specialty Logo" />
            {/* <img className="text-center" src={SKSLogo} alt="SchoolKidz" /> */}
        </div>
    );
}

export default Navbar;