import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

const ReferralProgramDetails = props => {

    //#region "     On Load of Component    "
    const [data, setData] = React.useState();
    React.useEffect(() => {
    }, []);

    //#endregion

    return <Dialog title={`Referral Program details`} onClose={props.cancelModal}>
        <div className="text-xs tracking-wide" style={{ marginTop: "25px", padding: "6px" }}>
            <div><label style={{ fontSize: "large", padding: "3px" }}>Referral Program</label></div>
            <div style={{ padding: "5px", fontSize: "15px" }}>
                <p>Are you interested in extra money for your school organization? We are offering a bonus for referring a new school to us that participates in the SchoolKidz school supply kit program for this school year. Based on a final net sale amount of the school you refer, your school will earn a percentage back of that sale!</p>
                <p><strong>You can earn the following percentages:</strong></p>
                <ul style={{ marginLeft: "35px" }}>
                    <li style={{ listStyle: "disc", marginBottom: "5px" }}>$0 → $4,999 = 1.0% </li>
                    <li style={{ listStyle: "disc" }}>$5,000+ = 2.0%</li>
                </ul>
                <p><strong>Here's a quick example:</strong></p>
                <p><strong>ABC Elementary</strong> refers <strong>123 Academy</strong> to the SchoolKidz Program. At the end of <strong>123 Academy's</strong> program, they bring in a net sale amount of <strong>$7,500.</strong></p>
                <p>$7,500 x .02 = <strong>$150</strong></p>
                <p>(Net Sale Amount) x (Percentage Earned) = Cash Bonus</p>
                <p><strong>Program Criteria</strong></p>
                <ul style={{ marginLeft: "35px" }}>
                    <li style={{ listStyle: "disc", marginBottom: "5px" }}>Please complete this form and return to your representative prior to the new school signing up.</li>
                    <li style={{ listStyle: "disc" }}>The school referred must be paid in full in order for the referring school to receive this bonus.</li>
                    <li style={{ listStyle: "disc" }}>The school referred and referring school must participate in the SchoolKidz Program and use SchoolKidz flyers/order forms to promote the sale.</li>
                    <li style={{ listStyle: "disc" }}>All referring schools will be issued a check in November.</li>
                    <li style={{ listStyle: "disc" }}>Referred school must purchase a minimum of $500 (net sales) in order for referral bonus to be paid.</li>
                </ul>
            </div>
        </div>
    </Dialog>;
};

export default ReferralProgramDetails;