import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

const OrderNameEntryDetails = props => {

    //#region "     On Load of Component    "
    const [data, setData] = React.useState();
    React.useEffect(() => {
    }, []);

    //#endregion

    return <Dialog title={`Order Name Entry details`} onClose={props.cancelEdit}>
        <div className="text-xs tracking-wide" style={{ marginTop: "25px", padding: "6px" }}>
            <div><label style={{ fontSize: "large", padding: "3px" }}>Order Name Entry Details</label></div>
            <div style={{ padding: "5px", fontSize: "15px" }}>
                <p>You will be manually entering all your school's paper orders in this section. If your school is purchasing additional kits, enter those with “Extra” as the first name and “Kit” as the last name</p>
                <p><strong>Entry Steps include:</strong></p>
                <ul style={{ marginLeft: "35px" }}>
                    <li style={{ listStyle: "disc" }}>ADD NEW button</li>
                    <li style={{ listStyle: "disc" }}>Select appropriate drop down (grade) for each student. You will do this for every student who you collected a paper order from. </li>
                    <li style={{ listStyle: "disc" }}>On the next screen you will see your order tally sheet with all names listed. You will then be able to review your summary and confirm totals by grade.</li>
                    <li style={{ listStyle: "disc" }}>Once all paper forms are entered, click SUBMIT </li>
                </ul>
            </div>
        </div>
    </Dialog>;
};

export default OrderNameEntryDetails;