import React from "react";

/**
 * This component displays the loading symbol
 * 
 */
export default function LoadingSpinner(props) {
    return (
        <div className="spinner-container">
            <div className="mt-2" style={{
                zIndex: "1",
                position: "absolute",
                textAlign: "center",
                width: "100%"
            }}>
                <label style={{ fontSize: "larger", color: "#e4a7a7", marginTop: props.LoaderStyles.marginTop }}>{props.message}</label>
            </div>
            <div className="loading-spinner">
            </div>
        </div>
    );
}