import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import Notifications from '../../../Notifications/Messages';
import APIConnector from '../../../apiconnector';
import CommonVariables from '../../../variables'
import { useNavigate } from 'react-router';
import OrderNameEntry from '../OrderTally/OrderNameEntry';
import OrderTallySheet from '../OrderTally/OrderTallySheet';
import CompletePage from './completePage';
import '../ProgramScheduleWizard/ProgramSchduleWizardstyles.css';
import { WhereAmI } from "../../../UserActivity";
import DownloadIcon from '@mui/icons-material/Download';
import { triggerBase64Download } from 'common-base64-downloader-react';


/**
 * This component displays the order tally sheet
 * 
 */ 

const OrderEntryWizard = (props) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [step, setStep] = useState(0);
    const [formState, setFormState] = useState({});
    const [currentStage, setcurrentStage] = React.useState(0);

    const [steps, setSteps] = useState([{
        label: 'Order Name Entry',
        isValid: undefined
    }, {
        label: 'Order Tally Sheet',
        isValid: undefined
    }, {
        label: 'Completed',
        isValid: undefined
    }]);

    useEffect(() => {

        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetUserActivity, {}).then(function (Activityresponse) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(Activityresponse))
                return;
            setcurrentStage(Activityresponse.stage);

            GetUserInfo();
        });
    }, []);

    var lastStepIndex = steps.length - 1;
    var isLastStep = lastStepIndex === step;
    const onStepSubmit = React.useCallback(event => {
        setStep(() => Math.min(step + 1, lastStepIndex));


        const currentSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === step ? true : currentStep.isValid
        }));

        setSteps(currentSteps);
        if (isLastStep) {
            if (!formState.tallySheetData) {
                Notifications.ShowWarningMessage("Please complete the Order Tally sheet step to submit");
                setStep(2);
                return;
            }
            const AlertNo = () => {
                props.onAlert(false);
            };
            const AlertYes = (item) => {
                props.onAlert(false);
            
                let inputJson = { ...formState.tallySheetData };
                inputJson.tallySheetInfoModel.submit_flag = true;
                inputJson.tallySheetInfoModel.userId = CommonVariables.getlocalstorageData().userId;
                if (typeof (inputJson.tallySheetInfoModel.loading_dock) == "object") {
                    inputJson.tallySheetInfoModel.loading_dock = inputJson.tallySheetInfoModel.loading_dock.value;
                }
                props.onLoaderRaise(true, "Saving...", { marginTop: "145px" });
                APIConnector.Post(CommonVariables.urls.OrderTallySheetSave, inputJson).then(function (response) {
                    props.onLoaderRaise(false);
                    if (!CommonVariables.isError(response))
                        return;
                    Notifications.ShowSuccessMessage("Order Entry successfully submitted.");
                    navigate("/externaldashboard");
                });
            
            
        }
        var alertDataObj = {
            title: "Please Confirm",
            body: "This will submit and lock your entry. Do you want to continue?",
            No: AlertNo,
            NoTitle: "No",
            YesTitle:"Yes",
            Yes: AlertYes,
            datatoPassOnYes:""
        };
        props.onAlert(true, alertDataObj);
        }
    }, [steps, isLastStep, step, lastStepIndex]);

    const onPrevClick = React.useCallback(event => {
        event.preventDefault();
        setStep(() => Math.max(step - 1, 0));
    }, [step, setStep]);

    const handleChange = (e) => {
        setStep(e.value);
    };
    const Tallydownload=()=>{
        var obj={
            "parameters": `&p_customernumber=${userData.custnmbr}`,
            "reportname": CommonVariables.ConfigData.Reports.TallySheetReport
          }
        props.onLoaderRaise(true, "Generating Report ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetReports, obj).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            PdfDownload("OTallySheetAll.pdf", resp.reportdata)
        });
    }
    const GetUserInfo = function () {
        let input = {
            userId: localStorage.getItem("userId"),
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            if (resp.userName) {
                setUserData(resp);
            }


        });
    };
    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }}>
        {currentStage === WhereAmI.TallySheet &&
            (
                <>
                    <Stepper value={step} items={steps} onChange={handleChange} />
                    {
                        <Form
                            initialValues={formState}
                            onSubmitClick={onStepSubmit}
                            render={formRenderProps =>
                                <div style={{ alignSelf: 'center' }} className="m-auto w-responsive">
                                    <FormElement className='w-full ml-5' style={{}}>
                                        {
                                            (
                                                (steps[step].label === "Order Name Entry" && <OrderNameEntry onLoaderRaise={props.onLoaderRaise} formState={formState} />) ||
                                                (steps[step].label === "Order Tally Sheet" && <OrderTallySheet onLoaderRaise={props.onLoaderRaise} formState={formState} />) ||
                                                (steps[step].label === "Completed" && <CompletePage onLoaderRaise={props.onLoaderRaise} formState={formState} />)
                                            )
                                        }
                                        <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                                        <div id="footerID" style={{ width: '100% !important' }}
                                            className={'WizardFooter'}>
                                            <div style={{ textAlign: "center" }}>
                                                {step !== 0 ? <Button
                                                    className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                                    onClick={onPrevClick}>Previous
                                                </Button> : undefined}
                                                <span className="step-text-class">Step {step + 1} of {steps.length}</span>
                                                <Button id="btnparentSubmit"
                                                    className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                                    onClick={formRenderProps.onSubmit}>
                                                    {isLastStep ? 'Submit' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </FormElement>
                                </div>
                            }
                        />
                    }
                </>
            )
        }
        {
            currentStage === WhereAmI.ProgramSchedule &&
            (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <h4>
                        Please complete Program schedule process
                    </h4>
                    <Link to="/externaldashboard/ProgramScheduleWizard" style={{ display: "block", textDecoration: "underline" }}
                        className="text-decoration-none text-primary mt-4 rounded-0 mx-0">Go to Program Schedule
                    </Link>
                </div>
            )
        }
        {
            currentStage <= WhereAmI.GradeWorksheet &&
            (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <h4>
                        There are unapproved Grade Worksheets.Please approve it first
                    </h4>
                    <Link to="/externaldashboard/grades" style={{ display: "block", textDecoration: "underline" }}
                        className="text-decoration-none text-primary mt-4 rounded-0 mx-0">Go to Grade Worksheets
                    </Link>
                </div>
            )
        }
        {
            currentStage != WhereAmI.TallySheet && currentStage > WhereAmI.TallySheet &&
            (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <h4>
                        Order Tally Sheet is already submitted. Click on below link to see the Tally Sheet Info
                    </h4>
                   <br/>
                    <div className="sok-tile" >
                    <DownloadIcon />
                    <div className="sok-tile-name" onClick={() => { Tallydownload() }}>
                    TallySheetAll.pdf
                    </div>
                    </div>
                </div>
            )
        }
    </div>;
};
export default OrderEntryWizard