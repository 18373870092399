import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import EditOrderNameEntry from "./EditOrderNameEntry";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import PrintIcon from '@mui/icons-material/Print';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { filterBy } from "@progress/kendo-data-query";
import Notifications from "../../../Notifications/Messages";
import { Filter, Operators, TextFilter, NumericFilter, BooleanFilter } from "@progress/kendo-react-data-tools";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import RefreshIcon from '@mui/icons-material/Refresh';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import HelpIcon from '@mui/icons-material/HelpOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import PreviewOrderTallySheet from './PreviewOrderTallySheet';
import OrderNameEntryDetails from './OrderNameEntryDetails';

const OrderNameEntry = (props) => {

    //const initialFilter = {
    //    logic: "and",
    //    filters: [{
    //        field: "grade",
    //        operator: "contains",
    //        value: '',
    //    }]
    //};
    //const [filter, setFilter] = React.useState(initialFilter);
    const [openForm, setOpenForm] = React.useState(false);
    const [openViewForm, setOpenViewForm] = React.useState(false);
    const [openDetailsForm, setOpenDetailsForm] = React.useState(false);
    const [gradeData, setGradeData] = React.useState([]);
    const [editItem, setEditItem] = React.useState({
        OrderID: 1,
    });
    const [data, setData] = React.useState([]);

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion



    useEffect(() => {
        
        GetGradeTypeData();
    }, []);

    const GetGradeTypeData = () => {
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.OrderNameGradesList).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setGradeData(response);
            RefreshOrderNameEntryGrid();
        });
    };

    const RefreshOrderNameEntryGrid = (message) => {
        props.onLoaderRaise(true, "Getting Order Name details ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.OrderNameEntryList).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setData(response);
            if (message)
                Notifications.ShowSuccessMessage(message);
        });
    };

    const enterEdit = (item) => {
        let temp = { ...item };
        if (temp.grade && temp.grade.length > 0)
            temp.grade = gradeData.filter(function (each) { return each.grade === temp.grade })[0];
        if (temp.type && temp.type.length > 0)
            temp.type = gradeData.filter(function (each) { return each.type === temp.type })[0];
        setEditItem(temp);
        setOpenForm(true);
    };

    const enterAdd = () => {
        setEditItem({ isfromAdd: true, grade: "", type: "", custnmbr: gradeData[0].custnmbr });
        setOpenForm(true);
    };

    const enterView = () => {
        setOpenViewForm(true);
    };

    const enterDetails = () => {
        setOpenDetailsForm(true);
    };
    //#region " Save/Update Functionlaity        "
    const handleSave = (event) => {
        let inputJson = {
            rowid: editItem.rowid,
            origin_date: editItem.origin_date,
            origin_by: editItem.origin_by,
            custnmbr: event.custnmbr,
            grade: event.grade.grade,
            type: event.type.type,
            firstname: event.firstname,
            lastname: event.lastname,
            ordered_kit: event.ordered_kit
        };

        props.onLoaderRaise(true, editItem.isfromAdd ? "Saving..." : "Updating...", { marginTop: "145px" });
        APIConnector.Post((editItem.isfromAdd ? (CommonVariables.urls.OrderNameEntryCreate) : (CommonVariables.urls.OrderNameEntryUpdate)), inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            RefreshOrderNameEntryGrid("Order Name" + (editItem.isfromAdd ? " added " : " updated ") + "successfully");
        });
    };

    const enterDelete = (dataItem) => {
        props.onLoaderRaise(true, "Deleting...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.OrderNameEntryDelete, dataItem).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            RefreshOrderNameEntryGrid("Order Name deleted successfully");
        });
    };
    //#endregion

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleCancelView = () => {
        setOpenViewForm(false);
    };

    const handleCancelDetails = () => {
        setOpenDetailsForm(false);
    };


    const MyEditCommandCell = (props) => {
        return (
            <td style={{ "textAlign": "center" }} >
                <EditIcon onClick={() => enterEdit(props.dataItem)} title="Review" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer", "textAlign": "center" }} />
            </td>)
    };
    const MyDeleteCommandCell = (props) => {
        return (
            <td style={{ "textAlign": "center" }} >
                <DeleteIcon onClick={() => enterDelete(props.dataItem)} title="Review"
                    style={{ "display": "inline-block", "borderColor": "transparent", "color": "red", "cursor": "pointer", "textAlign": "center" }} />
            </td>)
    };
    const grid = (
        <Grid className=" rounded-2xl border-b-4" style={{ height: "258px" }}
            data={data}
            sortable={true}
            pageable={true}
            take={100}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <Column field="custnmbr" title="Acct #" width={90} />
            <Column field="grade" title="Grade" width={70} />
            <Column field="type" title="Type" width={80} />
            <Column field="firstname" title="First Name" width={260} />
            <Column field="lastname" title="Last Name" width={260} />
            <Column field="ordered_kit" title="Ordered Kit" width={120} />
            <Column cell={MyEditCommandCell} title="Edit" width={60} />
            <Column cell={MyDeleteCommandCell} title="Delete" width={75} />
        </Grid>
    );

    return <div className="PageStyle pt-10">
        <div>
            <h5 className="wizardStepHeadLabel">Please enter all paper orders you received. Click Add New to begin entering each name.
                <br /> You can preview your order summary by clicking on "Preview Tallysheet".
                <br /> When you have finsihed adding ALL your paper orders, click NEXT to move on to Step 2.
            </h5>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-7" style={{ marginTop: "auto", marginLeft: "43%", textAlign: "end" }}>
                    <span title="Excel" style={{ marginRight: "15px", font: "caption" }}>Export to Excel
                        <img onClick={exportExcel}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRIidWVv0sjURDHP7MmJxjBVpOAYUUE8T+w8Ed5tdUVlgdWgnHxrFyvEnNXWlhYWVlZ6T8gXC9Y2GQvniZBG/HACxeTzBWbQjYv2eda3bd8uzOfmfk+3sD/LokeuPv1j4geAvnBobobeDk/DuD0Ii2SCxWQHbdUTQCIrRxosaBKEELqe28FxCr4kv0lHZZCiG4NgiQCvAWSGGALeRfABIl+TyVJ6pZqGj3rue9dWXUgsB54WQGugBfaTKKs2MRaARS2s35tRNFDRI4Dd6KK8NUm1nZE48OjsqZDf4/opC/cm9oqyKxNoLXJorpZeSr8/lnMX4L0mNlP9iaLnBeofMgcPKQaf1pnwAyAODpfLuZ+9A2LHphuCNAQR2dQZ7mDpoebndNm2ikDY13z+8pyRLpf3sjeqeqmKN51M/cowrdoYabiLEck01Pf6ycKcwBupt5WU58G2Xrw6XXCuLm/VqKnwjY5mE2+JWYnPOOM3nvjz+HCkR3zX+HG6+lARD4Dt4MAI6JHi76mwpWpu/2SGztIIrdU3QLpPtXiB96EAfp+iG/a0f8Ajj+0Auj2X9kAAAAASUVORK5CYII="
                            style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }}
                            alt="Export to Excel"
                        />
                    </span>
                    <span title="Preview Tallysheet data" style={{ marginRight: "25px", font: "caption" }}>Preview Tallysheet
                        <PreviewIcon onClick={enterView} title="Preview Tallysheet data"
                            style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Add New Item" style={{ marginRight: "25px", font: "caption" }}>Add New
                        <NoteAddIcon onClick={enterAdd} style={{ marginBottom: "9px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshOrderNameEntryGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
        </div>
        <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
            {grid}
        </GridPDFExport>
        <ExcelExport
            data={data}
            ref={(exporter) => {
                _export = exporter;
            }}>
            {grid}
        </ExcelExport>
        {openForm && (
            <EditOrderNameEntry
                cancelEdit={handleCancelEdit}
                onSubmit={handleSave}
                item={editItem}
                gradeData={gradeData}
                onLoaderRaise={props.onLoaderRaise}
            />
        )}
        {openViewForm && (
            <PreviewOrderTallySheet
                cancelEdit={handleCancelView}
                onLoaderRaise={props.onLoaderRaise}
            />
        )}
        {openDetailsForm && (
            <OrderNameEntryDetails
                cancelEdit={handleCancelDetails}
                onLoaderRaise={props.onLoaderRaise}
            />
        )}
    </div>;
};

export default OrderNameEntry;