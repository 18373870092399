import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TaskIcon from '@mui/icons-material/Task';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from '@mui/icons-material/Update';
import Notifications from "../../../Notifications/Messages";
import RefreshIcon from '@mui/icons-material/Refresh';
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";

/**
 * This component displays the information of the delivery week
 * 
 */


const DeliveryWeekInfo = props => {
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "desiredDeliveryWeek",
            operator: "isnotnull",
            value: '',
        }]
    };

    const [filter, setFilter] = React.useState(initialFilter);

    //#region "     On Load of Component    "
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        RefreshGrid();
    }, []);

    function RefreshGrid() {
        props.onLoaderRaise(true, "Loading delivery week list ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetDeliveryWeekList).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            response = response.map((item) => {
                item.desiredDeliveryWeek = new Date(item.desiredDeliveryWeek);
                return item;
            });
            setData(response);
        });
    };
    //#endregion

    //#region "     Delete functionality        "
    const Delete = (props) => (
        <DeleteCell
            {...props}
            remove={remove}
            cancel={cancelField}
        />
    );
    const remove = (dataItem) => {
        props.onLoaderRaise(true, "Deleting...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.DeleteDeliveryWeek, dataItem).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("Delivery week deleted succesfully");
            RefreshGrid();
        });
    };
    //#endregion


    const EditCell = (props) => {
        const { dataItem } = props
        return (
            <td colSpan="1" className="k-grid-edit-cell" aria-colindex="9" aria-selected="false" data-grid-col-index="8" role="gridcell" style={{ textAlign: "center" }}>
                {
                    dataItem.inEdit ?
                        (
                            dataItem.isNotSaved ?
                                <span title="save">
                                    <SaveIcon onClick={() => props.save(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                                </span> :
                                (
                                    <span title="update">
                                        <UpdateIcon onClick={() => props.update(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                                    </span>
                                )
                        ) :
                        (
                            <span title="Edit">
                                <EditIcon onClick={() => props.editField(dataItem)} title="Print" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                            </span>
                        )
                }
            </td>
        );
    };



    const DeleteCell = props => {
        const { dataItem } = props;
        return <td className="k-command-cell" style={{ textAlign: "center" }}>
            {
                dataItem.inEdit ? (<span title="Cancel">
                    <CancelIcon onClick={() => props.cancel(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                </span>)
                    : (<span title="Delete">
                        <DeleteIcon onClick={() => props.remove(dataItem)} style={{ "display": "inline-block", "borderColor": "transparent", "color": "rgb(232 71 111)", "cursor": "pointer" }} />
                    </span>)
            }
        </td>
    };

    //#region "     Edit Functionality      "
    const Edit = (props) => (
        <EditCell
            {...props}
            editField={editField}
            update={updateField}
            save={saveField}
        />
    );
    const editField = (dataItem) => {
        var temp = [...data]
        let index = temp.findIndex(record => record.rowid === dataItem.rowid);
        temp[index].inEdit = true;
        setData(temp);
    };

    const cancelField = (dataItem) => {
        var temp = [...data]
        let index = temp.findIndex(record => record.rowid === dataItem.rowid);
        temp[index].inEdit = false;
        setData(temp);
    };
    const updateField = (dataItem) => {
        props.onLoaderRaise(true, "Updating...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateDeliveryWeek, dataItem).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("Delivery week updated succesfully");
            RefreshGrid();
        });
    };
    //#endregion

    function CreateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    //#region "     Add New Functonality    "
    const addNew = () => {
        const newDataItem = {
            inEdit: true,
            desiredDeliveryWeek: new Date(),
            isNotSaved: true,
            rowid: CreateUUID()
        };
        setData([newDataItem, ...data]);
    };

    const saveField = (dataItem) => {
        let inputJson = {
            desiredDeliveryWeek: dataItem.desiredDeliveryWeek,
        };
        props.onLoaderRaise(true, "Saving...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.CreateDeliveryWeek, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("Delivery week saved succesfully");
            RefreshGrid();
        });
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
            item.rowid === event.dataItem.rowid
                ? { ...item, [field]: event.value }
                : item
        );
        setData(newData);
    };
    //#endregion

    //#region "      grid      "
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={100}
            total={data.length}
            editField="inEdit"
            onItemChange={itemChange}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <Column field="desiredDeliveryWeek" editor="date" format="{0:d}" filterable={true} title="Delivery Week" editable={true} width="590px" />
            <Column cell={Edit} title="Save / Update" filterable={false} width="302px" editable={true} />
            <Column cell={Delete} title="Delete" filterable={false} width="280px" />
        </Grid>
    );
    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">{props.heading}</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-6">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "desiredDeliveryWeek",
                                label: "Delivery Week",
                                filter: DateFilter,
                                operators: Operators.date,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-6" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Add New Week" style={{ marginRight: "15px", font: "caption" }}>Add New Week
                        <NoteAddIcon onClick={addNew} style={{ marginBottom: "9px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
        </div>);
};

export default DeliveryWeekInfo;