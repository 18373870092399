import * as React from "react";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import { formatDateOnly } from '../../components/commonfunctions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import EditOrderFormProof from "./EditOrderFormProof";
import EditIcon from '@mui/icons-material/Edit';


/**
 * This component enables to edit the status of Order Form and download the attachments
 * 
 */

const OrderFormProofgrid = props => {
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "tt_name",
            operator: "contains",
            value: '',
        }]
    };

    const [filter, setFilter] = React.useState(initialFilter);
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({
        tt_orderformproofid: 1,
    });
    //#region "     On Load of Component    "
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        RefreshGrid();
    }, []);

    function RefreshGrid(message) {
        props.onLoaderRaise(true, "Loading order form proof list ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.OrderFormProofList).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response)){
                return;
            }
                for (let index = 0; index < response?.length; index++) {
                    response[index].tt_ApprovalDate=formatDateOnly(response[index].tt_ApprovalDate);
                }
            setData(response);
            if (message) {
                Notifications.ShowSuccessMessage(message);
            }
        });
    };
    //#endregion


    //#region "      grid      "

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );

    const EditCommandCell = (props) => {
        return (
            <td className="flex justify-evenly">
                <span title="Edit">
                    <EditIcon onClick={() => props.enterEdit(props.dataItem)} title="Edit" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };

    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };
    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const UpdateStatus = (rowItem) => {
        let inputObj = { ...editItem };
        inputObj.statuscode = rowItem.statusValue.statusValue;
        props.onLoaderRaise(true, "Updating status ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateOrderForm, inputObj).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            RefreshGrid("Status updated Successfully");
        });
    };

    const grid = (
        <Grid className="rounded-2xl border-b-4"
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={1000}
            resizable={true}
            style={{ height: (window.innerHeight - 317) + "px" }}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <Column field="tt_name" title="Acct #" width="80" editable={true} />
            <Column field="tt_orderformtype" title="Order form type" width="160" editable={true} />
            <Column field="tt_StatusText" width="200" title="Status" />
            <Column field="tt_FirstName" title="First name" width="130" editable={true} />
            <Column field="tt_LastName" title="Last name" width="130" editable={true} />
            {/* <Column field="tt_Title" title="Title" width="430" editable={true} />*/}
            <Column field="tt_ApprovalDate" width="150" title="Approval Date" />
            <Column title="Edit" cell={MyEditCommandCell} width="60" />
        </Grid>
    );

    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading ml-4">Program Schedule Submission</h2>
            <div className="row ml-2" style={{ "marginRight": "auto" }}>
                <div className="col-sm-8">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "tt_FirstName",
                                label: "First name",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "tt_LastName",
                                label: "Last name",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "tt_name",
                                label: "Account #",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "tt_Title",
                                label: "Title",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "tt_orderformtype",
                                label: "Order form type",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "tt_ApprovalDate",
                                label: "Approval Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "tt_StatusText",
                                label: "Status",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-4" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
            {openForm && (
                <EditOrderFormProof cancelEdit={handleCancelEdit}
                    onSubmit={UpdateStatus}
                    item={editItem} onLoaderRaise={props.onLoaderRaise} />
            )}
        </div>
    );
};

export default OrderFormProofgrid;