import * as React from "react";
import { Field,Form,FormElement } from "@progress/kendo-react-form";
import { useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { FormInput, FormUpload, FormDatePicker } from "./form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ReferralProgramDetails from "../SchoolInfoWizard/ReferralProgramDetails";
import APIConnector from "../../../apiconnector";
import CommonVariables from "../../../variables";
import {  useNavigate } from "react-router-dom";
import Notifications from "../../../Notifications/Messages";

/**
 * This component enables to submit the referral
 * 
 */

export default function Referral(props) {
    const navigation = useNavigate();
    const [accountId,setAccountId]=React.useState({})
    React.useEffect(()=>{
        APIConnector.Post(CommonVariables.urls.GetSchoolReferral).then(function (resp) {
           setAccountId(resp)
        });
    },[])
    const handleSubmit=(dataItem)=>{
        APIConnector.Post(CommonVariables.urls.SetSchoolReferral, {...dataItem.schoolReferralModel_Step4,referring_school_id:accountId.referring_school_id}).then(function (resp) {
            Notifications.ShowSuccessMessage("Referral Submitted Successfully");
            navigation('/externaldashboard')
        });
    }
    return (
        <Form
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
            marginLeft:"30%"
          }}
        >
            <div style={{ textAlign: "center", paddingLeft: "100px" }}>
                <h2 className="inline-block primary-heading">Submit Referral</h2>
            </div>
                <div style={{ margin: "10px", marginTop: "20px" }} className="row">
                    <div className="col-12 col-md-12">
                        <label className="fieldlabel">Name of school you are referring</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_name"}
                            id={"schoolReferralModel_Step4.referred_school_name"}
                            name={"schoolReferralModel_Step4.referred_school_name"}
                            component={FormInput}
                            validator={requiredValidator}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Address</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_line1"}
                            id={"schoolReferralModel_Step4.referred_school_address_line1"}
                            name={"schoolReferralModel_Step4.referred_school_address_line1"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">City</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_city"}
                            id={"schoolReferralModel_Step4.referred_school_address_city"}
                            name={"schoolReferralModel_Step4.referred_school_address_city"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">State</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_state"}
                            id={"schoolReferralModel_Step4.referred_school_address_state"}
                            name={"schoolReferralModel_Step4.referred_school_address_state"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Zip</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            id={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            name={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <label className="fieldlabel">Contact at referred school</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_name"}
                            id={"schoolReferralModel_Step4.referred_school_contact_name"}
                            name={"schoolReferralModel_Step4.referred_school_contact_name"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Contact phone number</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            id={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            name={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Contact email</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_email"}
                            id={"schoolReferralModel_Step4.referred_school_contact_email"}
                            name={"schoolReferralModel_Step4.referred_school_contact_email"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6" style={{ marginTop: "20px" }}>
                        <label className="fieldlabel">Have you previously spoken with this contact?</label>
                        <Field
                            key={"schoolReferralModel_Step4.contact_spoken_to"}
                            id={"schoolReferralModel_Step4.contact_spoken_to"}
                            name={"schoolReferralModel_Step4.contact_spoken_to"}
                            component={DropDownList}
                            data={["Yes", "No"]}
                        />
                    </div>
                    <div className="col-12 col-md-6" style={{ marginTop: "20px" }}>
                        <label className="fieldlabel">Does the school know we will be contacting them?</label>
                        <Field
                            key={"schoolReferralModel_Step4.school_expecting_call"}
                            id={"schoolReferralModel_Step4.school_expecting_call"}
                            name={"schoolReferralModel_Step4.school_expecting_call"}
                            component={DropDownList}
                            data={["Yes", "No"]}
                        />
                    </div>
                </div>
                <div className="k-form-buttons">
            <button
              type={"submit"}
              className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
              disabled={!formRenderProps.allowSubmit}
              style={{marginLeft:"45%"}}
            >
              Submit
            </button>
          </div>
                </FormElement>
                 )}
                 />
    )
    
};
