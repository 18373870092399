import * as React from "react";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import EditQuoteRevise from "./EditQuoteRevise";
import APIConnector from "../../../../apiconnector";
import CommonVariables from "../../../../variables";
import { filterBy } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import Notifications from "../../../../Notifications/Messages";
import PrintIcon from '@mui/icons-material/Print';
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateOnly } from '../../commonfunctions';

/**
 * This component allows to add response notes and complete the gradeworksheet revision.
 * 
 */

const QuoteRevise = (props) => {
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "status",
            operator: "contains",
            value: '',
        }]
    };
    function RefreshQuoteReviseGrid(msg) {
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetQuoteRevise).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response)){
                return;
            }
                for (let index = 0; index < response?.length; index++) {
                    response[index].change_date=formatDateOnly(response[index].change_date);
                }
            setData(response);
            if (msg)
                Notifications.ShowSuccessMessage(msg);
        });
    };

    React.useEffect(() => {
        RefreshQuoteReviseGrid();
    }, []);

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion


    const EditCommandCell = (props) => {
        return (
            <td className="flex justify-evenly">
                <span title="Edit">
                    <EditIcon onClick={() => props.enterEdit(props.dataItem)} title="Edit" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };

    const [filter, setFilter] = React.useState(initialFilter);
    const [data, setData] = React.useState([]);

    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({
        tt_account: 1,
    });

    const enterEdit = (item) => {
        let QRInputJSON = {
            CUSTNMBR: item.custnmbr,
            Grade: item.grade,
            Type: item.type
        };
        props.onLoaderRaise(true, "Verifying assignment...", { marginTop: "145px" });

        APIConnector.Post(CommonVariables.urls.QuoteRevisionAssignment, QRInputJSON).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess) {
                Notifications.ShowWarningMessage((response && response.message) ? response.message : "Quote revision assignment to user failed");
                return;
            }
            setOpenForm(true);
            setEditItem(item);
        });
    };


    const handleCancelEdit = (msg) => {
        if (msg)
            RefreshQuoteReviseGrid(msg);

        setOpenForm(false);
    };

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={1000}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <GridNoRecords>
                No Quote's available to revise
            </GridNoRecords>
            <Column field="custnmbr" title="Acct #" width="80" />
            <Column field="schoolName" title="School Name" width="250" />
            <Column field="grade" title="Grade" width="70" />
            <Column field="type" title="Type" width="70" />
            <Column field="itemdesc" title="Item Description" width="170" />
            <Column field="new_qty" title="Quantity" width="90" />
            <Column field="status" title="Status" width="200" />
            <Column field="assigned_to_name" title="Assigned To" width="200" />
            <Column field="change_date" title="Change Date" width="150" />
            <Column cell={MyEditCommandCell} filterable={false} width="60" title="Edit" />
        </Grid>
    );

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">Revision Queue</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-8">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "custnmbr",
                                label: "Acct #",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "schoolName",
                                label: "School Name",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "grade",
                                label: "Grade",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "type",
                                label: "Type",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "itemdesc",
                                label: "Item Description",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "new_qty",
                                label: "Item Quantity",
                                filter: NumericFilter,
                                operators: Operators.numeric,
                            },
                            {
                                name: "status",
                                label: "Status",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "assigned_to_name",
                                label: "Assigned To",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "change_date",
                                label: "Change Date",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-4" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end",paddingLeft:"10px" }}>
                    {/*<span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print*/}
                    {/*    <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />*/}
                    {/*</span>*/}
                    <span title="Excel" style={{ marginRight: "15px", font: "caption" }}>Export to Excel
                        <img onClick={exportExcel}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRIidWVv0sjURDHP7MmJxjBVpOAYUUE8T+w8Ed5tdUVlgdWgnHxrFyvEnNXWlhYWVlZ6T8gXC9Y2GQvniZBG/HACxeTzBWbQjYv2eda3bd8uzOfmfk+3sD/LokeuPv1j4geAvnBobobeDk/DuD0Ii2SCxWQHbdUTQCIrRxosaBKEELqe28FxCr4kv0lHZZCiG4NgiQCvAWSGGALeRfABIl+TyVJ6pZqGj3rue9dWXUgsB54WQGugBfaTKKs2MRaARS2s35tRNFDRI4Dd6KK8NUm1nZE48OjsqZDf4/opC/cm9oqyKxNoLXJorpZeSr8/lnMX4L0mNlP9iaLnBeofMgcPKQaf1pnwAyAODpfLuZ+9A2LHphuCNAQR2dQZ7mDpoebndNm2ikDY13z+8pyRLpf3sjeqeqmKN51M/cowrdoYabiLEck01Pf6ycKcwBupt5WU58G2Xrw6XXCuLm/VqKnwjY5mE2+JWYnPOOM3nvjz+HCkR3zX+HG6+lARD4Dt4MAI6JHi76mwpWpu/2SGztIIrdU3QLpPtXiB96EAfp+iG/a0f8Ajj+0Auj2X9kAAAAASUVORK5CYII="
                            alt="Export to Excel"
                            style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }}
                        />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshQuoteReviseGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            <ExcelExport
                data={data}
                ref={(exporter) => {
                    _export = exporter;
                }}>
                {grid}
            </ExcelExport>
            {openForm && (
                <EditQuoteRevise
                    cancelEdit={handleCancelEdit}
                    item={editItem}
                    onLoaderRaise={props.onLoaderRaise}
                />
            )}
        </div>
    );
};

export default QuoteRevise;
