import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from '../Dashboard/components/Home/Home'
import QuoteRequest from "../Dashboard/components/kendocomps/QuoteRequest/QuoteRequest";
import AuthUser from "../Dashboard/components/Grid/AuthUser/authUser";
import Profile from "../Dashboard/components/profile/profile";
import QuoteRevise from "../Dashboard/components/kendocomps/QuoteRevise/QuoteRevise";
import QuotePrint from "../Dashboard/components/kendocomps/QuotePrint/QuotePrint";
import SchoolGrade from "../Dashboard/components/kendocomps/SchoolGrade/schoolGrade";
import DeliveryWeekInfo from "../Dashboard/components/DeliveryWeek/DeliveryWeeksInfo";
import OrderFormProofgrid from "../Dashboard/components/OrderFormProof/OrderFormProofgrid";
import TallySheetMaingrid from "../Dashboard/components/TallySheetMain/TallySheetMaingrid";
import Noaccess from '../Login/components/Noaccess';
import ErrorLogs from "../Dashboard/components/ErrorModule/ErrorLogs";


/**
 * This component contains all Dashboard routes
 * 
 */

function DashboardRouting(props) {
    const [newheading, setNewheading] = useState("")
    const location = useLocation();
    useEffect(() => {
        const heading = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
        setNewheading(heading)

        if (window.location.pathname === "/dashboard" || window.location.pathname === "/externaldashboard") {
            $(".route-to-dashboard").addClass("hide-button");
        }
        else {
            $(".route-to-dashboard").removeClass("hide-button");
        }
    }, [location])
    return (
        <Routes>
            <Route exact path='/dashboard' element={<Home onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/users' element={<AuthUser heading={newheading} onLoaderRaise={props.onLoaderRaise} onAlert={props.onAlert} />} />
            <Route exact path='/dashboard/deliveryWeekInfo' element={<DeliveryWeekInfo heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/quote-queue' element={<QuoteRequest heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/quote-revise' element={<QuoteRevise heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/quote-print' element={<QuotePrint heading={newheading} onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/profile/*' element={<Profile onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/ErrorLogs' element={<ErrorLogs onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/school-grade-status' element={<SchoolGrade onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/OrderFormProofgrid' element={<OrderFormProofgrid onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path='/dashboard/TallySheetMaingrid' element={<TallySheetMaingrid onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/dashboard/noaccess" element={<Noaccess onLoaderRaise={props.onLoaderRaise} />} />
        </Routes>
    );
}
export default DashboardRouting;    