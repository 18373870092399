import * as React from "react";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import RefreshIcon from '@mui/icons-material/Refresh';
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";

/**
 * This component enables to update the submit flag for a tallysheet.
 *
 */

const TallySheetMaingrid = props => {
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "custnmbr",
            operator: "contains",
            value: '',
        }]
    };

    const [filter, setFilter] = React.useState(initialFilter);

    //#region "     On Load of Component    "
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        RefreshGrid();
    }, []);

    function RefreshGrid() {
        props.onLoaderRaise(true, "Loading Tally Sheet list ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.OrderTallySheetList).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setData(response);
        });
    };

    const handleCheckboxChange = (event, dataItem) => {
        let tempItem = { ...dataItem };
        tempItem.submit_flag = event.target.checked;
        props.onLoaderRaise(true, "Updating ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateTallySheet, tempItem).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("Submit flag updated successfully");
            RefreshGrid();
        });
    };

    const submitFlag = ({ dataItem }) => {
        return (
            <td colSpan="1" role="gridcell" aria-colindex="7" aria-selected="false" data-grid-col-index="6" style={{textAlign:"center"} }>
                <input type="checkbox" onChange={(event) => handleCheckboxChange(event, dataItem)} checked={dataItem.submit_flag} />
            </td>
        );
    };
    //#endregion

    //#region "      grid      "
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            pageable={true}
            take={1000}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <Column field="custnmbr" title="Customer Number" width="420" />
            <Column field="year" title="Year" width="300" />
            <Column field="submit_flag" title="Submit flag" width="320" cell={submitFlag} editable={true} />
        </Grid>
    );
    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">Tally Sheet Maint</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-8">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "custnmbr",
                                label: "Cust #",
                                filter: TextFilter,
                                operators: Operators.text,
                            }, {
                                name: "year",
                                label: "Year",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-4" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
        </div>
    );
};

export default TallySheetMaingrid;