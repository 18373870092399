import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Outlet } from "react-router-dom";
import $ from "jquery";
import CommonVariables from "./variables";
import APIConnector from "./apiconnector";
import "@progress/kendo-theme-default/dist/all.css";
import Loginrouting from "./Routing/LoginRouting";
import DashboardRouting from "./Routing/DashboardRouting";
import Layout from "./Layout/Layout";
import ExternalDashboardRouting from "./Routing/ExternalDashboardRouting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorEventHandler } from "./errorhandler";
import "./App.css";
import LoadingSpinner from "./Layout/Commonloader";
import { useCallback } from 'react';
import Notifications from "./Notifications/Messages.js"
import AllRoutes from "./Routing/AllRoutes";
import UserActivity from "./UserActivity";
import CommonAlert from "./Layout/CommonAlert";


/**
 * This is the main component
 * 
 */
function App() {
    const [isLoading, setIsLoading] = useState(false);
    const [LoaderMessage, setLoaderMessage] = useState("");
    const [LoaderStyles, setLoaderStyles] = useState({});

    const [isAlert, setisAlert] = useState(false)
    const [alertDataObj, setalertDataObj] = useState({});

    const onLoaderRaise = useCallback((flag, msg, LoaderStyles) => {
        if (flag) {
            setIsLoading(flag);
            if (!msg)
                msg = "Loading..."
            if (!LoaderStyles)
                LoaderStyles = { marginTop: "0px" };
            setLoaderStyles(LoaderStyles);
            setLoaderMessage(msg);
        }
        else {
            setIsLoading(flag);
        }
    }, [isLoading]);

    const onAlert = useCallback((flag,dataObj) => {
        if (flag) {
            if (!dataObj) {
                setisAlert(false);
                return;
            }
            setisAlert(flag);
            setalertDataObj(dataObj);
        }
        else {
            setalertDataObj({});
            setisAlert(flag);
        }
    }, [isAlert]);
    //window.addEventListener('error', function (event) {
    //    setIsLoading(false);
    //    errorEventHandler(event);
    //});

    const navigation = useNavigate();
    useEffect(function () {

        window.addEventListener('storage', function (event) {
            if (localStorage.length == 0) {
                navigation("/")
            }
        });

        let locationpathname = window.location.pathname;
        //Verify and Navigate to internal OR external site
        let isLocalStorageAvailable = CommonVariables.verifyLocalstorageDataAvailable()
        if (!isLocalStorageAvailable) {
            if (locationpathname !== "/")
                Notifications.ShowWarningMessage("Session expired.Please login");
            navigation("/")
            return;
        }

        onLoaderRaise(true, "Verifying session please wait ...", { marginTop: "145px" });
        let StorageData = CommonVariables.getlocalstorageData();

        let TokeninputModel = {
            Tokens: {
                AccessToken: StorageData.accessToken,
                RefreshToken: StorageData.refreshToken
            }
        };

        APIConnector.Post(CommonVariables.urls.VerifyAndRefreshTokenAsync, TokeninputModel).then(function (response) {
            onLoaderRaise(false);
            if (!response || (response.status && response.status != 200)) {
                Notifications.ShowErrorMessage("Session expired.Please login");
                navigation("/")
                return;
            }
            if (!response.isSuccess || !response.tokenModel.tokens || !response.tokenModel.tokens.accessToken) {
                Notifications.ShowErrorMessage("Session expired.Please login");
                navigation("/")
                return;
            }
            if (!VerifyPermissionForCurrentRoute(response.tokenModel.role)) {
                navigation(response.tokenModel.isExternal == true ? '/externaldashboard/noaccess' : '/dashboard/noaccess');
                return;
            }

            CommonVariables.setFactoryData({ "RoleName": response.tokenModel.role });
            CommonVariables.setlocalStorageData({ tokens: response.tokenModel.tokens, userId: response.tokenModel.userId, RoleName: response.tokenModel.role });

            if (response.tokenModel.isExternal) {
                if (response.tokenModel.isContractAccepted && response.tokenModel.isAdminApproved) {
                    if (response.userActivity && response.userActivity.stage > 0 && !response.userActivity.orderFormWizardHide) {//on hiding order form wizard doesn't force
                        var navigateRoute = UserActivity.SwitchUserNavigationBasedOnActivity(response.userActivity);
                        navigation(navigateRoute);
                        return;
                    }
                    navigation((locationpathname.indexOf('/externaldashboard') >= 0 ? locationpathname : '/externaldashboard'));
                }
                else if (response.tokenModel.isContractAccepted && !response.tokenModel.isAdminApproved) {
                    Notifications.ShowWarningMessage("Approval awaited by the Administrator");
                    navigation('/');
                }
                else {
                    navigation('/terms');
                }
            }
            else {
                navigation((locationpathname.indexOf('/dashboard') >= 0 ? locationpathname : '/dashboard'));
            }
        });
    }, []);


    const VerifyPermissionForCurrentRoute = (RoleName) => {
        var FilteredRoutes = AllRoutes.filter(function (EachRoute) {
            return EachRoute.path === window.location.pathname && EachRoute.Roles.includes(RoleName.toUpperCase());
        });
        if (FilteredRoutes && FilteredRoutes.length > 0)
            return true;
        else
            return false;
    };
    return (
        <div>
            <div>
                <ToastContainer style={{ zIndex: "1000005" }} autoClose={5000} />
            </div>
            <div style={{ marginTop: "40px" }} className="bg-image"></div>
            {isLoading && <LoadingSpinner message={LoaderMessage} LoaderStyles={LoaderStyles} />}
            {isAlert && <CommonAlert alertDataObj={alertDataObj} />}
            <Layout onLoaderRaise={onLoaderRaise} onAlert={onAlert}>
                <ExternalDashboardRouting onLoaderRaise={onLoaderRaise} onAlert={ onAlert} />
                <DashboardRouting onLoaderRaise={onLoaderRaise} onAlert={onAlert} />
                <Loginrouting onLoaderRaise={onLoaderRaise} />
            </Layout>
        </div>
    );
}

export default App;