import * as React from 'react';

const CompletePage = (props) => {
    return (<div style={{ "textAlign": "center" }} className='mt-5'>
        <div className="container-full">
            <label className="wizardStepHeadLabel">Please click the Submit button to complete the Tally Sheet.</label>
        </div>
    </div>
    );
};

export default CompletePage;