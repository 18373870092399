import React from 'react';
import Navbar from '../Login/components/NavbarLogin/navbar';
import { Link } from 'react-router-dom';
import '../Layout/Layout.css';

function Loginlayout(props) {
    return (
        <div>
            <Navbar />
                {props.RouterInfo.children}
            <div className='login-footer w-full flex flex-col'>
            <hr></hr>
                <div className=''>
                    <p className='text-center text-slate-500'>© SchoolKidz 2022 | <a href="https://schoolkidz.com/privacy-policy" target="_blank" style={{color:"#003087"}}>Privacy Policy</a> </p>
                </div>
            </div>
        </div>
    );
}

export default Loginlayout;