import React from "react";
import $ from "jquery";
import urls from "../../../variables";
import APIConnector from "../../../apiconnector";
import UserActivity from "../../../UserActivity";
import { useNavigate } from "react-router-dom";
import CommonVariables from '../../../variables';
import "./login.css";
import Notifications from "../../../Notifications/Messages.js"
import { Link } from 'react-router-dom';





/**
 * This component renders the login page and validates the user
 * 
 */
function Login(props) {
    const navigation = useNavigate();

    const RetryLogin = (data) => {
        props.onLoaderRaise(true, "Verifying login ...", { marginTop: "155px" });
        APIConnector.Post(urls.urls.loginUrl, data).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess || !response.tokenModel.tokens || !response.tokenModel.tokens.accessToken) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            CommonVariables.setlocalStorageData({ tokens: response.tokenModel.tokens, userId: response.tokenModel.userId, email: data.email, RoleName: response.tokenModel.role });
            if (response.tokenModel.isExternal) {
                if (response.tokenModel.isContractAccepted && response.tokenModel.isAdminApproved) {
                    if (response.userActivity && response.userActivity.stage > 0 && !response.userActivity.orderFormWizardHide) {
                        var navigateRoute = UserActivity.SwitchUserNavigationBasedOnActivity(response.userActivity);
                        navigation(navigateRoute);
                        return;
                    }
                    navigation('/externaldashboard');
                }
                else if (response.tokenModel.isContractAccepted && !response.tokenModel.isAdminApproved) {
                    Notifications.ShowWarningMessage("Approval awaited by the Administrator");
                }
                else {
                    navigation('/terms');
                }
            }
            else {
                navigation('/dashboard');
            }

        });
    };

    const VerifyAndLogin = (event) => {
        event.preventDefault();
        var email = $("#username").val();
        const data = { email: email, password: $("#Password").val() };
        props.onLoaderRaise(true, "Verifying login ...", { marginTop: "155px" });
        APIConnector.Post(urls.urls.loginUrl, data).then(function (response) {
            props.onLoaderRaise(false);
            if (response && response.isSuccess === false) {
                RetryLogin(data);
                return;
               } 
            if (!response.isSuccess || !response.tokenModel.tokens || !response.tokenModel.tokens.accessToken) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            CommonVariables.setlocalStorageData({ tokens: response.tokenModel.tokens, userId: response.tokenModel.userId, email: email, RoleName: response.tokenModel.role });
            if (response.tokenModel.isExternal) {
                if (response.tokenModel.isContractAccepted && response.tokenModel.isAdminApproved) {
                    if (response.userActivity && response.userActivity.stage > 0 && !response.userActivity.orderFormWizardHide) {
                        var navigateRoute = UserActivity.SwitchUserNavigationBasedOnActivity(response.userActivity);
                        navigation(navigateRoute);
                        return;
                    }
                    navigation('/externaldashboard');
                }
                else if (response.tokenModel.isContractAccepted && !response.tokenModel.isAdminApproved) {
                    Notifications.ShowWarningMessage("Approval awaited by the Administrator");
                }
                else {
                    navigation('/terms');
                }
            }
            else {
                navigation('/dashboard');
            }

        });
    };
    return (
        <div>
            <div className="login-body">
                <p className="login-p mt-2">Please sign into the SchoolKidz Portal</p>
                <form onSubmit={VerifyAndLogin} className="container login-form p-4 px-5 mx-auto mt-5  rounded border-round-dark">
                    <h4 className="text-center mt-0">SchoolKidz</h4>
                    <div className="mb-2 mt-3">
                        <label className="login-label">Username</label>
                        <input
                            type="text"
                            autoFocus={true}
                            autoComplete="username"
                            className="pl-1 pt-1 pb-1 rounded-0 border-input prompt"
                            id="username"
                            placeholder="Enter your username"
                            required
                        />
                    </div>
                    <div className="mb-0 mt-3">
                        <label className="login-label">Password</label>
                        <input
                            type="password"
                            autoComplete="current-password"
                            className="pl-1 pt-1 pb-1  rounded-0 border-input prompt"
                            id="Password"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <button
                        type="submit" className="button-save px-3 mt-4 mb-3 hover:bg-sky-700 rounded-0">
                        Login
                    </button>
                    <div className="btns mt-4 float-sm-right">
                        <Link to="/forgotpassword" className="text-decoration-underline mx-0 border-dark" style={{color:"#003087"}}>Forgot password</Link>
                    </div>
                </form>
               
            </div>
        </div>
    );
}
export default Login;