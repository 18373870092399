import React, { useEffect, useState } from 'react';
import APIConnector from '../../../apiconnector'
import "./Navbar.css";
import $, { data } from 'jquery';
import SKLogo from '../../../images/SKLogo.png';
//import SKSLogo from '../../../images/SKSLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import CommonVariables from '../../../variables';
import Notifications from '../../../Notifications/Messages';
import userImage from '../../../images/user.png';
import Back from '../../../Dashboard/components/Images/Back.png';

function Navbar(props) {
    const link = window.location.pathname;
    const linkTo = link.slice(0, link.lastIndexOf('/'));
    $(".askchoice").addClass("hidden");
    const navigation = useNavigate();
    const [userData, setUserData] = useState({});

    useEffect(function () {
        let input = {
            userId: localStorage.getItem("userId"),
        };
        //props.RouterInfo.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
           // props.RouterInfo.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            if (resp.userName) {
                setUserData(resp);
            }
        });
    }, []);

    const [profileView, setProfileView] = useState(false);
    function logout() {
        CommonVariables.logout();
    }
    function buttonHandler() {
        setProfileView(!profileView);
    }
    var constraints = {
        video: true,
    };
    function stopBothVideoAndAudio() {
        var video = document.querySelector("video");
        $(".askchoice").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: false })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    /***************************************/
    function capture() {
        $("#player").addClass("hidden")
        $("#retake").removeClass("hidden")
        $(".showCanvasAndBtn").removeClass("hidden")
        const player = document.getElementById('player');
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            // video.srcObject = stream;
            player.srcObject = stream;
            // video.srcObject = stream;
        });
        // Draw the video frame to the canvas.
        context.drawImage(player, 0, 0, canvas.width, canvas.height);

        const imageurl = canvas.toDataURL();
        var UpdateProfile = {
            userId: CommonVariables.getlocalstorageData().userId,
            image: imageurl,
        };
        document.querySelector(".uploadBtn").addEventListener("click", () => {
            var userdata = {
                userId: localStorage.getItem("userId"),
            };
            props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
            APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
                props.RouterInfo.onLoaderRaise(false);
                if (!CommonVariables.isError(resp))
                    return;
                if (resp.userName) {
                    setUserData(resp);
                }
                var payload = userData;
                payload.image = UpdateProfile.image;
                props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                APIConnector.Post(CommonVariables.urls.UpdateProfileImage, payload).then(function (response) {
                    props.RouterInfo.onLoaderRaise(false);
                    if (!CommonVariables.isError(response))
                        return;
                    if (response.isSuccess) {
                        stopBothVideoAndAudio();
                        Notifications.ShowSuccessMessage("Image uploaded Successfully")
                        Notifications.ShowSuccessMessage("Please Login again to effect the changes");
                        window.location.reload(true);
                    }
                    else {
                        Notifications.ShowErrorMessage("Some error occured, Try again")
                    }
                })
            });
            setProfileView(false);
        });
    };
    function takePicture() {
        var video = document.querySelector("video");
        $(".askchoice").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    };
    function retake() {
        $("#player").removeClass("hidden")
        $("#canvas").addClass("hidden")
    };


    function uploadPhoto() {
        document.querySelector(".uploaded-image").onchange()
    };


    /*****************************************/
    return (
        <div className=''>
            <div className=''>
                <div className="navbar fixed mr-0 row navigation-bar pt-1">
                    <div className="col-12 col-sm-6  col-md-6 col-lg-2 flex">
                        <img onClick={() => navigation('/externaldashboard')} className="mx-auto bg-white p-1 my-0 hover:cursor-pointer" src={SKLogo} alt="SchoolKidz and School Specialty Logo" />
                        {/* <img onClick={() => navigation('/dashboard')} className="mx-auto bg-white p-1 my-0 hover:cursor-pointer" src={SKSLogo} /> */}
                    </div>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-4 mt-3 d-flex justify--center justify-content-md-end">
                        <div className="flex">
                            <div className="profile-div py-0 inline-block">
                                <button className="profile-button" onClick={buttonHandler}>
                                    <img
                                        className="inline-block profile-img rounded-circle"
                                        src={userData.image != null ? userData.image : userImage}
                                        alt="Account"
                                        style={{
                                            width: ((userData.image === null) ? "80px !important" : "10rem !important"),
                                            height: ((userData.image === null) ? "80px !important" : "10rem !important")
                                        }}
                                    />
                                    &ensp;
                                    {(userData &&
                                        <div className="inline align-middle">{userData.firstName + " " + userData.lastName}</div>)}
                                </button>
                                &emsp;
                            </div>
                        </div>
                    </div>
                </div>
                {profileView && (
                    <div className='d-flex justify-content-center justify-content-sm-end text-center font-weight-bold text-light mr-3'>
                        <div className="profile-view shadow-lg justify-content-center position-absolute bg-white px-3 pb-3">
                            <div className='askchoice absolute hidden bg-gray-100 w-60 h-72 pt-12'>
                                <input className='border-2 border-gray-400 text-black text-sm w-full uploaded-image' accept="image/png, .jpeg, .jpg" type="file" onChange={(e) => {
                                    if (e.target.files && e.target.files.length) {
                                        (getBase64(e.target.files[0])).then(function (data) {
                                            var userdata = {
                                                userId: localStorage.getItem("userId"),
                                            };
                                            props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                                            APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
                                                props.RouterInfo.onLoaderRaise(false);
                                                if (!CommonVariables.isError(resp))
                                                    return;
                                                if (resp.userName) {
                                                    setUserData(resp);
                                                }
                                                var payload = userData;
                                                payload.image = data;
                                                props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                                                APIConnector.Post(CommonVariables.urls.UpdateProfile, payload).then(function (response) {
                                                    props.RouterInfo.onLoaderRaise(false);
                                                    if (!CommonVariables.isError(response))
                                                        return;
                                                    if (response.isSuccess) {
                                                        Notifications.ShowSuccessMessage("Image uploaded Successfully")
                                                        Notifications.ShowSuccessMessage("Please Login again to effect the changes");
                                                        window.location.reload(true);
                                                    }
                                                    else {
                                                        Notifications.ShowErrorMessage("Some error occured, Try again")
                                                    }
                                                })
                                            });
                                        });
                                    }
                                }} /><br />
                                <button className='border-2 text-sm w-full take-picture border-gray-400 text-black' onClick={takePicture}>take picture</button><br /> <br />
                                <button className='button-save mr-2' onClick={uploadPhoto}>Upload</button>
                                <button className='button-cancel' onClick={() => { $(".askchoice").addClass("hidden") }}>Cancel</button>
                            </div>
                            <div className='video absolute hidden text-black z-10' style={{}}>
                                <video id="player" autoPlay='true'></video>

                                <canvas id="canvas" width="" height="225vh" className='w-full showCanvasAndBtn hidden'></canvas>
                                <button id="retake" className='bg-green-400 cam-btns p-2 m-1 hidden' onClick={retake} >Retake</button>
                                <button id="capture" className='bg-green-400 cam-btns px-2 py-1 m-1' onClick={capture} ><span className="p-0 material-symbols-outlined">
                                    photo_camera
                                </span></button>
                                <button className='bg-blue-400 p-2 cam-btns hidden showCanvasAndBtn uploadBtn' onClick={() => {
                                    var video = document.querySelector("video");
                                    debugger;
                                    if (true) {
                                        navigator.mediaDevices.getUserMedia({ video: false })
                                            .then(mediaStream => {
                                                const stream = mediaStream;
                                                const tracks = stream.getTracks()
                                                tracks[0].stop();
                                            })
                                    }
                                    setProfileView(false);
                                }}>Upload</button>
                            </div>


                            <img className="profile-img-big p-2 mx-auto rounded-circle"
                                src={userData.image != null ? userData.image : userImage} alt="Account"
                                style={{
                                    width: ((userData.image === null) ? "80px !important" : "10rem !important"),
                                    height: ((userData.image === null) ? "80px !important" : "10rem !important")
                                }} />
                            <div className=''>
                                <p className='paragraph font-bold'>{userData.firstName + " " + userData.lastName}</p>
                                <div className='flex'>
                                    <Link to="externaldashboard/profile" onClick={buttonHandler} className='button-edit text-white'>Profile</Link>
                                    &emsp;
                                    <button className='button-delete hover-effect' onClick={logout}>Sign out</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="m-2 route-to-dashboard">
                <Link to={linkTo} className='justify-evenly'>
                    <button className="flex BackToDashboard">
                        <img src={Back} alt="back" />
                        <span className="ml-2">Dashboard</span>
                    </button>
                </Link>
            </div>
        </div>
    );
}
export default Navbar;