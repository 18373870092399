import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import APIConnector from '../../../apiconnector.js';
import CommonVariables from '../../../variables.js';
import $ from "jquery";
import Notifications from "../../../Notifications/Messages";
import { useNavigate } from 'react-router';

function Userdetails(props) {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    useEffect(function () {
        setUserData(props.userData);
        $(".askchoice-profile").addClass("hidden") 
    }, []);


    function updateUserdetails() {
        if (!userData.email) {
            Notifications.ShowWarningMessage("Please enter email");
            $("#email").focus();
            return;
        }

        props.onLoaderRaise(true, "saving", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateProfile, userData).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage("User details updated Successfully");
            setTimeout(function () {
                window.location.href = window.location.origin + "/dashboard/profile";
            }, 200);
            
        });
    };

    return (
        <div>
            <div className='grid align-self-end sm:grid-cols-2 gap-y-1 lg:gap-y-2 xl:gap-y-4 lg:w-9/12 xl:w-7/12 gap-y-4 md:w-10/12'>
                <label className="label">First Name</label>
                <input type="text" id="firstName" className=" input-text" autoFocus placeholder="Enter your First name" value={userData.firstName || ''} onChange={(e) => {
                    let tempData = { ...userData };
                    tempData.firstName = e.target.value;
                    setUserData(tempData);
                }} />
                <label className="label">Last Name</label>
                <input type="text" id="lastName" className=" input-text" placeholder="Enter your Last name" value={userData.lastName || ''} onChange={(e) => {
                    let tempData = { ...userData };
                    tempData.lastName = e.target.value;
                    setUserData(tempData);
                }} />
                <label className="label">Email Address</label>
                <input type="text" id="email" className="input-text" placeholder="Enter your email" value={userData.email || ''} disabled />
                <label className="label">Phone no.</label>
                <input type="text" id="phone" className="input-text" placeholder="Enter your phone number" value={userData.phone || ''} onChange={(e) => {
                    let tempData = { ...userData };
                    tempData.phone = e.target.value;
                    setUserData(tempData);
                }} />
            </div>
            <button className='button-save mt-3' onClick={updateUserdetails}>Update</button>
            <Link to="/dashboard/profile" className='button-cancel mt-3 ml-3 p-2 px-3'>Cancel</Link>
        </div>
    );
}

export default Userdetails;