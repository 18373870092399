// import messages from "./messageFunctions";
import CommonVariables from "./variables";




const APIConnector = {
    Get: async function (url) {
        let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
        if (url != CommonVariables.urls.loginUrl && url != CommonVariables.urls.ForgotPasword) {
            if (!Accesstoken) {
                window.location.href = window.location.origin;
            }
        }
        var configData = await CommonVariables.getConfigData();
        var response = await fetch(configData.ApiUrl + url, {
            method: "GET",
            headers: {
                credentials: "include",
                "Content-Type": "application/json; charset=utf-8 ",
                Accept: "*/*",
                "API-KEY": "secret",
                Authorization: Accesstoken,
            }
        });
        response = await response.json();
        return response;
    },

    //1. Token
    //2. UserID
    //3.Base Domain
    Post: async function (url, data) {

        try {
            data = data || {};

            if (!data.userId)
                data.userId = CommonVariables.getlocalstorageData().userId;

            let Accesstoken = (url === CommonVariables.urls.loginUrl || url === CommonVariables.urls.ForgotPasword) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
            if (url != CommonVariables.urls.loginUrl && url != CommonVariables.urls.ForgotPasword) {
                if (!Accesstoken) {
                    window.location.href = window.location.origin;
                }
            }
            var configData = await CommonVariables.getConfigData();
            var response = await fetch(configData.ApiUrl + url, {
                method: "POST",
                headers: {
                    credentials: "include",
                    "Content-Type": "application/json; charset=utf-8 ",
                    Accept: "*/*",
                    "API-KEY": "secret",
                    Authorization: Accesstoken,
                },
                body: JSON.stringify(data),

            });
            response = await response.json();

        }

        catch (error) {
            if (response)
                return response;
            return { isSuccess: false, message: "Failed to fetch data from API" }
        }

        return response;
    },
    Update: async function (url, data) {
        let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
        if (url != CommonVariables.urls.loginUrl && url != CommonVariables.urls.ForgotPasword) {
            if (!Accesstoken) {
                window.location.href = window.location.origin;
            }
        }
        var configData = await CommonVariables.getConfigData();
        var response = await fetch(configData.ApiUrl + url, {
            method: "PUT",
            headers: {
                credentials: "include",
                "Content-Type": "application/json; charset=utf-8 ",
                Accept: "*/*",
                "API-KEY": "secret",
                Authorization: Accesstoken,
            },
            body: JSON.stringify(data),
        });
        response = await response.json();
        return response;
    },
    Delete: async function (url) {
        var configData = await CommonVariables.getConfigData();
        var response = await fetch(configData.ApiUrl + url, { method: "DELETE" });
        response = await response.json();
        return response;
    },

    //1. Token
    //2. UserID
    //3.Base Domain
    FileUpload: async function (url, data) {
        try {
            let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
            if (url != CommonVariables.urls.loginUrl && url != CommonVariables.urls.ForgotPasword) {
                if (!Accesstoken) {
                    window.location.href = window.location.origin;
                }
            }
            var configData = await CommonVariables.getConfigData();
            var response = await fetch(configData.ApiUrl + url, {
                method: "POST",
                headers: {
                    credentials: "include",
                    contentType: false,
                    Accept: "*/*",
                    processData: false,
                    "API-KEY": "secret",
                    Authorization: Accesstoken,
                },
                body: data,

            });
            response = await response.json();

        }

        catch (error) {
        }

        return response;
    }
};
export default APIConnector;
