
import { triggerBase64Download } from 'common-base64-downloader-react';

export const formatDate = (date) => {
    if (date == "0001-01-01T00:00:00")
        return "";
    let d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    let result = [month, day, year].join('/');
    return result + " " + (d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours()) + ":" + (d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes());

};

export const formatDateOnly = (date) => {
    if (!date || date == "0001-01-01T00:00:00")
        return null;
    let d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [month, day, year].join('/');

};


//#region "     PDF Downlaod Files      "
export const PdfDownload = (name, file) => {
    var fileextension = name.substr(name.lastIndexOf("."));
    if (fileextension === ".jpg" || fileextension === ".png") {
        file = "data:image/png;base64," + file;
    }
    else if (fileextension === ".jpeg") {
        file = "data:image/jpeg;base64," + file;
    }
    else if (fileextension === ".xlsx") {
        file = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + file;
    }
    else if (fileextension === ".pdf") {
        file = "data:application/pdf;base64, " + file;
    }
    else if (fileextension === ".docx") {
        file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
    }
    else if (fileextension === ".doc") {
        file = "data:application / msword;base64, " + file;
    }
    else {
        file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
    }
    triggerBase64Download(file, name)
};

export const dynamicSpinner = (props) => {
    return (
        <div>
            <div style={{
                zIndex: "1",
                position: "absolute",
                marginTop: props.marginTop,//To Make it center align
                marginLeft: props.marginLeft
            }}>
                <label>Loading...</label>
            </div>
            <div style={{
                border: "10px solid #f3f3f3",
                borderTop: "10px solid #3498db",
                borderRadius: "50%",
                width: props.width,
                height: props.height,
                animation: "spinner 1s linear infinite"
            }}>
            </div>
        </div>
    );
};



