import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import DownloadIcon from '@mui/icons-material/Download';
import APIConnector from "../../../../apiconnector"
import CommonVariables from "../../../../variables";
import { triggerBase64Download } from 'common-base64-downloader-react';
import Notifications from "../../../../Notifications/Messages";
import { formatDateOnly, dynamicSpinner } from "../../commonfunctions";
import { Dropdown } from "react-bootstrap";


const EditQuoteRequest = (props) => {

    //#region " Initialization of State  Values       "
    const [files, setFiles] = React.useState([]);
    const [exceptionapprovallist, setexceptionapprovallist] = React.useState([]);
    const [loadingQAfiles, setloadingQAfiles] = React.useState(true);

    const Statusdropdown = [
        { text: "Open/Unassigned", statusValue: "206340000" },
        { text: "In Progress/Assigned", statusValue: "206340001" },
        { text: "Completed", statusValue: "206340002" },
        // { text: "Incomplete", statusValue: "206340003" }
    ];
    const [dialogDimension, setDialogDimension] = React.useState([]);
    let statusValFromMainDialog = Statusdropdown.filter(function (each) { return each.statusValue == props.obj.statusId })[0];
    if (props.obj.statusId == "206340000")
        statusValFromMainDialog = Statusdropdown[1];
    const [rowitem, setRowItem] = React.useState({
        accountName: props.obj.accountName,
        accountNumber: props.obj.accountNumber,
        createdby: props.obj.createdby,
        requestDate: formatDateOnly(props.obj.requestDate),
        requestType: props.obj.requestType,
        extAssignedIdName: props.obj.extAssignedIdName ? props.obj.extAssignedIdName : "",
        statusValue: statusValFromMainDialog,
        needbydate: formatDateOnly(props.obj.needbydate),
        specialNotes: props.obj.specialNotes ? props.obj.specialNotes : "",
        responseNotes: props.obj.responseNotes ? props.obj.responseNotes : ""
    });

    //#endregion


    //#region "     On Load     "
    React.useEffect(() => {
        APIConnector.Post(CommonVariables.urls.GetQuoteQueueAttachmentListById, { id: props.obj.id }).then(function (response) {
            if (!CommonVariables.isError(response))
                return;

            if (response && response.length > 0)
                setFiles(response);
            setloadingQAfiles(false);
        });

        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetQuoteQueueExceptionApprovalListById, { id: props.obj.id }).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (response && response.length > 0)
                setexceptionapprovallist(response);
        });

        var neededheight = window.innerHeight - (window.innerHeight / 5)
        var neededwidth = window.innerWidth - (window.innerWidth / 10)

        setDialogDimension([neededheight, neededwidth]);
    }, []);
    //#endregion

    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion



    return (
        <Dialog className="w-screen" title={`Edit Quote`} onClose={props.cancelEdit} height={dialogDimension[0]} width={dialogDimension[1]} >
            {
                <div>
                    <Form className="rounded-t-2xl w-screen flex" initialValues={rowitem} onSubmit={props.onSubmit}
                        render={(formRenderProps) => (
                            <FormElement className="quote-modal rounded-t-2xl font-normal w-full">
                                <fieldset className="k-form-fieldset grid md:grid-cols-4 gap-2">
                                    <div >
                                        <label className="fieldlabel">School name</label>
                                        <Field name={"accountName"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Account No.</label>
                                        <Field name={"accountNumber"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Quote request by</label>
                                        <Field name={"createdby"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Request date</label>
                                        <Field name={"requestDate"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Request type</label>
                                        <Field name={"requestType"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Request assignee</label>
                                        <Field name={"extAssignedIdName"} component={Input} disabled={true} />
                                    </div>
                                    <div >
                                        <label className="fieldlabel">Request status</label>
                                        <Field name={"statusValue"} component={DropDownList}
                                            textField="text" dataItemKey="statusValue" data={Statusdropdown} />
                                    </div>
                                    <div>
                                        <label className="fieldlabel">Need by date</label>
                                        <Field name={"needbydate"} component={Input} disabled={true} />
                                    </div>
                                </fieldset>
                                <fieldset className="k-form-fieldset grid grid-cols-2 gap-6">
                                    <div className="mb-3">
                                        <Label className="fieldlabel">Request Notes</Label>
                                        <Field name={"specialNotes"} component={TextArea} disabled={true} autoSize/>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="fieldlabel">Response Notes</Label>
                                        <Field name={"responseNotes"} component={TextArea} />
                                    </div>
                                </fieldset>
                                <Label className="fieldlabel" >Attachments</Label>
                                <div className="sok-tile-row" style={{ border: "2px solid", borderColor: "#b4b1b1", height: "auto" }}>
                                    {loadingQAfiles && dynamicSpinner({ width: "80px", height: "80px", marginTop: "31px", marginLeft: "12px" })}
                                    {!loadingQAfiles && files.length <= 0 && <div style={{ textAlign: "center" }}><label>No Attachments avaialble</label></div>}
                                    {!loadingQAfiles && files.length > 0 && files.map((EachFile, i) => (
                                        <div className="sok-tile" key={i}>
                                            <DownloadIcon />
                                            <div className="sok-tile-name" onClick={() => { PdfDownload(EachFile.fileNmae, EachFile.documentBody) }}>
                                                {EachFile.fileNmae}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Label className="fieldlabel" >Exception Approval Requests</Label>
                                <Grid className=" rounded-2xl border-b-4"
                                    resizable={true}
                                    sortable={true}
                                    pageable={true}
                                    data={exceptionapprovallist}
                                    style={{ height: (window.innerHeight - 400) + "px" }}
                                >
                                    <GridColumn field="Requestor" title="Requestor" width="200" />
                                    <GridColumn field="RequestDate" title="Request Date" width="140" />
                                    <GridColumn field="Status" title="Status" width="180" />
                                    <GridColumn field="Manager" title="Manager" width="200" />
                                    <GridColumn field="Approve/DenyDate" title="Approve/Deny Date" width="170" />
                                    <GridColumn field="RequestNotes" title="Request Notes" />
                                    <GridColumn field="ResponseNotes" title="Response Notes" />
                                </Grid>
                                <div className="k-form-buttons">
                                    <button type="submit" className="button-save" onClick={formRenderProps.onSubmit} disabled={!formRenderProps.allowSubmit}>
                                        Update
                                    </button>
                                    <button className="button-cancel" onClick={() => props.cancelEdit()}>
                                        Cancel
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            }
        </Dialog>
    );
};

export default EditQuoteRequest;
