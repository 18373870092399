import React from 'react';
import $ from "jquery"
import APIConnector from '../../../apiconnector';
import CommonVariables from '../../../variables';
import { useNavigate } from 'react-router';
import Notifications from '../../../Notifications/Messages';
import { Link } from 'react-router-dom';

function Changepassword(props) {
    const navigate = useNavigate();
    React.useEffect(()=>{
        $(".askchoice-profile").addClass("hidden") 
    })
    const passwordRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/);
    function updatepassword() {
        const currentPassword = $(".currentPassword").val();
        const newPassword = $(".newPassword").val();
        const confirmPassword = $(".confirmPassword").val();
        if (!currentPassword) {
            Notifications.ShowWarningMessage("Please enter current password");
            $(".currentPassword").focus();
            return;
        }
        if (!newPassword) {
            Notifications.ShowWarningMessage("Please enter new password");
            $(".newPassword").focus();
            return;
        }
        if (!confirmPassword) {
            Notifications.ShowWarningMessage("Please enter confirm password");
            $(".confirmPassword").focus();
            return;
        }

        if (!passwordRegex.test(newPassword)) {
            Notifications.ShowWarningMessage("new password must be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character");
            $(".newPassword").focus();
            return;
        }
        if (newPassword != confirmPassword) {
            Notifications.ShowWarningMessage("new password and confirm password are not equal");
            $(".confirmPassword").focus();
            return;
        }
        const data = { currentPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword }
        props.onLoaderRaise(true, "saving", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.changepassword, data).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response || !response.isSuccess || response.errors) {
                Notifications.ShowErrorMessage((response && response.message) ? response.message : "Failed to update");
                return;
            }
            Notifications.ShowSuccessMessage("Your Password changed Successfully");
            setTimeout(function () {
                window.location.href = window.location.origin + "/externaldashboard/profile";
            }, 200);
        });
    };


    return (
        <div>
            <div className='grid align-self-end sm:grid-cols-2 gap-y-1 lg:gap-y-3 xl:gap-y-4 lg:w-9/12 xl:w-7/12 gap-y-4 md:w-12/12'>
                <label className="block text-gray-500 label" for="inline-password">
                    Current Password
                </label>
                <input autoFocus className="currentPassword bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-300" type="password" placeholder="******************" required />
                <label className="block text-gray-500 label" for="inline-password">
                    New Password
                </label>
                <input className="newPassword bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-300" type="password" placeholder="******************" required />
                <label className="block text-gray-500 label" for="inline-password">
                    Confirm New Password
                </label>
                <input className="confirmPassword bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-300" type="password" placeholder="******************" required />
            </div>
            <button type="submit" className="button-save hover-effect shadow mt-4 mb-2 self-start" onClick={updatepassword}>
                Update password
            </button>
            <Link to="/externaldashboard/profile" className='button-cancel mt-3 sm:ml-3 p-2 px-3 '>Cancel</Link>
        </div>
    );
};
export default Changepassword;
