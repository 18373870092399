import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link, useLocation } from "react-router-dom";
import APIConnector from "../../../apiconnector";
import CommonVariables from "../../../variables";
import AllRoutes from "../../../Routing/AllRoutes";
import { useNavigate } from 'react-router';
import Q_Queue from '../Images/Q_Queue.png';
import Q_Revision from '../Images/Q_Revision.png';
import Q_Print from '../Images/Q_Print.png';
import SchoolSetup from '../Images/IconSchoolSetup.png';
import Users from '../Images/Users.png';
import DeliveryWeeks from '../Images/DeliveryWeeks.png';
import OrderProof from '../Images/OrderProof.png';
import TallyMaintenance from '../Images/TallyMaintenance.png';
import errorLog from '../Images/errorLog.png';

/**
 * 
 * This component renders the home page of internal dashboard 
 * 
 */

function Home(props) {
    const [quoteRequestCount, setQuoteRequestCount] = useState()
    const [quotePrintCount, setQuotePrintCount] = useState()
    const [quoteReviseCount, setQuoteReviseCount] = useState()
    const [schoolSetupCount, setSchoolSetupCount] = useState()
    const [deliveryWeekCount, setDeliveryWeekCount] = useState()
    const [orderFromProofCount, setOrderFromProofCount] = useState()
    const [tallySheetCount, setTallySheetCount] = useState()
    const [flag, setFlag] = useState(false);
    const navigate = useNavigate();
    const RoleName = CommonVariables.getlocalstorageData().RoleName;
    const location = useLocation();
    const [isActive, setIsActive] = useState(true);
    const [isPaused, setIsPaused] = useState(false);
    const [time, setTime] = useState(0);
    const loadingPanel = (
        <>
            <div className="k-loading-mask" style={{ color: "black", fontSize: "8px", width: "30%", height: "50%" }}>
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
            </div>
        </>
    );
    useEffect(() => {
        if (!RoleName || RoleName.length <= 0) {
            navigate("/");
            return;
        }

        APIConnector.Post(CommonVariables.urls.GetDashboardCounts, {}).then(function (response) {
            if (!CommonVariables.isError(response))
                return;
            setFlag(true)
            setIsActive(false)
            setIsPaused(true)
            setQuoteRequestCount(response.quoteQueueCount)
            setQuotePrintCount(response.quotePrintCount)
            setQuoteReviseCount(response.quoteRevisionCount)
            setSchoolSetupCount(response.schoolSetupCount)
            setDeliveryWeekCount(response.deliveryWeekCount)
            setOrderFromProofCount(response.orderFromProofCount)
            setTallySheetCount(response.tallySheetCount)

        });
    }, [])

    const VerifyPermissionForProvidedRoute = (RouteName) => {
        if (!RoleName || RoleName.length <= 0) {
            navigate("/");
            return;
        }
        var FilteredRoutes = AllRoutes.filter(function (EachRoute) {
            return EachRoute.path === RouteName && EachRoute.Roles.includes(RoleName.toUpperCase());
        });
        if (FilteredRoutes && FilteredRoutes.length > 0)
            return true;
        else
            return false;
    };

    return (
        <>
            <div className="background">
                <div className="row mx-auto mr-0 pt-10">
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/quote-queue") &&
                        (<Link to="quote-queue" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h2>{flag == false ? loadingPanel : quoteRequestCount} &nbsp;</h2>
                                        <h5>Quote Queue</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="queue img" src={Q_Queue} />
                                    </div>
                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }

                    {
                        VerifyPermissionForProvidedRoute("/dashboard/quote-revise") &&
                        (<Link to="quote-revise" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h2>{flag == false ? loadingPanel : quoteReviseCount}  &nbsp;</h2>
                                        <h5>Revision Queue</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="Revision Icon" src={Q_Revision} />
                                    </div>
                                </div>
                                <button className="button-hover-effect button-hover-effect-blue card-link-blue text-white p-2 text-left mt-auto">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/quote-print") &&
                        (<Link to="quote-print" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h2>{flag == false ? loadingPanel : quotePrintCount} &nbsp;</h2>
                                        <h5>Print Queue</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="Print Icon" src={Q_Print} />
                                    </div>
                                </div>
                                <button className="button-hover-effect button-hover-effect-blue card-link-blue text-white p-2 text-left mt-auto">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/school-grade-status") &&
                        (<Link to="school-grade-status" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300" >
                            <div className="card card-body-blue  hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h5>School Setup</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="SchoolSetup Icon" src={SchoolSetup} />
                                    </div>
                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-md-5 hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/users") &&
                        (<Link to="users" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h5>Users</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue"  alt="Users Icon" src={Users} />
                                    </div>
                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-md-5 hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/deliveryWeekInfo") &&
                        (<Link to="deliveryWeekInfo" className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                   {/*     <h2>{flag == false ? loadingPanel : deliveryWeekCount} &nbsp;</h2>*/}
                                        <h5>Delivery Weeks Info</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="deliveryweek icon" src={DeliveryWeeks} />
                                    </div>
                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-md-5 hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/OrderFormProofgrid") &&
                        (<Link to='OrderFormProofgrid' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h5>Program Schedule Submission</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="OrderProof Icon" src={OrderProof} />
                                    </div>
                                </div>
                                <button className="button-hover-effect button-hover-effect-blue card-link-blue text-white p-2 text-left mt-md-5">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/TallySheetMaingrid") &&
                        (<Link to='TallySheetMaingrid' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h5>Tally Sheet Maintenance</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="TallyMaintenance" src={TallyMaintenance} />
                                    </div>
                                </div>
                                <button className="button-hover-effect button-hover-effect-blue card-link-blue text-white p-2 text-left mt-md-5">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/dashboard/ErrorLogs") &&
                        (<Link to='ErrorLogs' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-8">
                                        <h5>Error Logs</h5>
                                    </div>
                                    <div className="col-4 text-center">
                                        <img className="symbol-dashboard-blue" alt="View Error Logs" src={errorLog} style={{ height: "70px" }} />
                                    </div>
                                </div>
                                <button className="button-hover-effect button-hover-effect-blue card-link-blue text-white p-2 text-left mt-md-5">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                </div>
            </div>
        </>
    );
}

export default Home;
