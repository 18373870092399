
const AllRoutes = [
    { path: "/", element: "Login", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLUSER,SCHOOLREP" },
    { path: "/forgotpassword", element: "Forgotpassword", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLUSER,SCHOOLREP" },
    { path: "/terms", element: "Terms", Roles: "SCHOOLUSER" },
    { path: "/privacypolicy", element: "Privacypolicy", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLUSER,SCHOOLREP" },
    { path: "/dashboard", element: "Home", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLREP" },
    { path: "/dashboard/users", element: "AuthUse", Roles: "INTERNALADMIN,SCHOOLCOORDINATOR,SCHOOLREP,INTERNALUSER" },
    { path: "/dashboard/deliveryWeekInfo", element: "DeliveryWeekInfo", Roles: "INTERNALADMIN" },
    { path: "/dashboard/quote-queue", element: "QuoteRequest", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLREP" },
    { path: "/dashboard/quote-revise", element: "QuoteRevise", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR" },
    { path: "/dashboard/quote-print", element: "QuotePrint", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR" },
    { path: "/dashboard/school-grade-status", element: "SchoolGrade", Roles: "INTERNALADMIN,SCHOOLCOORDINATOR" },
    { path: "/dashboard/profile", element: "Profile", Roles: "INTERNALADMIN,INTERNALUSER,SCHOOLCOORDINATOR,SCHOOLREP" },
    { path: "/dashboard/OrderFormProofgrid", element: "OrderFormProofgrid", Roles: "INTERNALADMIN,SCHOOLCOORDINATOR" },
    { path: "/dashboard/TallySheetMaingrid", element: "TallySheetMaingrid", Roles: "INTERNALADMIN,INTERNALUSER" },
    { path: "/dashboard/ErrorLogs", element: "ErrorLogs", Roles: "INTERNALADMIN" },
    { path: "/externaldashboard", element: "Home", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/grades", element: "Grades", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/ProgramScheduleWizard", element: "ProgramScheduleWizard", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/schoolinfo", element: "SchoolInfoWizard", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/profile", element: "Profile", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/OrderEntryWizard", element: "OrderEntryWizard", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/forms", element: "Forms", Roles: "SCHOOLUSER" },
    { path: "/externaldashboard/referral", element: "Referral", Roles: "SCHOOLUSER" }
];
export default AllRoutes;
