import Notifications from "./Notifications/Messages";

const urls =
{
    registerUrl: "Authentication/register",
    loginUrl: "Authentication/loginasync",
    ForgotPasword: "Authentication/ForgetPassword",
    Impersonate: "Authentication/ImpersonateUserAsync",
    contractAccept: "UserContract/SetUserContract",
    changepassword: "Authentication/ChangePassword",
    getRoles: "Role/GetAllRole",
    GetAuthUsers: "ManageUser/GetAllUsers",
    GetAuthUserById: "ManageUser/GetUserById",
    UpdateAuthUser: "ManageUser/UpdateUser",
    CreateAuthUser: "ManageUser/CreateUser",
    GetAllUserContactList: "ManageUser/GetAllUserContactList",
    GetAllSystemUserList: "ManageUser/GetAllSystemUserList",
    GetAccountContactsList: "ManageUser/GetAccountContactsList",
    UpdateProfileImage: "ManageUser/UpdateProfileImage",
    CreateAuthRole: "Role/CreateRole",
    UpdateRole: "Role/UpdateRole",
    DeleteRole: "Role/DeleteRole",
    GetAllPermissions: "Role/getAllPermission",
    DeletUser: "ManageUser/DeleteUser",
    ResendEmail: "ManageUser/SendUserLoginEmail",
    terms: "GetContracts",
    UpdateProfile: "ManageUser/UpdateUser",
    AllContracts: "GetAllContracts",
    GetLastVisitedUrl: "UserNavigate/GetLastVisitUrl",
    SetLastVisitedUrl: "UserNavigate/SetLastVisitUrl",
    terms: "CreateContract",
    GetLatestTerms: "GetContracts",
    GetQuotes: "Quote/GetQuotes",
    getUserById: "ManageUser/GetUserById",
    //QuoteQueue
    GetQuoteQueue: "QuoteQueue/GetQuoteQueue",
    GetQuoteQueueById: "QuoteQueue/GetQuoteQueueById",
    GetQuoteQueueAttachmentListById: "QuoteQueue/GetAttachmentListById",
    GetQuoteQueueExceptionApprovalListById: "QuoteQueue/GetExceptionApprovalListById",
    UpdateQuoteRequest: "QuoteQueue/UpdateQuoteQueue",
    //QuoteRevision
    GetQuoteRevise: "QuoteRevision/GetQuoteRevise",
    GetQuoteReviseById: "QuoteRevision/GetQuoteReviseById",
    QuoteRevisionAssignment: "QuoteRevision/QuoteRevisionAssignment",
    //QuotePrint
    GetQuotePrint: "OrderFormProof/GetQuotePrint",
    GetQuotePrintById: "OrderFormProof/GetQuotePrintById",
    GetQuotePrintAttachmentListById: "OrderFormProof/GetAttachmentListById",
    UpdateStatusToUploaded: "OrderFormProof/UpdateStatusToUploaded",
    UpdateStatusToPrintComplete: "OrderFormProof/UpdateStatusToPrintComplete",
    OrderFormUploadFile: "OrderFormProof/UploadAttachment",
    //GradeWorksheet
    GetGradeWorksheetById: "GradeWorkSheet/GetGradeWorksheetById",
    GetAllGradeWorksheet: "GradeWorkSheet/GetAllGradeWorksheet",
    // GetQuotePrintById: "Quote/GetQuotePrintById",
    ErrorLog: "ErrorLogs/CreateErrorLog",
    GetErrorLogs: "ErrorLogs/GetErrorLogs",
    GetSchoolInfo: "SchoolInfo/GetSchoolInfo",
    SetSchoolInfo: "SchoolInfo/SetSchoolInfo",
    VerifyAndRefreshTokenAsync: "Authentication/VerifyAndRefreshTokenAsync",
    GetDashboardCounts: "Count/GetDashboardCounts",
    GetExternalDashboardCounts: "Count/GetExternalDashboardCounts",
    GetSchoolGrades: "SchoolGradeStatus/GetAllSchoolGradeStatus",
    UpdateSchoolGrade: "SchoolGradeStatus/UpdateSchoolGradeStatus",
    SchoolGradeKitUpdate: "SchoolGradeKitUpdate/CreateSchoolGradeKitUpdate",
    UpdateSchoolGradeKitUpdate: "SchoolGradeKitUpdate/UpdateSchoolGradeKitUpdate",
    ApproveUserAfterContract: "ManageUser/ApproveUserAfterContract",
    GetProgramSchedule: "ProgramSchedule/GetProgramSchedule",
    SetProgramSchedule: "ProgramSchedule/SetProgramSchedule",
    //Delivery Week Info
    GetDeliveryWeekList: "DeliveryWeek/GetDeliveryWeekList",
    CreateDeliveryWeek: "DeliveryWeek/CreateDeliveryWeek",
    UpdateDeliveryWeek: "DeliveryWeek/UpdateDeliveryWeek",
    DeleteDeliveryWeek: "DeliveryWeek/DeleteDeliveryWeek",
    OrderNameEntryList: "OrderNameList/GetAllAsync",
    OrderNameGradesList: "OrderNameList/GetGrades",
    OrderNameEntryCreate: "OrderNameList/CreateAsync",
    OrderNameEntryUpdate: "OrderNameList/UpdateAsync",
    OrderNameEntryDelete: "OrderNameList/DeleteAsync",
    GetTallySheetContainerOutModel: "TallySheetInfo/GetTallySheetContainerOutModel",
    OrderTallySheetSave: "TallySheetInfo/UpdateTallySheetInfo",
    OrderTallySheetList: "TallySheetInfo/GetTallySheetList",
    UpdateTallySheet: "TallySheetInfo/UpdateTallySheet",
    OrderFormProofList: "OrderFormProof/GetAllAsync",
    GetUBSSchoolGradeList: "OrderFormProof/GetUBSSchoolGradeList",
    UpdateOrderForm: "OrderFormProof/UpdateOrderForm",
    GetUserActivity: "ManageUser/GetUserActivity",
    GetReports: "Reports/GetReport",
    GetExcelReport: "Reports/GetExcelReport",
    GetSchoolReferral:"SchoolReferral/GetSchoolReferral",
    SetSchoolReferral:"SchoolReferral/SetSchoolReferral"
};


const CommonVariables = {
    urls: urls,
    ConfigData: undefined,
    getConfigData: async function () {
        if (!this.ConfigData) {
            var ConfigResponse = await fetch('./config.json');
            this.ConfigData = await ConfigResponse.json();
            return this.ConfigData;
        }
        else
            return this.ConfigData;
    },
    logout: function () {
        localStorage.clear();
        window.location.pathname = "\\";
    },
    localstorageData: {},
    setlocalStorageData: function (storageData) {
        this.localstorageData = {
            userId: localStorage.setItem('userId', storageData.userId ? storageData.userId : localStorage.getItem("userId")),
            accessToken: localStorage.setItem('accessToken', storageData.tokens.accessToken ? storageData.tokens.accessToken : localStorage.getItem("accessToken")),
            refreshToken: localStorage.setItem('refreshToken', storageData.tokens.refreshToken ? storageData.tokens.refreshToken : localStorage.getItem("refreshToken")),
            email: localStorage.setItem("email", storageData.email ? storageData.email : localStorage.getItem("email")),
            RoleName: localStorage.setItem("RoleName", storageData.RoleName ? storageData.RoleName : localStorage.getItem("RoleName"))
        }
    },
    getlocalstorageData: function () {
        return localStorage;
    },
    verifyLocalstorageDataAvailable: function () {
        let StorageData = this.getlocalstorageData();
        if (!StorageData.userId || !StorageData.accessToken || !StorageData.refreshToken || !StorageData.email) {
            return false;
        }
        return true;
    },
    factoryData: {},
    getFactoryData: function () {
        return this.factoryData;
    },
    setFactoryData: function (factoryData) {
        this.factoryData = factoryData;
    },
    isError: function (response) {
        if (response && response.isSuccess === false) {
            if (response.message)
                Notifications.ShowErrorMessage(response.message);
            else if (response.errorMessage)
                Notifications.ShowErrorMessage(response.errorMessage);
            else
                Notifications.ShowErrorMessage("Something went wrong.Please try again");
            return false;
        }
        if (response && response.status && response.status != "200") {
            Notifications.ShowErrorMessage("Something went wrong.Please try again");
            return false;
        }
        return true;
    }
}
export default CommonVariables;