import CommonVariables from "./variables";
import APIConnector from "./apiconnector";
import Notifications from "./Notifications/Messages";

const errors = [];

export function errorEventHandler(event) {
    let tempstorage = CommonVariables.getlocalstorageData();
    if (!tempstorage || !tempstorage.userId || tempstorage.userId.length <= 0)
        return;

    var errorData = {
        userId: tempstorage.userId,
        Exception: event.error.message,
        Path: window.location.pathname,
        originDate: new Date()
    };
    errors.push(errorData);

    if (errors.length > 0) {
        APIConnector.Post(CommonVariables.urls.ErrorLog, errors).then(function (resp) {
            errors = [];
        });
    }
};

window.onbeforeunload = function (e) {
    if (errors.length > 0) {
        APIConnector.Post(CommonVariables.urls.ErrorLog, errors).then(function (resp) {

        });
    }
};


