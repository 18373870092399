import React, { useEffect, useState } from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, TextArea, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import {requiredValidator} from "../SchoolInfoWizard/validators";
import { FormDropDownList } from '../SchoolInfoWizard/form-components';

const EditOrderNameEntry = (props) => {
    const [rowitem, setRowItem] = React.useState(props.item);
    const [gradedropdown, setGradedropdown] = React.useState([]);
    const [typedroppdown, setTypedroppdown] = React.useState([]);

    useEffect(() => {
        var set=new Set()

        var gdata=props.gradeData.filter((ele)=>{
            if(set.has(ele.grade))
            return false;
            else{
                set.add(ele.grade)
                return true;
            }
        })


        setGradedropdown(gdata);
    });
    const onGradeChange = (event) => {
        var obj = { ...event.target.value }
        var temp = { ...rowitem };
        temp.grade = obj;
        temp.type = obj;
        setRowItem(temp);
        var typedata=props.gradeData.filter((ele)=>{
            return ele.grade===event.target.value.grade
        })
        setTypedroppdown(typedata);
    }

    return (
        <Dialog title={`Add Order`} onClose={props.cancelEdit}>
            <div className="modalStyle">
                <Form className="rounded-t-2xl w-screen flex" initialValues={rowitem} onSubmit={props.onSubmit}
                    render={(formRenderProps) => (
                        <FormElement className="quote-modal rounded-t-2xl font-normal w-full" style={{}}>
                            <p>Important Information - PLEASE READ:</p>
                            <ol style={{ marginLeft: "35px" }}>
                            <li style={{ listStyle: "disc" }}>Select an option for all dropdown fields. Note: Grade Kindergarten = 09 and Pre-Kindergarten = 10.</li>
                            <li style={{ listStyle: "disc" }}><b>Your label will print EXACTLY as entered. We highly recommend typing names in upper/lower standard format. 
                                <br/>If you choose to type in all CAPS,you will be limited to 17 total characters (first and last name combined including 1 space). </b></li>
                            <li style={{ listStyle: "disc" }}>If you are purchasing extra kits, type "Extra" in the First Name field and "kits" in the Last Name field.</li>
                            </ol>  
                            <fieldset className="k-form-fieldset grid md:grid-cols-2 gap-2">
                                <div >
                                    <label className="fieldlabel">Customer #</label>
                                    <Field name={"custnmbr"} component={Input} disabled={true} />
                                </div>
                                <div >
                                    <label className="fieldlabel">First name</label>
                                    <Field name={"firstname"} component={Input} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Grade</label>
                                    <Field name={"grade"} component={DropDownList}
                                        textField="grade" dataItemKey="rowid" data={gradedropdown} onChange={onGradeChange} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Last name</label>
                                    <Field name={"lastname"} component={Input} />
                                </div>
                                <div >
                                    <label className="fieldlabel">Type</label>
                                    <Field name={"type"} component={FormDropDownList}
                                        textField="type" dataItemKey="rowid" data={typedroppdown} validator={requiredValidator}/>
                                </div>
                                <div >
                                    <label className="fieldlabel">Quantity of Kits ordered</label>
                                    <Field name={"ordered_kit"} component={NumericTextBox} min={0} max={9999} />
                                </div>
                            </fieldset>
                            <div className="k-form-buttons">
                                <button type="submit" className="button-save" onClick={formRenderProps.onSubmit} disabled={!formRenderProps.allowSubmit}>
                                    {props.item.isfromAdd ? "Save" : "Update"}
                                </button>
                                <button className="button-cancel" onClick={() => props.cancelEdit()}>
                                    Cancel
                                </button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>
        </Dialog>
    );
};
export default EditOrderNameEntry;