import * as React from "react";
import { useEffect } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import SchoolInfo from "./schoolinfo.js";
import YourContactInfo from "./yourcontactinfo";
import Programs from "./Programs";
import { TaxInfo } from "./TaxInfo.js";
import Referral from "./Referral.js";
import CommonVariables from "../../../variables";
import APIConnector from "../../../apiconnector.js";
import './SchoolInfoWizard.css';
import Notifications from "../../../Notifications/Messages";
import { useNavigate } from 'react-router';
import { WhereAmI } from "../../../UserActivity.js";


/**
 * This component is used to review and edit School,tax,kindergarten,referral and Contact Info 
 * 
 */

export const SchoolInfoWizard = (props) => {
    const navigate = useNavigate();
    const [stepPages, setStepPages] = React.useState([SchoolInfo, YourContactInfo, Programs, Referral, TaxInfo]);
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [data, setData] = React.useState();
    const [currentStage, setcurrentStage] = React.useState(0);
    const [steps, setSteps] = React.useState([
        {
            label: "School Info",
            isValid: undefined,
        },
        {
            label: "Your Contact Info",
            isValid: undefined,
        },
        {
            label: "Programs",
            isValid: undefined,
        },
        {
            label: "Referral Program",
            isValid: undefined,
        },
        {
            label: "Tax Info",
            isValid: undefined,
        }
    ]);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid = steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;

    useEffect(() => {
        props.onLoaderRaise(true, "Getting school info ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetSchoolInfo).then(function (resp) {
            APIConnector.Post(CommonVariables.urls.GetUserActivity, {}).then(function (Activityresponse) {
                if (!CommonVariables.isError(Activityresponse))
                    return;

                if (Activityresponse.orderFormWizardHide) {
                    Activityresponse.stage = Activityresponse.stage + 1;
                }
                setcurrentStage(Activityresponse.stage);
            });
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            //Remove Programs Section based on showProgramSection Flag
            if (resp.schoolProgramModel_Step3.showProgramSection == false) {
                var AllSteps = [...steps];
                AllSteps.splice(2, 1);
                setSteps(AllSteps);

                var AllstepPages = [...stepPages];
                AllstepPages.splice(2, 1);
                setStepPages(AllstepPages);
            }

            if (resp.schoolProgramModel_Step3 && resp.schoolProgramModel_Step3.program_date) {
                if (resp.schoolProgramModel_Step3.program_date != '0001-01-01T00:00:00') {
                    resp.schoolProgramModel_Step3.program_date = new Date(resp.schoolProgramModel_Step3.program_date);
                }
                else
                    resp.schoolProgramModel_Step3.program_date = new Date();
            }
            else {
                resp.schoolProgramModel_Step3 = {};
                resp.schoolProgramModel_Step3.program_date = new Date();
            }

            if (resp.schoolProgramModel_Step3.is_selected == null)
                resp.schoolProgramModel_Step3.is_selected = false;

            if(!resp.taxExemptionModel_Step5 ){
                resp.taxExemptionModel_Step5 = {};
            }

            if (resp.taxExemptionModel_Step5.is_selected == null)
                resp.taxExemptionModel_Step5.is_selected = false;


            if (resp.contact_Step2 && resp.contact_Step2.tt_stateprovinceid) {
                resp.contact_Step2.tt_stateprovinceid = resp.stateProvinceListModel.filter(function (Each) { return Each.state_province_id === resp.contact_Step2.tt_stateprovinceid })[0];
            }
            if (resp.accountModel_Step1 && resp.accountModel_Step1.tt_stateprovinceid) {
                resp.accountModel_Step1.tt_stateprovinceid = resp.stateProvinceListModel.filter(function (Each) { return Each.state_province_id === resp.accountModel_Step1.tt_stateprovinceid })[0];
            }
            setData(resp);
            setFormState(resp);
        });
    }, []);


    const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;
            if (!isValid)
                return;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));
            setSteps(currentSteps);
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            if (isLastStep && isPreviousStepsValid && isValid) {
                let FinalData = values;

                if (FinalData.contact_Step2 && FinalData.contact_Step2.tt_stateprovinceid && FinalData.contact_Step2.tt_stateprovinceid.state_province_id)
                    FinalData.contact_Step2.tt_stateprovinceid = FinalData.contact_Step2.tt_stateprovinceid.state_province_id;

                if (FinalData.accountModel_Step1 && FinalData.accountModel_Step1.tt_stateprovinceid && FinalData.accountModel_Step1.tt_stateprovinceid.state_province_id)
                    FinalData.accountModel_Step1.tt_stateprovinceid = FinalData.accountModel_Step1.tt_stateprovinceid.state_province_id;

                FinalData.schoolProgramModel_Step3.accountid = FinalData.accountModel_Step1.accountid;
                FinalData.schoolReferralModel_Step4.referring_school_id = FinalData.accountModel_Step1.accountid;
              
                props.onLoaderRaise(true, "Submitting school info ....", { marginTop: "145px" });
                APIConnector.Post(CommonVariables.urls.SetSchoolInfo, FinalData).then(function (resp) {
                    props.onLoaderRaise(false);
                    if (!CommonVariables.isError(resp))
                        return;
                    Notifications.ShowSuccessMessage("School setup successfully done");
                    navigate("/externaldashboard");
                });
            }
        },
        [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
    );
    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );
    const handleChange = (e) => {
        if (currentStage != WhereAmI.SchoolSetup)
            setStep(e.value);
    };
    return (
        <div>
            <div style={{ textAlign: "center", paddingLeft: "100px" }}>
                <h2 className="inline-block primary-heading">School Setup</h2>
            </div>
            <div className="d-flex justify-content-center">
                <div className="wizard-div col-11 border-0 rounded-2xl" style={{ justifyContent: "center" }}>
                    <Stepper className='kendo-stepper' onChange={handleChange} value={step} items={steps} />
                    {data &&
                        (
                            <Form initialValues={formState} onSubmitClick={onStepSubmit} render={formRenderProps =>
                                <div style={{ alignSelf: 'center' }} className="m-auto w-responsive">
                                    <FormElement className='w-full ml-2'>
                                        {
                                            (steps[step].label === "School Info" && <SchoolInfo {...formState} />) ||
                                            (steps[step].label === "Programs" && <Programs {...formState} />) ||
                                            (steps[step].label === "Your Contact Info" && <YourContactInfo {...formState} />) ||
                                            (steps[step].label === "Referral Program" && <Referral {...formState} />) ||
                                            (stepPages[step])
                                        }
                                        <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                                        <div id="footerID" style={{ width: '100% !important' }}
                                            className={'WizardFooter'}>
                                            <div style={{ textAlign: "center" }}>
                                                {step !== 0 ?
                                                    <Button className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md" onClick={onPrevClick}>Previous</Button>
                                                    : undefined
                                                }
                                                <span className="step-text-class">Step {step + 1} of {steps.length}</span>
                                                <Button className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md" onClick={formRenderProps.onSubmit}> {isLastStep ? 'Submit' : 'Next'}</Button>
                                            </div>
                                        </div>
                                    </FormElement>
                                </div>}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};
export default SchoolInfoWizard;
