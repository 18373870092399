import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

const PreviewOrderTallySheet = props => {

    //#region "     On Load of Component    "
    const [data, setData] = React.useState();
    React.useEffect(() => {
        RefreshOrderTallySheetGrid();
    }, []);

    //#endregion

    const RefreshOrderTallySheetGrid = () => {
        props.onLoaderRaise(true, "Getting Order Tally sheet details ...", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetTallySheetContainerOutModel).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response.sheetGrid)
                response.sheetGrid = [];
            setData(response.sheetGrid);
        });
    };

    return <Dialog title={`Preview TallySheet Data`} onClose={props.cancelEdit}>
        <div className="modalStyle">
            <Grid className="rounded-2xl border-b-4" style={{ height: "250px" }}
                data={data}
                sortable={true}
                resizable={true}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "single"
                }}>
                <Column field="grade" title="Grade" width={120} />
                <Column field="type" title="Type" width={120} />
                <Column field="kitPrice" format="{0:c2}" width={170} title="Kit Cost Each" />
                <Column field="kits_ordered" title="# of Kits Ordered" width={160} />
                <Column field="total" format="{0:c2}" title="Total Cost($$)" width={180} />
            </Grid>
        </div>
    </Dialog>;
};

export default PreviewOrderTallySheet;