import React from 'react';
import { Link, useNavigate } from "react-router-dom"
import Notifications from "../../Notifications/Messages"
import '../style/logincommon.css';
import CommonVariables from '../../variables';
import APIConnector from "../../apiconnector";
import $ from "jquery";

/**
 * This component render Forgot Password page enable user to create new password
 * 
 */


function Forgotpassword(props) {
    const navigation = useNavigate();
    const toemailsent = () => {
        //const regEx = /^\w+(?:[\.!#%&'*=+\-`{|}~]\w+)*@\w+(?:[\.!#%&'*=+\-`{|}~]\w+)*(?:\.\w{2,3})+$/;
        var username = $("#usernameID").val();
        if (!username || username.trim().length <= 0) {
            Notifications.ShowWarningMessage("Please enter username");
            $("#usernameID").focus();
            return;
        }
        let data = {
            email: username
        };
        props.onLoaderRaise(true, "Verifying email ...", { marginTop: "155px" });
        APIConnector.Post(CommonVariables.urls.ForgotPasword, data).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            if (!response.isSuccess) {
                Notifications.ShowErrorMessage(response.errorMessage ? response.errorMessage : "Something went wrong.Please try again.");
                return;
            }
            Notifications.ShowSuccessMessage("A new password has been sent to your email");
            navigation('/');
        });
    };

    return (
        <>

            <div className='container login-form mt-5 py-4 px-4 rounded pb-5'>
                <div className="mb-3"><h5>Forgot Password</h5></div>
                <div className="mb-3">
                    <input type="text" autoFocus={true} style={{ height: "30px" }} className="mb-3 mt-4 rounded-0 border-input"
                        id="usernameID" placeholder="Enter your username" required />
                </div>
                <button onClick={toemailsent}
                    type="submit" className="button-save px-3 mt-4 mb-3 hover:bg-sky-700 rounded-0">
                    Submit
                </button>
                <Link to="/" style={{ display: "block" }} className="text-decoration-none text-primary mt-4 rounded-0 mx-0">Already have an account? Login</Link>
            </div>
        </>
    );
}

export default Forgotpassword;