// import { createContext, useState } from 'babel-plugin-react-html-attrs';
import React, { useSyncExternalStore } from 'react';

/**
 * This component renders the no access page
 * 
 */

function Noaccess(props) {
    return (
        <div>
            <h1 className='text-center pt-5 text-danger'>Access denied</h1>
        </div>
    );
}

export default Noaccess;