import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { FormInput, FormUpload, FormDatePicker } from "./form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";
import { RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ReferralProgramDetails from "../SchoolInfoWizard/ReferralProgramDetails";

export default function Referral(props) {
    const [OpenModal, setOpenModal] = React.useState(false);

    const [is_refselected, setIs_refselected] = React.useState(props.schoolReferralModel_Step4.is_selected);
    const handlerefChange = React.useCallback(
        (e) => {
            props.schoolReferralModel_Step4.is_selected = !props.schoolReferralModel_Step4.is_selected;
            setIs_refselected(!is_refselected);
        },
        [props.schoolReferralModel_Step4.is_selected]
    );

    const programDetailsclick = () => {
        setOpenModal(true);
    };
    const handleCancelModal = () => {
        setOpenModal(false);
    };
    return (
        <div className="kendo-form" >
            <p className="font-weight-bold mt-3 d-block d-sm-none text-center">
                Programs
            </p>
            <br />
            <h5 className="wizardStepHeadLabel">
                Are you interested in earning extra money for your school? You can do this later at any time from the menu. &nbsp;
                <a style={{ textDecoration: "underline" }} href="#" onClick={programDetailsclick}>
                    Review Referral Program Details</a>
            </h5>
            <div className="text-xs tracking-wide font-bold fieldlabel" style={{ marginTop: "25px" }}>
                <div style={{ margin: "10px", marginTop: "20px" }}>
                    <RadioButton
                        name={"schoolReferralModel_Step4.is_selected"}
                        value={is_refselected}
                        checked={is_refselected}
                        label="Yes, I would like to enter a referral"
                        onChange={handlerefChange}
                        style={{ borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }}
                    />
                </div>
                <div style={{ margin: "10px" }}>
                    <RadioButton
                        name={"schoolReferralModel_Step4.is_selected"}
                        value={!is_refselected}
                        checked={!is_refselected}
                        label="No, I am not interested at this time"
                        onChange={handlerefChange}
                        style={{ borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }}
                    />
                </div>
            </div>
            {is_refselected &&
                <div style={{ margin: "10px", marginTop: "20px" }} className="row">
                    <div className="col-12 col-md-12">
                        <label className="fieldlabel">Name of school you are referring</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_name"}
                            id={"schoolReferralModel_Step4.referred_school_name"}
                            name={"schoolReferralModel_Step4.referred_school_name"}
                            component={FormInput}
                            validator={requiredValidator}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Address</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_line1"}
                            id={"schoolReferralModel_Step4.referred_school_address_line1"}
                            name={"schoolReferralModel_Step4.referred_school_address_line1"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">City</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_city"}
                            id={"schoolReferralModel_Step4.referred_school_address_city"}
                            name={"schoolReferralModel_Step4.referred_school_address_city"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">State</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_state"}
                            id={"schoolReferralModel_Step4.referred_school_address_state"}
                            name={"schoolReferralModel_Step4.referred_school_address_state"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Zip</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            id={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            name={"schoolReferralModel_Step4.referred_school_address_postalcode"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <label className="fieldlabel">Contact at referred school</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_name"}
                            id={"schoolReferralModel_Step4.referred_school_contact_name"}
                            name={"schoolReferralModel_Step4.referred_school_contact_name"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Contact phone number</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            id={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            name={"schoolReferralModel_Step4.referred_school_contact_phone"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="fieldlabel">Contact email</label>
                        <Field
                            key={"schoolReferralModel_Step4.referred_school_contact_email"}
                            id={"schoolReferralModel_Step4.referred_school_contact_email"}
                            name={"schoolReferralModel_Step4.referred_school_contact_email"}
                            component={FormInput}
                        />
                    </div>
                    <div className="col-12 col-md-6" style={{ marginTop: "20px" }}>
                        <label className="fieldlabel">Have you previously spoken with this contact?</label>
                        <Field
                            key={"schoolReferralModel_Step4.contact_spoken_to"}
                            id={"schoolReferralModel_Step4.contact_spoken_to"}
                            name={"schoolReferralModel_Step4.contact_spoken_to"}
                            component={DropDownList}
                            data={["Yes", "No"]}
                            validator={requiredValidator}
                        />
                    </div>
                    <div className="col-12 col-md-6" style={{ marginTop: "20px" }}>
                        <label className="fieldlabel">Does the school know we will be contacting them?</label>
                        <Field
                            key={"schoolReferralModel_Step4.school_expecting_call"}
                            id={"schoolReferralModel_Step4.school_expecting_call"}
                            name={"schoolReferralModel_Step4.school_expecting_call"}
                            component={DropDownList}
                            data={["Yes", "No"]}
                            validator={requiredValidator}
                        />
                    </div>
                </div>
            }
            {
                OpenModal && <ReferralProgramDetails cancelModal={handleCancelModal} />
            }
        </div>
    )
};
