import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import DownloadIcon from '@mui/icons-material/Download';
import { Label } from "@progress/kendo-react-labels";
import APIConnector from "../../../../apiconnector"
import CommonVariables from "../../../../variables";
import { triggerBase64Download } from 'common-base64-downloader-react';
import Notifications from "../../../../Notifications/Messages";
import { dynamicSpinner, formatDateOnly } from "../../commonfunctions";
import { BorderColor } from "@mui/icons-material";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const EditQuotePrint = (props) => {

    //#region " Initialization of State  Values       "
    const [dialogDimension, setDialogDimension] = React.useState([])
    const [files, setFiles] = React.useState([]);
    const [loadingQAfiles, setloadingQAfiles] = React.useState(true);
    const [rowitem, setRowItem] = React.useState({
        tt_name: props.item.tt_name,
        tt_accountidname: props.item.tt_accountidname,
        tt_firstname: props.item.tt_firstname,
        tt_city: props.item.tt_city,
        tt_lastname: props.item.tt_lastname,
        tt_stateidname: props.item.tt_stateidname,
        tt_title: props.item.tt_title,
        tt_postalcode: props.item.tt_postalcode,
        tt_address_line1: props.item.tt_address_line1,
        tt_countryidname: props.item.tt_countryidname,
        tt_address_line2: props.item.tt_address_line2,
        tt_shippingphonenumber: props.item.tt_shippingphonenumber,
        takehomedate: formatDateOnly(props.item.takehomedate),
        shipdays: props.item.shipdays,
        tt_type_value: props.item.tt_type_value,
        tt_shipbydate: formatDateOnly(props.item.tt_shipbydate),
        tt_receivedidyouforgetflyer: props.item.tt_receivedidyouforgetflyer ? "Yes" : "No",
        tt_receiveelectronicflyer: props.item.tt_receiveelectronicflyer ? "Yes" : "No"
    });
    //#endregion

    //#region "     On Load         "
    React.useEffect(() => {
        RefreshAttachmentsList();
        var windowheight = window.innerHeight;
        var windowwidth = window.innerWidth;
        var neededheight = windowheight - (windowheight / 5)
        var neededwidth = windowwidth - (windowwidth / 10)
        setDialogDimension([neededheight, neededwidth]);
    }, []);

    const RefreshAttachmentsList = () => {
        setloadingQAfiles(true);
        APIConnector.Post(CommonVariables.urls.GetQuotePrintAttachmentListById, { id: props.item.tt_orderformproofid }).then(function (response) {
            if (!CommonVariables.isError(response))
                return;
            if (response && response.length > 0)
                setFiles(response);
            setloadingQAfiles(false);
        });
    };
    //#endregion

    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion

    //#region "     Upload Files      "
    const uploadFile = (e) => {
        const formData = new FormData();
        formData.append("Form", e.target.files[0]);
        formData.append("tt_orderformproofid", props.item.tt_orderformproofid);
        formData.append("userId", CommonVariables.getlocalstorageData().userId);
        try {
            props.onLoaderRaise(true, "Uploading file ...", { marginTop: "145px" });
            APIConnector.FileUpload(CommonVariables.urls.OrderFormUploadFile, formData).then(function (response) {
                props.onLoaderRaise(false);
                if (!CommonVariables.isError(response))
                    return;
                if (response && response.isSuccess == true) {
                    Notifications.ShowSuccessMessage("File Uploaded successfully");
                    RefreshAttachmentsList();
                }
                else {
                    let message = "";
                    if (response && response.message)
                        message = response.message;
                    else
                        message = "Uploading Failed";
                    Notifications.ShowErrorMessage(message);
                }
            });
        } catch (ex) {
            Notifications.ShowErrorMessage(ex);
        }
    };
    //#endregion

    return (
        <Dialog className="w-screen font-normal" title={`Edit Quote`} onClose={props.cancelEdit} height={dialogDimension[0]} width={dialogDimension[1]}>
            <Form
                className="rounded-t-2xl w-screen mb-2"
                onSubmit={props.onSubmit}
                initialValues={rowitem}
                render={(formRenderProps) => (
                    <FormElement className="quote-modal rounded-t-2xl" style={{}}>
                        <fieldset className="k-form-fieldset grid md:grid-cols-4 gap-2">
                            <div>
                                <label className="fieldlabel">Account name</label>
                                <Field name={"tt_accountidname"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Account number</label>
                                <Field name={"tt_name"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">First name</label>
                                <Field name={"tt_firstname"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">City</label>
                                <Field name={"tt_city"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Last name</label>
                                <Field name={"tt_lastname"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">State</label>
                                <Field name={"tt_stateidname"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Title</label>
                                <Field name={"tt_title"} component={Input} disabled={true} />
                            </div>
                            <div>
                                <label className="fieldlabel">Postal code</label>
                                <Field name={"tt_postalcode"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Address line 1</label>
                                <Field name={"tt_address_line1"} component={Input} disabled={true} />
                            </div>
                            <div>
                                <label className="fieldlabel">Country</label>
                                <Field name={"tt_countryidname"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Address line 2</label>
                                <Field name={"tt_address_line2"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Shipping phone</label>
                                <Field name={"tt_shippingphonenumber"} component={Input} disabled={true} />
                            </div>
                            <div>
                                <label className="fieldlabel">Take home date</label>
                                <Field name={"takehomedate"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Ship days</label>
                                <Field name={"shipdays"} component={Input} disabled={true} />
                            </div>
                            <div>
                                <label className="fieldlabel">Type</label>
                                <Field name={"tt_type_value"} component={Input} disabled={true}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Ship by date</label>
                                <Field name={"tt_shipbydate"} component={Input} disabled={true} />
                            </div>

                            <div>
                                <label className="fieldlabel">Received did you forget flyer</label>
                                <Field name={"tt_receivedidyouforgetflyer"} component={DropDownList} disabled={true} data={["Yes", "No"]}/>
                            </div>
                            <div>
                                <label className="fieldlabel">Received electronic flyer</label>
                                <Field name={"tt_receiveelectronicflyer"} component={DropDownList} disabled={true} data={["Yes", "No"]}/>
                            </div>
                        </fieldset>
                        <div style={{ display: "flex" }}>
                            <div className="col-sm-8" style={{ textAlign: "left" }}>
                                <Label className="fieldlabel">Attachments</Label>
                            </div>
                           {/* <div className="col-sm-4" style={{ textAlign: "right", cursor: "pointer" }}>
                                <input style={{ display: "none" }} id="choose-file" type="file" onChange={uploadFile} />
                                <label htmlFor="choose-file">
                                    <span title="Upload File" style={{font: "caption"}}>Upload File
                                        <UploadFileIcon style={{ marginBottom: "6px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                                    </span>
                                </label>
                            </div> */}
                            
                        </div>
                        {
                            <div className="sok-tile-row" style={{ border: "2px solid", borderColor: "#b4b1b1", height: "auto" }}>
                                {loadingQAfiles && dynamicSpinner({ width: "80px", height: "80px", marginTop: "31px", marginLeft: "12px" })}
                                {!loadingQAfiles && files.length <= 0 && <div style={{ textAlign: "center" }}><label>No Attachments avaialble</label></div>}
                                {!loadingQAfiles && files.length > 0 && files.map((EachFile, i) => (
                                    <div className="sok-tile" key={i}>
                                        <DownloadIcon />
                                        <div className="sok-tile-name" onClick={() => { PdfDownload(EachFile.fileNmae, EachFile.documentBody) }}>
                                            {EachFile.fileNmae}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        <div className="k-form-buttons">
                            <button type={"submit"} className="button-save" onClick={props.UpdateStatusToUploaded}>
                                Update status to uploaded
                            </button>
                            <button type={"submit"} className="button-save" onClick={props.UpdateStatusToPrintingUploaded}>
                                Update status to printing completed
                            </button>
                            <button className="button-cancel" onClick={props.cancelEdit}>Cancel</button>
                        </div>
                    </FormElement>
                )}
            />
        </Dialog>
    );
};

export default EditQuotePrint;
