import * as React from 'react';

const Completed = (props) => <div>
    <br />
    <br />
    <br />
    <label className="fieldlabel" style={{ letterSpacing:"0.3px" }}>
        Please review your selections and pricing by using the &quot;Previous&quot; button below. Once selections and pricing are reviewed and accurate, click the &quot;Submit&quot; button below. Once your sale is live, you cannot make changes to kit components or parent pricing.
    </label>
    <br />
    <br />
    <br />
</div>;

export default Completed;
