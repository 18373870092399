import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom"
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import OrderType from './WizardPages/orderingType';
import ChooseSchedule from './WizardPages/chooseSchedule';
import Completed from './WizardPages/completed';
import Notifications from '../../../Notifications/Messages';
import './ProgramSchduleWizardstyles.css';
import APIConnector from '../../../apiconnector';
import CommonVariables from '../../../variables'
import ProgramSchedulePricing from './WizardPages/programSchedulePricing';
import OrderFormProof from './WizardPages/orderFormProof';
import $ from 'jquery';
import { useNavigate } from 'react-router';
import { formatDateOnly } from '../../../Dashboard/components/commonfunctions';
import { WhereAmI } from "../../../UserActivity";
import { triggerBase64Download } from 'common-base64-downloader-react';
import DownloadIcon from '@mui/icons-material/Download';


//const useOutsideAlerter = (ref) => {
//    const navigate = useNavigate();
//    useEffect(() => {
//        /**
//         * Alert if clicked on outside of element
//         */
//        function handleClickOutside(event) {
//            if (ref.current && !ref.current.contains(event.target)) {
//                if (window.confirm("Changes you made may not be saved."))
//                    navigate("/externaldashboard");
//                else
//                    return true;
//            }
//        };
//        // Bind the event listener
//        document.addEventListener("mousedown", handleClickOutside);
//        return () => {
//            // Unbind the event listener on clean up
//            document.removeEventListener("mousedown", handleClickOutside);
//        };
//    }, [ref]);
//};

/**
 * This component is used for choosing the order type ,program delivery dates, update order form proof information and edit the parent pricing of different kits and items.
 * 
 */
const ProgramScheduleWizard = (props) => {
    //const wrapperRef = useRef(null);
    //useOutsideAlerter(wrapperRef);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const [currentStage, setcurrentStage] = React.useState(0);
    const [step, setStep] = React.useState(0);
    const [programdata, setProgramdata] = React.useState();
    const [formState, setFormState] = React.useState();
    const [isStageVisited, setisStageVisited] = React.useState(0);
    const [orderformschooladdress, setOrderformschooladdress] = React.useState();
    const [steps, setSteps] = React.useState([{
        label: 'Start',
        isValid: undefined
    }, {
        label: 'Choose Schedule',
        isValid: undefined
    }, {
        label: 'Pricing',
        isValid: undefined
    }, {
        label: 'Order Forms',
        isValid: undefined
    }, {
        label: 'Completed',
        isValid: undefined
    }]);
    const stepPages = [OrderType, ChooseSchedule, ProgramSchedulePricing, OrderFormProof, Completed];

    const OrderFormTypechangeEvent = (event) => {
        let input = { ...formState };
        input.programDeliveryOutModel.orderType = event.value;
        setFormState(input);
        if (event.value === "PaperOnly") {
            setSteps([{
                label: 'Start',
                isValid: undefined
            }, {
                label: 'Choose Schedule',
                isValid: undefined
            }, {
                label: 'Completed',
                isValid: undefined
            }]);
        }
        else {
            setSteps([{
                label: 'Start',
                isValid: undefined
            }, {
                label: 'Choose Schedule',
                isValid: undefined
            }, {
                label: 'Pricing',
                isValid: undefined
            }, {
                label: 'Order Forms',
                isValid: undefined
            }, {
                label: 'Completed',
                isValid: undefined
            }]);
        }
    };
    const GetUserInfo = function () {
        let input = {
            userId: localStorage.getItem("userId"),
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            if (resp.userName) {
                setUserData(resp);
            }
        });
    };
    useEffect(() => {
        props.onLoaderRaise(true);
        
        APIConnector.Post(CommonVariables.urls.GetUserActivity, {}).then(function (Activityresponse) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(Activityresponse))
                return;
            setcurrentStage(Activityresponse.stage);
            if (Activityresponse.isStageVisited === 3) {//Already visited then 
                setisStageVisited(Activityresponse.isStageVisited);
            }
            
            props.onLoaderRaise(true, "Getting program schedule info ....", { marginTop: "145px" });
            APIConnector.Post(CommonVariables.urls.GetProgramSchedule).then(function (resp) {
                props.onLoaderRaise(false);
                if (!CommonVariables.isError(resp))
                    return;

                GetUserInfo();
                resp = AssignDefaultDateForAllDateFields(resp, Activityresponse);
                setFormState(resp);
                setProgramdata(resp);
                setOrderformschooladdress(BuildOrderFormSchoolAddress(resp));
                if (resp.programDeliveryOutModel.orderType === "PaperOnly") {
                    setSteps([{
                        label: 'Start',
                        isValid: undefined
                    }, {
                        label: 'Choose Schedule',
                        isValid: undefined
                    }, {
                        label: 'Completed',
                        isValid: undefined
                    }]);
                }
            });
        });
    }, []);

    function AssignDefaultDateForAllDateFields(resp, Activityresponse) {
        if (!resp.programDeliveryOutModel) {
            resp.programDeliveryOutModel = {};
        }
        if (resp.programDeliveryOutModel.takeHomeDate) {
            if (resp.programDeliveryOutModel.takeHomeDate != '1900-01-01T00:00:00') {
                resp.programDeliveryOutModel.takeHomeDate = new Date(resp.programDeliveryOutModel.takeHomeDate);
            }
            else
                resp.programDeliveryOutModel.takeHomeDate = "";
        }
        //else {
        //    resp.programDeliveryOutModel.takeHomeDate = new Date();
        //}
        if (resp.programDeliveryOutModel && resp.programDeliveryOutModel.orderDueDate) {
            if (resp.programDeliveryOutModel.orderDueDate != '1900-01-01T00:00:00') {
                resp.programDeliveryOutModel.orderDueDate = new Date(resp.programDeliveryOutModel.orderDueDate);
            }
            else
                resp.programDeliveryOutModel.orderDueDate = "";
        }
        //else {
        //    resp.programDeliveryOutModel.orderDueDate = new Date();
        //}

        if (resp.programDeliveryOutModel && resp.programDeliveryOutModel.returnByDate) {
            if (resp.programDeliveryOutModel.returnByDate != '1900-01-01T00:00:00') {
                resp.programDeliveryOutModel.returnByDate = new Date(resp.programDeliveryOutModel.returnByDate);
            }
            else
                resp.programDeliveryOutModel.returnByDate = "";
        }
        //else {
        //    resp.programDeliveryOutModel.returnByDate = new Date();
        //}


        if (resp.programDeliveryOutModel.deliveryWeek) {
            if (resp.programDeliveryOutModel.deliveryWeek == '1900-01-01T00:00:00') {
                resp.programDeliveryOutModel.deliveryWeek = "";
            }
            else {
                resp.programDeliveryOutModel.deliveryWeek = formatDateOnly(resp.programDeliveryOutModel.deliveryWeek);
            }
        }
        if (resp.deliveryWeekOutModel && resp.deliveryWeekOutModel.length > 0) {
            resp.deliveryWeekOutModel = resp.deliveryWeekOutModel.map((item) => {
                return formatDateOnly(item.desiredDeliveryWeek);
            });
        }
        if (resp.bulkItemsOutList && resp.bulkItemsOutList.length > 0)
            resp.bulkItemsOutList = resp.bulkItemsOutList.map((item) => {
                if (Activityresponse.isStageVisited !== 3) {
                    item.parentPrice = item.itemPrice;
                }
                return item;
            });

        if (resp.additionalItemsOutList && resp.additionalItemsOutList.length > 0)
            resp.additionalItemsOutList = resp.additionalItemsOutList.map((item) => {
                if (Activityresponse.isStageVisited !== 3) {
                    item.parentPrice = item.itemPrice;
                }
                return item;
            });

        if (resp.readKitPricingOutList && resp.readKitPricingOutList.length > 0)
            resp.readKitPricingOutList = resp.readKitPricingOutList.map((item) => {
                if (Activityresponse.isStageVisited !== 3) {
                    item.parentPrice = item.kitPrice;
                }
                return item;
            });

        return resp;
    };
    function BuildOrderFormSchoolAddress(resp) {
        return {
            tt_FirstName: resp.orderingInfoOutModel.tt_FirstName,
            tt_LastName: resp.orderingInfoOutModel.tt_LastName,
            tt_Title: resp.orderingInfoOutModel.tt_Title,
            tt_Address_line1: resp.orderingInfoOutModel.tt_Address_line1,
            tt_address_line2: resp.orderingInfoOutModel.tt_address_line2,
            tt_City: resp.orderingInfoOutModel.tt_City,
            tt_PostalCode: resp.orderingInfoOutModel.tt_PostalCode,
            tt_StateId: resp.orderingInfoOutModel.tt_StateId,
        };
    };

    var lastStepIndex = steps.length - 1;
    var isLastStep = lastStepIndex === step;
    const onStepSubmit = React.useCallback(event => {
        const {
            isValid,
            values
        } = event;
        const currentSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === step ? isValid : currentStep.isValid
        }));
        setSteps(currentSteps);
        if (steps[step].label === "Choose Schedule" && (!formState.programDeliveryOutModel || !formState.programDeliveryOutModel.isValid)) {
            return;
        }
        if (steps[step].label === "Order Forms" && (!formState.orderingInfoOutModel || !formState.orderingInfoOutModel.isValid)) {
            return;
        }

        if (!isValid) {
            return;
        }
        setStep(() => Math.min(step + 1, lastStepIndex));
        if (isLastStep) {
            let input = { ...formState };

            if (!ValidateBeforeSubmit(input)) {
                return;
            }


            if (input.orderingInfoOutModel.tt_ShipTo)
                input.orderingInfoOutModel.tt_ShipTo = input.orderingInfoOutModel.tt_ShipTo.tt_ShipTo;

            if (input.orderingInfoOutModel.tt_StateId)
                input.orderingInfoOutModel.tt_StateId = input.orderingInfoOutModel.tt_StateId.state_province_id;

            if (input.programDeliveryOutModel.deliveryWeek && input.programDeliveryOutModel.deliveryWeek.length > 0)
                input.programDeliveryOutModel.deliveryWeek = new Date(input.programDeliveryOutModel.deliveryWeek);
            else
                input.programDeliveryOutModel.deliveryWeek = '1900-01-01T00:00:00';


            if (!input.programDeliveryOutModel.takeHomeDate || input.programDeliveryOutModel.takeHomeDate == "") {
                input.programDeliveryOutModel.takeHomeDate = '1900-01-01T00:00:00';
            }
            if (!input.programDeliveryOutModel.orderDueDate || input.programDeliveryOutModel.orderDueDate == "") {
                input.programDeliveryOutModel.orderDueDate = '1900-01-01T00:00:00';
            }
            if (!input.programDeliveryOutModel.returnByDate || input.programDeliveryOutModel.returnByDate == "") {
                input.programDeliveryOutModel.returnByDate = '1900-01-01T00:00:00';
            }


            input.deliveryWeekOutModel = null;

            if (!input.orderingInfoOutModel.parentNotes)
                input.orderingInfoOutModel.parentNotes = " ";//Since it was a mandatory field we are saving it as with an empty string 


            props.onLoaderRaise(true, "Submitting program schedule info ....", { marginTop: "145px" });
            APIConnector.Post(CommonVariables.urls.SetProgramSchedule, input).then(function (resp) {
                props.onLoaderRaise(false);
                if (!CommonVariables.isError(resp))
                    return;
                Notifications.ShowSuccessMessage("Program Schedule submitted successfully");
                setcurrentStage(WhereAmI.TallySheet);
            });
        }
    }, [steps, isLastStep, step, lastStepIndex]);
    const onPrevClick = React.useCallback(event => {
        event.preventDefault();
        setStep(() => Math.max(step - 1, 0));
    }, [step, setStep]);

    const handleChange = (e) => {
        setStep(e.value);
    };

    //#region "     Validate All the Mandatory Fields           "
    const ValidateBeforeSubmit = (input) => {
        if (!input.programDeliveryOutModel.takeHomeDate || input.programDeliveryOutModel.takeHomeDate == "") {
            if (input.programDeliveryOutModel.orderType === 'OnlineOnly' || input.programDeliveryOutModel.orderType === "OnlineAndPaper") {
                Notifications.ShowWarningMessage("Please select Start date");
                setStep(1);
                return false;
            }
        }
        if (!input.programDeliveryOutModel.orderDueDate || input.programDeliveryOutModel.orderDueDate == "") {
            if (input.programDeliveryOutModel.orderType === 'OnlineOnly' || input.programDeliveryOutModel.orderType === "OnlineAndPaper" || input.programDeliveryOutModel.orderType === "PaperOnly") {
                Notifications.ShowWarningMessage("Please select End date");
                setStep(1);
                return false;
            }
        }
        if (!input.programDeliveryOutModel.returnByDate || input.programDeliveryOutModel.returnByDate == "") {
            if (input.programDeliveryOutModel.orderType === "OnlineAndPaper") {
                Notifications.ShowWarningMessage("Please select End Date");
                setStep(1);
                return false;
            }
        }
        return true;
    }
    //#endregion

    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion
    const programSheduledownload = () => {
        var obj = {
            "parameters": `&CustomerNumber=${userData.custnmbr}`, "reportname": CommonVariables.ConfigData.Reports.ProgramScheduleReport
        };
        props.onLoaderRaise(true, "Generating Report ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetReports, obj).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            PdfDownload("ProgramSchedule.pdf", resp.reportdata)
        });
    };
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }}>
        {currentStage === WhereAmI.ProgramSchedule &&
            (
                <div>
                    <Stepper value={step} items={steps} onChange={handleChange} />
                    {
                        programdata &&
                        <Form
                            initialValues={formState}
                            onSubmitClick={onStepSubmit}
                            render={formRenderProps =>
                                <div style={{ alignSelf: 'center' }} className="m-auto w-responsive">
                                    <FormElement className='w-full ml-5' style={{}}>
                                        {((steps[step].label === "Start" && <OrderType formState={formState} OrderFormTypechangeEvent={OrderFormTypechangeEvent} />) ||
                                            (steps[step].label === "Choose Schedule" && <ChooseSchedule formState={formState} />) ||
                                            (steps[step].label === "Pricing" && <ProgramSchedulePricing formState={formState} isStageVisited={isStageVisited} />) ||
                                            (steps[step].label === "Order Forms" && <OrderFormProof formState={formState} programdata={programdata} orderformschooladdress={orderformschooladdress} />) ||
                                            (steps[step].label === "Completed" && <Completed formState={formState} />))}

                                        <div id="footerID" style={{
                                            width: '100% !important',
                                            display: ((steps[step].label === "Choose Schedule" || steps[step].label === "Pricing" || steps[step].label === "Order Forms") ? "none" : "")
                                        }} className={'WizardFooter'}>
                                            <div style={{ textAlign: "center" }}>
                                                {step !== 0 ?
                                                    <Button id="btnProgramWizardPrevID"
                                                        className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                                        onClick={onPrevClick}>Previous
                                                    </Button> : undefined
                                                }
                                                <span className="step-text-class">Step {step + 1} of {steps.length}</span>
                                                <Button id="btnparentSubmit"
                                                    className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                                    onClick={formRenderProps.onSubmit}>
                                                    {isLastStep ? 'Submit' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </FormElement>
                                </div>
                            } />
                    }
                </div>
            )
        }
        {
            currentStage <= WhereAmI.GradeWorksheet &&
            (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <h5 className="wizardStepHeadLabel">
                        There are unapproved Grade Worksheets.Please approve it first
                    </h5>
                    <Link to="/externaldashboard/grades" style={{ display: "block", textDecoration: "underline" }}
                        className="text-decoration-none text-primary mt-4 rounded-0 mx-0">Go to Grade Worksheets
                    </Link>
                </div>
            )
        }
        {
            currentStage != WhereAmI.ProgramSchedule && currentStage > WhereAmI.GradeWorksheet &&
            (
                <div style={{ textAlign: "center", margin: "40px" }}>
                    <h5 className="wizardStepHeadLabel">
                        Thank you for completing all the steps to get your sale started.<br />
                        Your coordinator will review your submission and contact you if changes need to be made.<br />
                        If there are no corrections that need to be made to the dates and pricing information you provided,<br />
                        your coordinator will email you a copy of your Program Schedule and Grade Worksheets.
                        <div className="flex m-10" style={{ justifyContent: "center" }}>
                            <span className='mr-2 mt-1'>Click the  </span>
                            <button className='button-edit text-white mr-2' onClick={() => navigate("/externaldashboard")} >Dashboard</button>
                            <span className='mr-2 mt-1'>or</span>
                            <button className='button-delete hover-effect' onClick={CommonVariables.logout}>Log off</button>
                        </div>
                    </h5>
                    <div className="sok-tile" >
                        <DownloadIcon />
                        <div className="sok-tile-name" onClick={() => { programSheduledownload() }}>
                            ProgramSchedule.pdf
                        </div>
                    </div>
                </div>
            )
        }
    </div>;
};
export default ProgramScheduleWizard


