import React from 'react';
import Dashboardlayout from './Dashboardlayout';
import ExternalDashboardlayout from './ExternalDashboardlayout';
import Loginlayout from './Loginlayout';

const Layout = props => {


    if (window.location.pathname.includes('externaldashboard')) {

        return (
            <ExternalDashboardlayout RouterInfo={props} />
        )
    }

    else if (window.location.pathname.includes('dashboard')) {

        return (
            <Dashboardlayout RouterInfo={props} />
        )
    }
    else {
        return (
            <Loginlayout RouterInfo={props} />
        )
    }
};

export default Layout;
