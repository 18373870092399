import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "../Login/components/loginPage/loginpage";
import Forgotpassword from "../Login/components/forgotpassword";
import Terms from "../Login/components/terms";
import Privacypolicy from '../Login/components/privacypolicy';

/**
 * 
 * This component contains all login routes
 * 
 */


function Loginrouting(props) {
    return (
        <Routes>
            <Route index element={<Login onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/forgotpassword" element={<Forgotpassword onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/terms" element={<Terms onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/privacypolicy" element={<Privacypolicy onLoaderRaise={props.onLoaderRaise} />} />
        </Routes>
    );
}

export default Loginrouting;