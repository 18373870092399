import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import APIConnector from "../../../../apiconnector";
import CommonVariables from "../../../../variables";
import Notifications from "../../../../Notifications/Messages";


export const DropDownCell = (props) => {
    const { dataItem } = props;
    const field = props.field || "";
    const [dataValue, setDataValue] = React.useState(dataItem[field]);

    const localizedData = ["Open", "Revision Complete", "Un-Approved", "Approved"];

    const handleChange = (event) => {
        if (event.target.value === "Open") {
            Notifications.ShowWarningMessage("Open status is not allowed");
            return;
        }
        setDataValue(event.target.value)
        var obj = { ...dataItem, status: event.target.value }
        props.dataItem.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGrade, obj).then(function (response) {
            props.dataItem.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            Notifications.ShowSuccessMessage(response.message);
        });
    }
    return (
        <td>
            <DropDownList defaultValue="Select"
                value={dataValue}
                data={localizedData} onChange={handleChange} />
        </td>
    );
};