import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormInput, FormUpload, FormNumericTextBox, FormDropDownList } from "./form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";

export default function SchoolInfo(props) {
    return (
        <div className="kendo-form">
            <p className="font-weight-bold mt-3 d-block d-sm-none text-center">
                School Info
            </p>
            <br />
            <h5 className="wizardStepHeadLabel">
                Please review school information. Any changes you make will update our system directly and all materials related to the program (including printed materals and website).
            </h5>
            <div className="row text-xs tracking-wide font-bold">
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">School name</label>
                    <Field
                        key={"accountModel_Step1.name"}
                        id={"accountModel_Step1.name"}
                        name={"accountModel_Step1.name"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Statement name</label>
                    <Field
                        key={"accountModel_Step1.tt_StatementName"}
                        id={"accountModel_Step1.tt_StatementName"}
                        name={"accountModel_Step1.tt_StatementName"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Phone</label>
                    <Field
                        key={"accountModel_Step1.telephone1"}
                        id={"accountModel_Step1.telephone1"}
                        name={"accountModel_Step1.telephone1"}
                        maxlength="20"
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Grades</label>
                    <Field
                        key={"accountModel_Step1.tt_grades"}
                        id={"accountModel_Step1.tt_grades"}
                        name={"accountModel_Step1.tt_grades"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Enrollment</label>
                    <Field
                        key={"accountModel_Step1.tt_enrollment"}
                        id={"accountModel_Step1.tt_enrollment"}
                        name={"accountModel_Step1.tt_enrollment"}
                        component={FormNumericTextBox}
                        validator={requiredValidator}
                    />
                </div>
            </div>
            <br />
            <h5 className="wizardStepHeadLabel">School Address</h5>
            <div className="row text-xs tracking-wide font-bold">
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Street 1</label>
                    <Field
                        key={"accountModel_Step1.address1_line1"}
                        id={"accountModel_Step1.address1_line1"}
                        name={"accountModel_Step1.address1_line1"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Street 2</label>
                    <Field
                        key={"accountModel_Step1.address1_line2"}
                        id={"accountModel_Step1.address1_line2"}
                        name={"accountModel_Step1.address1_line2"}
                        component={FormInput}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">City</label>
                    <Field
                        key={"accountModel_Step1.address1_city"}
                        id={"accountModel_Step1.address1_city"}
                        name={"accountModel_Step1.address1_city"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">State</label>
                    <Field
                        key={"accountModel_Step1.tt_stateprovinceid"}
                        id={"accountModel_Step1.tt_stateprovinceid"}
                        name={"accountModel_Step1.tt_stateprovinceid"}
                        textField={"state_province_name"}
                        dataItemKey={"state_province_id"}
                        data={props.stateProvinceListModel}
                        component={FormDropDownList}
                        validator={requiredValidator}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label className="fieldlabel">Zip</label>
                    <Field
                        key={"accountModel_Step1.address1_postalcode"}
                        id={"accountModel_Step1.address1_postalcode"}
                        name={"accountModel_Step1.address1_postalcode"}
                        component={FormInput}
                        validator={requiredValidator}
                    />
                </div>
            </div>
        </div>
    );
};
