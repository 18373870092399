import React, { useEffect, useState } from 'react';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { NumericTextBox, TextBox, InputSuffix } from "@progress/kendo-react-inputs";

const GridContext = React.createContext({});
const ParentPriceCell = (props) => {
    const { itemChange } = React.useContext(GridContext);
    const ParentPriceChange = (e) => {
        var LatestPrice = 0.00;
        if (e.target.value != null) {
            LatestPrice = e.target.value;
        }
        LatestPrice = LatestPrice.toFixed(2);
        itemChange({ field: "parentPrice", value: LatestPrice, dataItem: props.dataItem })
    };

    return (
        <td>
            <NumericTextBox
                value={props.dataItem.parentPrice.toFixed(2)}
                onChange={(e) => {
                    ParentPriceChange(e)
                }}
            />
        </td>);
}

const GridOfferingItemCell = (props) => {
    const { itemChange } = React.useContext(GridContext);
    const handleChange = (e) => {
        itemChange({ field: "orderNote", value: e.target.value, dataItem: props.dataItem })
    }

    return (
        <td style={{margin:"16px 0px"}}>
            <TextBox value={props.dataItem.orderNote} onChange={(e) => handleChange(e)} maxLength={30} width="135" />
        </td>
    )
}




const BulkItems = (props) => {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        if (!props.formState.bulkItemsOutList)
            props.formState.bulkItemsOutList = [];
        props.formState.bulkItemsOutList = props.formState.bulkItemsOutList.map((item, index) => {

            if (!item.parentPrice) {
                item.parentPrice = 0;
            }

            item.parentPrice = Math.round(item.parentPrice * 100) / 100;
            item.profitAmount = (item.parentPrice - item.discountedItemPrice);
            if (!item.profitAmount)
                item.profitAmount = 0;
            item.profitAmount = Math.round(item.profitAmount * 100) / 100;

            if (!item.discount)
                item.discount = 0;

            if (item.discount > 1)
                item.discount = (item.discount / 100);

            item.discount = Math.round(item.discount * 10000) / 10000;


            return Object.assign(
                {
                    inEdit: true
                },
                item.BulkID = index,
                item
            )
        });
        setData(props.formState.bulkItemsOutList);
    }, []);


    const ProfitAmt = (props) => {
        return <> {props.dataItem.profitAmount < 0
            ? <td className='text-danger' >${props.dataItem.profitAmount.toFixed(2)}</td>
            : <td>${props.dataItem.profitAmount.toFixed(2)}</td>
        } </>
    }

    const itemChange = (event) => {
        if (event.value < 0)
            return;
        const field = event.field || "";
        if (field == "parentPrice") {
            event.value = Math.round(event.value * 100) / 100;
        }
        const newData = data.map((item) => {
            return item.BulkID === event.dataItem.BulkID
                ? {
                    ...item,
                    [field]: event.value,
                    ["profitAmount"]: (field === "orderNote" ? item.profitAmount : (Math.round((event.value - item.discountedItemPrice) * 100) / 100)),
                    changed: "true"
                }
                : item
        }
        );
        setData(newData);
        props.formState.bulkItemsOutList = newData;
    };

    return (<div>
        <label className="Headlabel">
            Enter the pricing you would like to charge for the optional bulk items.
            <u>Bulk items are only available for purchase online.</u> If you're running a paper and online sale,
            parents will need to purchase bulk items online. Please put the grade(s) that will need this item.  If all grades need the item, then type "ALL Grades."
        </label>
        <div>
            <GridContext.Provider value={{ itemChange }}>
                <Grid data={data} editField="inEdit" resizable={true} dataItemKey={"BulkID"} onItemChange={itemChange}
                    style={{ height: (window.innerHeight - 450) + "px" }}>
                    <Column field="custnmbr" title="Acct #" width="80" editable={false} />
                    <Column field="grade" title="Grade" width="70" editable={false} />
                    <Column field="type" width="65" title="Type" editable={false} />
                    <Column field="itemNumber" width="120" title="Item#" editable={false} />
                    <Column field="itemDescription" width="160" title="Item Description" editable={false} />
                    <Column field="itemPrice" width="95" title="Item Price (Shipping included)" editable={false} format="{0:c2}" />
                    <Column field="discount" title="Discount" width="85" editable={false} format="{0:p2}" />
                    <Column field="discountAmount" title="Discount Amount" width="90" editable={false} format="{0:c2}" />
                    <Column field="discountedItemPrice" title="Discounted Item Price" width="100" editable={false} format="{0:c2}" />
                    <Column field="parentPrice" title="Parent Price" editor="numeric" min="0" width="130" cell={ParentPriceCell} />
                    <Column field="profitAmount" title="Profit Amount" format="{0:c2}" width="110" editable={false} cell={ProfitAmt} />
                    <Column field="orderNote" width="135" title="Grade offering item(s) to" editor="text" cell={GridOfferingItemCell} />
                </Grid>
            </GridContext.Provider>
        </div>
    </div>);
};

export default BulkItems;