import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormInput, FormUpload, FormDatePicker } from "./form-components";
import {
    userNameValidator,
    emailValidator,
    passwordValidator,
    requiredValidator,
} from "./validators";
export const TaxInfo = (
    <div className="kendo-form">
        <p className="font-weight-bold mt-3 d-block d-sm-none text-center">
            Tax Info
        </p>
        <br />
        <h5 className="wizardStepHeadLabel">
            State law mandates SchoolKidz have a hard copy of your school's Tax Exempt Certificate on file.
            <br />Please email your certificate to your coordinator before the start of your sale.
            <br />We can't process your order(s) until we have this certificate on file.
            <br />All online orders will be charged sales tax in all states except for Delaware, Montana, New Hampshire and Oregon
        </h5>
        <div className="row text-xs tracking-wide font-bold">
            <div className="col-12 col-md-6">
                <label className="fieldlabel">State tax exempt code</label>
                <Field
                    key={"taxExemptionModel_Step5.tt_taxexemptinfo"}
                    id={"taxExemptionModel_Step5.tt_taxexemptinfo"}
                    name={"taxExemptionModel_Step5.tt_taxexemptinfo"}
                    maxlength="25"
                    component={FormInput}
                />
            </div>
            <div className="col-12 col-md-6">
                <label className="fieldlabel">Expiration date</label>
                <Field
                    key={"taxExemptionModel_Step5.tt_taxexcemptexpirationdate"}
                    id={"taxExemptionModel_Step5.tt_taxexcemptexpirationdate"}
                    name={"taxExemptionModel_Step5.tt_taxexcemptexpirationdate"}
                    maxlength="25"
                    component={FormInput}
                    validator={requiredValidator}
                />
            </div>
        </div>
        <br />
        <br />
    </div>
);
