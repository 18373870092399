import React, { useEffect, useState } from 'react';
import APIConnector from '../../../apiconnector'
import "./Navbar.css";
import $ from 'jquery';
import SKLogo from '../../../images/SKLogo.png';
// import SKSLogo from '../../../images/SKSLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import CommonVariables from '../../../variables';
import Notifications from '../../../Notifications/Messages';
import userImage from '../../../images/user.png';
import userBig from '../../../images/userBig.png';
import Back from '../Images/Back.png';

function Navbar(props) {
    const link = window.location.pathname;
    const linkTo = link.slice(0, link.lastIndexOf('/'));
    var video = document.querySelector("video");
    const player = document.getElementById('player');
    $(".askchoice").addClass("hidden");
    const navigation = useNavigate();
    const [userData, setUserData] = useState({ userName: "" });
    const Imptoken = CommonVariables.getlocalstorageData().ImpersonationToken;
    const [profileView, setProfileView] = useState(false);

    useEffect(function () {
        let input = {
            userId: localStorage.getItem("userId"),
        };
        props.RouterInfo.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
            props.RouterInfo.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            if (resp.userName) {
                setUserData(resp);
            }
        });
       

    }, []);

    function logout() {
        var userId = localStorage.getItem("userId");
        var windowUrl = window.location.pathname;
        //data = {
        //    user: userId,
        //    history: windowUrl
        //}
        ////APIConnector.Post(CommonVariables.urls.SetLastVisitedUrl, data)
        ////    .then(function (response) {
        ////    })
        localStorage.clear();
        navigation("/");
    }
    function buttonHandler() {
        setProfileView(!profileView);
    }
    function ImpersonationBack() {
        var impToken = CommonVariables.getlocalstorageData().ImpersonationToken;
        if (impToken == null) {
            Notifications.ShowErrorMessage("No Impersonation User is there")
        }
        else {
            var impUserId = CommonVariables.getlocalstorageData().ImpersonationUserId;
            localStorage.clear();
            CommonVariables.setlocalStorageData({ token: impToken, userId: impUserId });
            Notifications.ShowWarningMessage("you are going back to original Account")
            navigation("/dashboard");
            window.location.reload(true);
        }
    }
    var constraints = {
        video: true,
    };
    function stopBothVideoAndAudio() {
        var video = document.querySelector("video");
        $(".askchoice").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: false })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    /***************************************/
    function capture() {
        $("#player").addClass("hidden")
        $("#retake").removeClass("hidden")
        $(".showCanvasAndBtn").removeClass("hidden")
        const player = document.getElementById('player');
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            // video.srcObject = stream;
            player.srcObject = stream;
            // video.srcObject = stream;
        });
        // Draw the video frame to the canvas.
        context.drawImage(player, 0, 0, canvas.width, canvas.height);

        const imageurl = canvas.toDataURL();
        var UpdateProfile = {
            userId: CommonVariables.getlocalstorageData().userId,
            image: imageurl,
        };
        debugger;
        document.querySelector(".uploadBtn").addEventListener("click", () => {
            var userdata = {
                userId: localStorage.getItem("userId"),
            };
            props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
            APIConnector.Post(CommonVariables.urls.getUserById, userdata).then(function (resp) {
                props.RouterInfo.onLoaderRaise(false);
                if (!CommonVariables.isError(resp))
                    return;
                if (resp.userName) {
                    setUserData(resp);
                }
                var payload = userData;
                payload.image = UpdateProfile.image;
                props.RouterInfo.onLoaderRaise(true, "Uploading image", { marginTop: "145px" });
                APIConnector.Post(CommonVariables.urls.UpdateProfile, payload).then(function (response) {
                    props.RouterInfo.onLoaderRaise(false);
                    if (!CommonVariables.isError(response))
                        return;
                    if (response.isSuccess) {
                        stopBothVideoAndAudio();
                        Notifications.ShowSuccessMessage("Image uploaded Successfully")
                        Notifications.ShowSuccessMessage("Please Login again to effect the changes");
                        window.location.reload(true);
                    }
                    else {
                        Notifications.ShowErrorMessage("Some error occured, Try again")
                    }
                })
            });
            setProfileView(false);
        });
    };
    function takePicture() {
        var video = document.querySelector("video");
        $(".askchoice").addClass("hidden");
        $(".video").removeClass("hidden");
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    video.srcObject = stream;
                    window.localStream = stream;
                })
                .catch(function (err0r) {
                });
        }
    };
    function retake() {
        $("#player").removeClass("hidden")
        $("#canvas").addClass("hidden")
    };


    function uploadPhoto() {
        document.querySelector(".uploaded-image").onchange()
    };


    /*****************************************/
    return (
        <div className=''>
            <div className=''>
                <div className="navbar fixed mr-0 row navigation-bar pt-1">
                    <div className="col-12 col-sm-6  col-md-6 col-lg-2 flex">
                        <img onClick={() => navigation('/dashboard')} className="mx-auto bg-white p-1 my-0 hover:cursor-pointer"  src={SKLogo} alt="SchoolKidz and School Specialty Logo"/>
                        {/* <img onClick={() => navigation('/dashboard')} className="mx-auto bg-white p-1 my-0 hover:cursor-pointer" src={SKSLogo} alt="School Specialty Logo"/> */}
                    </div>
                    <div className="col-12 col-sm-6 col-md-5 col-lg-4 mt-3 d-flex justify--center justify-content-md-end">
                        <div className="flex">
                            <div className="profile-div py-0 inline-block">
                                <button className="profile-button" onClick={buttonHandler}>
                                    <img
                                        className="inline-block profile-img rounded-circle"
                                        //  src={pfp}
                                        src={userData.image != null ? userData.image : userImage}
                                        alt="Account"
                                        style={{
                                            width: ((userData.image === null) ? "80px !important" : "10rem !important"),
                                            height: ((userData.image === null) ? "80px !important" : "10rem !important")
                                        }}
                                    />
                                    &ensp;
                                    {(userData &&
                                        <div className="inline align-middle">{userData.firstName + " " + userData.lastName}</div>)}
                                </button>
                                &emsp;
                            </div>
                        </div>
                    </div>
                </div>
                {profileView && (
                    <div  className='d-flex justify-content-center justify-content-sm-end text-center font-weight-bold text-light mr-3'>
                        <div id="profile" className="profile-view shadow-lg justify-content-center position-absolute bg-white px-3 pb-3">
                            <img className="profile-img-big p-2 mx-auto rounded-circle"
                                src={userData.image != null ? userData.image : userImage} alt="Account"
                                style={{
                                    width: ((userData.image === null) ? "80px !important" : "10rem !important"),
                                    height: ((userData.image === null) ? "80px !important" : "10rem !important")
                                }}
                            />
                            <div className=''>
                                <p className='paragraph font-bold'>{userData.firstName + " " + userData.lastName}</p>
                                <div className='flex'>
                                    <Link to="dashboard/profile" onClick={buttonHandler} className='button-edit text-white'>Profile</Link>
                                    &emsp;
                                    <button className='button-delete hover-effect' onClick={logout}>Sign out</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="m-2 route-to-dashboard">
                <Link to={linkTo} className='justify-evenly'>
                    <button className="BackToDashboard">
                        <img src={Back} alt="back" />
                        <span className="ml-2">Dashboard</span>
                    </button>
                </Link>
            </div>
        </div>
    );
}
export default Navbar;