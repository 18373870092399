import React, { useEffect, useState } from 'react';
import { Field, FormElement, Form } from '@progress/kendo-react-form';
import { requiredValidator } from '../validators';
import { FormInput, DropDown, DatePic } from "../form-components";
import { FormDatePicker } from "../../SchoolInfoWizard/form-components";
import { Stepper } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import $ from "jquery";
import moment from 'moment';
import Pricing from './pricing';
import AdditionalItems from './additionalItems';
import BulkItems from './BulkItems';
import Notifications from "../../../../Notifications/Messages.js";



const ProgramSchedulePricing = (props) => {
    const [pricingformState, setPricingformState] = React.useState(props.formState);
    const [Pricingstep, setPricingstep] = React.useState(0);
    const stepPricingSteps = [Pricing];
    let numberOfSteps = [{
        label: 'Price Kits',
        isValid: undefined,
        key: 1
    }];
    if (props.formState.programDeliveryOutModel.orderType != 'PaperOnly') {
        numberOfSteps.push({
            label: 'Price Additional Items',
            isValid: undefined,
            key: 2
        });
        stepPricingSteps.push(AdditionalItems);
    }
    if (props.formState.bulkItemsOutList && props.formState.bulkItemsOutList.length > 0) {
        numberOfSteps.push({
            label: 'Bulk Items',
            isValid: undefined,
            key: 3
        });
        stepPricingSteps.push(BulkItems);
    }

    const [pricingSteps, setpricingSteps] = React.useState(numberOfSteps);

    const pricinglastStepIndex = pricingSteps.length - 1;
    const isPricingLastStep = pricinglastStepIndex === Pricingstep;


    const onPricingstepSubmit = React.useCallback(event => {
        if (Pricingstep === pricinglastStepIndex) {
            if (pricingformState.bulkItemsOutList && pricingformState.bulkItemsOutList.length > 0) {
                if (pricingformState.bulkItemsOutList.filter(function (each) { return !each.orderNote || each.orderNote.trim() === '' })?.length > 0) {
                    Notifications.ShowErrorMessage("Please enter 'Grade offering item(s) to'");
                    return;
                }
            }
        }

        const {
            isValid,
            values
        } = event;

        const currentSteps = pricingSteps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === Pricingstep ? isValid : currentStep.isValid
        }));

        setpricingSteps(currentSteps);

        setPricingstep(() => Math.min(Pricingstep + 1, pricinglastStepIndex));

        if (isPricingLastStep) {
            props.formState.additionalItemsOutList = pricingformState.additionalItemsOutList;
            props.formState.readKitPricingOutList = pricingformState.readKitPricingOutList;
            props.formState.bulkItemsOutList = pricingformState.bulkItemsOutList;
            $("#btnparentSubmit").click();
        }
    }, [stepPricingSteps, isPricingLastStep, Pricingstep, pricinglastStepIndex]);

    const onPricingPrevClick = React.useCallback(event => {
        event.preventDefault();
        if (Pricingstep === 0) {
            $("#btnProgramWizardPrevID").click();
        }
        else {
            setPricingstep(() => Math.max(Pricingstep - 1, 0));
        }

    }, [Pricingstep, setPricingstep]);

    const handleChangePricing = (e) => {
        setPricingstep(e.value);
    };

    return <>
        <br />
        <label className="wizardStepHeadLabel">
            Pricing section
        </label>
        <div style={{
            display: 'flex'
        }}>
            <Stepper value={Pricingstep} onChange={handleChangePricing} key={Pricingstep} items={pricingSteps} style={{ width: "15%" }}
                className="mb-auto"
                orientation={"vertical"} />
            <div className="mt-auto pb-4">
                <Form initialValues={pricingformState} onSubmitClick={onPricingstepSubmit} render={formRenderProps =>
                    <div>
                        <FormElement className='ml-10 mb-auto' style={{}}>
                            {
                                (pricingSteps[Pricingstep].label === "Price Kits" && <Pricing formState={pricingformState} isStageVisited={props.isStageVisited} />) ||
                                (pricingSteps[Pricingstep].label === "Price Additional Items" && <AdditionalItems formState={pricingformState} isStageVisited={props.isStageVisited} />) ||
                                (pricingSteps[Pricingstep].label === "Bulk Items" && <BulkItems formState={pricingformState} isStageVisited={props.isStageVisited} />)
                            }

                            <span style={{ marginTop: '40px' }} className={'k-form-separator'} />

                            <div id="PricingfooterID" style={{ width: "-webkit-fill-available" }}
                                className={'ProgramScheduleSubWizardFooter'}>
                                <div>
                                    <Button id="PricingPrev" className="step-prev-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={onPricingPrevClick}>Previous
                                    </Button>
                                    <span className="step-text-class">Step {Pricingstep + 1} of {pricingSteps.length}</span>
                                    <Button id="PricingNext" className="step-next-button k-button-md k-button-rectangle k-button-solid ki-rounded-md"
                                        onClick={formRenderProps.onSubmit}>
                                        {isPricingLastStep ? 'Next Step' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    </div>
                } />
            </div>
        </div>
    </>;
};
export default ProgramSchedulePricing;