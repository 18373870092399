import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import Notifications from "../../../Notifications/Messages";
import { formatDate } from '../../components/commonfunctions';
import PreviewIcon from '@mui/icons-material/Preview';
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import RefreshIcon from '@mui/icons-material/Refresh';
import ViewErrorLog from "../ErrorModule/ViewErrorLog";


/**
 * This component logs the errors encountered by different users 
 * 
 */
const initialDataState = {
    skip: 0,
    take: 1000,
  };
const ErrorLogs = props => {
    const initialFilter = {
        logic: "and",
        filters: [],
    };

    const [page, setPage] = React.useState(initialDataState);
    const pageChange = (event) => {
      setPage(event.page);
    };
    const [filter, setFilter] = React.useState(initialFilter);
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState();
    //#region "     On Load of Component    "
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        RefreshGrid();
    }, []);

    function RefreshGrid() {
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetErrorLogs).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setData(response);
        });
    };
    //#endregion


    const EditCell = (props) => {
        const { dataItem } = props
        return (
            <td colSpan="1" className="k-grid-edit-cell" aria-colindex="9" aria-selected="false" data-grid-col-index="8" role="gridcell" style={{ textAlign: "center" }}>
                <span title="View detailed Error">
                    <PreviewIcon onClick={() => props.enterEdit(dataItem)} title="edit" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                </span>
            </td>
        );
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };

    //#region "     Edit Functionality      "
    const Edit = (props) => (
        <EditCell
            {...props}
            enterEdit={enterEdit}
        />
    );
    const DateCellTimeStamp = ({ dataItem }) => {
        return (
            <td colSpan="1" role="gridcell" aria-colindex="7" aria-selected="false" data-grid-col-index="6">
                {formatDate(dataItem.originDate)}
            </td>
        );
    };

    //#endregion

    //#region "      grid      "
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 215) + "px" }}
            data={filterBy(data.slice(page.skip, page.take + page.skip), filter)}
            sortable={true}
            pageable={true}
            take={page.take}
            skip={page.skip}
            total={data.length}
            onPageChange={pageChange}
            editField="inEdit"
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}>
            <Column field="userName" filterable={true} title="User" width={300} editable={false} />
            <Column field="originDate" cell={DateCellTimeStamp} width={170} title="Timestamp" editable={false} />
            <Column field="exception" filterable={true} title="Message" width={950} editable={false} />
            <Column cell={Edit} title="View" filterable={false} width={100} editable={false} />
        </Grid>
    );
    //#endregion

    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">Error Logs</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-10">
                </div>
                <div className="col-sm-2" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshGrid} style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
            {openForm && (
                <ViewErrorLog cancelEdit={handleCancelEdit} item={editItem} onLoaderRaise={props.onLoaderRaise} />
            )}
        </div>);
};

export default ErrorLogs;