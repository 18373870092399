import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
// ES2015 module syntax
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import APIConnector from "../../../../apiconnector";
import CommonVariables from "../../../../variables";
import Notifications from "../../../../Notifications/Messages";


const EditQuoteRevise = (props) => {


    const [dialogDimension, setDialogDimension] = React.useState([])
    const [gridData, setGridData] = React.useState([]);
    const responseNotes = React.useRef(null);


    React.useEffect(() => {
        var windowheight = window.innerHeight;
        var windowwidth = window.innerWidth;

        var neededheight = windowheight - (windowheight / 5)
        var neededwidth = windowwidth - (windowwidth / 10)

        setDialogDimension([neededheight, neededwidth]);
        let inputJson = {
            CUSTNMBR: props.item.custnmbr,
            Grade: props.item.grade,
            Type: props.item.type
        };
        if (!props.item.assigned_to_name || props.item.assigned_to_name.toLowerCase() == "Open/Unassigned".toLowerCase()) {
            responseNotes.current.value = "";
        }
        else {
            responseNotes.current.value = props.item.response_note;
        }
        
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetQuoteReviseById, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setGridData(response);
            responseNotes.current.focus();
        });
    }, []);

    const handleSubmit = () => {
        const obj = {
            rowid: props.item.rowid,
            CUSTNMBR: props.item.custnmbr,
            Grade: props.item.grade,
            Status: "Revision Complete",
            Type: props.item.type,
            assigned_to_user: props.item.assigned_to_user,
            assigned_to_name: props.item.assigned_to_name,
            response_note: responseNotes.current.value,
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGrade, obj).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;

            props.cancelEdit("Grade Completed Successfully");
        });
    };


    var rowitem = Object.assign({}, props.item);
    rowitem.tt_needbydate = new Date(rowitem.tt_needbydate);
    rowitem.tt_requestdate = new Date(rowitem.tt_requestdate);
    var dropdown = ["Completed", "In Progress/Assigned", "Open/Unassigned"];

    return (
        <Dialog
            className="w-screen"
            title={`Edit Quote`}
            onClose={props.cancelEdit}
            width={dialogDimension[1]}
        >
            <Form
                className="rounded-t-2xl w-screen flex"
                onSubmit={props.onSubmit}
                initialValues={rowitem}
                render={(formRenderProps) => (
                    <FormElement className="quote-modal rounded-t-2xl font-normal w-full" style={{}}>
                        <fieldset className="k-form-fieldset grid gap-6">
                            <div className="mb-3">
                                <Label className="fieldlabel">Response Notes</Label>
                                <textarea style={{ "width": "100%", "height": "85%", border: "2px Solid #000000", borderRadius: "2px" }} ref={responseNotes}
                                    placeholder="Enter your response notes ..." />
                            </div>

                        </fieldset>
                        <fieldset className="k-form-fieldset grid grid-cols-1 gap-6">

                            <Grid
                                className=" rounded-2xl border-b-4"
                                resizable={true}
                                sortable={true}
                                pageable={true}
                                take={100}
                                data={gridData}
                                style={{ height: (window.innerHeight - 270) + "px" }}
                            >
                                <Column field="custnmbr" width="110" title="Customer #" />
                                <Column field="grade" width="80" title="Grade" />
                                <Column field="type" width="110" title="Type" />
                                <Column field="itemnmbr" width="175" title="Original Item Number" />
                                <Column field="itemdesc" title="Description" />
                                <Column field="cmptitnm" width="100" title="Item" />
                                <Column field="cmpituom" title="Item UOM" />
                                <Column field="cmpitqty" width="110" title="Original Qty" />
                                <Column field="request_type" width="160" title="Requested Change" />
                                <Column field="new_qty" width="110" title="Revised Qty" />
                                <Column field="new_item"  title="New Item Info" />
                                {/* <Column cell={MyEditCommandCell} /> */}
                            </Grid>

                        </fieldset>
                        <div className="k-form-buttons pb-3">
                            <button
                                className="button-save"
                                onClick={handleSubmit}
                            >
                                Revision Complete
                            </button>
                            <button
                                type={"submit"}
                                className="button-cancel"
                                onClick={props.cancelEdit}
                            >
                                Cancel
                            </button>
                        </div>
                    </FormElement>
                )}
            />
        </Dialog>
    );
};

export default EditQuoteRevise;
