import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownCell } from "./MyDropDownlist.js";
import APIConnector from "../../../../apiconnector.js";
import CommonVariables from "../../../../variables";
import { filterBy } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter } from "@progress/kendo-react-data-tools";
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import PrintIcon from '@mui/icons-material/Print';

/**
 * This component enables to edit the status of the school Grade.
 */


const SchoolGrade = (props) => {

    //#region " Initialization of State  Values       "
    const initialFilter = {
        logic: "and",
        filters: [{
            field: "custnmbr",
            operator: "contains",
             value: '',
        }]
    };

    
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState(initialFilter);
    //#endregion
    const initialSort = [
        {
            field: "grade",
            dir: "asc",
        },
    ];
   

    const [sort, setSort] = React.useState(initialSort);

    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport) {
            gridPDFExport.save(data);
        }
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion

    //#region "     On Load         "
    React.useEffect(() => {
        refreshSchoolGradeGrid();
    }, []);

    

    const refreshSchoolGradeGrid = (msg) => {
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetSchoolGrades).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            let newData = response.map((item) => {
                item.onLoaderRaise = props.onLoaderRaise;
                //if (!item.status || item.status == '')
                //    item.status = 'Open';
                return item;
            });
            setData(newData.sort((a, b) =>  a.custnmbr - b.custnmbr ||a.grade.localeCompare(b.grade) ));
        });
    };
    //#endregion
    const grid = (
        <Grid className="rounded-2xl border-b-4" style={{ height: (window.innerHeight - 317) + "px" }}
            data={filterBy(data, filter)}
            sortable={true}
            sort={sort}
            pageable={true}
            take={3000}
            total={data.length}
            resizable={true}
            onFilterChange={(e) => setFilter(e.filter)}            
            onSortChange={(e) => {
                setSort(e.sort);
              }}
        >
            <Column field="custnmbr" title="Account number" width="330px" filterable={true} />
            <Column field="grade" title="Grade" width="250px" filterable={true} />
            <Column field="type" title="Type" width="250px" filterable={true} />
            <Column field="status" title="Status" width="340px" cell={DropDownCell} filterable={true} />
        </Grid>);
    return (
        <div className="PageStyle">
            <h2 className="inline-block pt-1 primary-heading">School setup</h2>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-7">
                    <Filter
                        value={filter}
                        onChange={(e) => setFilter(e.filter)}
                        fields={[
                            {
                                name: "custnmbr",
                                label: "Account Number",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "grade",
                                label: "Grade",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "type",
                                label: "Type",
                                filter: TextFilter,
                                operators: Operators.text,
                            },
                            {
                                name: "status",
                                label: "Status",
                                filter: TextFilter,
                                operators: Operators.text,
                            }
                        ]}
                    />
                </div>
                <div className="col-sm-5" style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print
                        <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Excel" style={{ marginRight: "15px", font: "caption" }}>Export to Excel
                        <img onClick={exportExcel}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABqUlEQVRIidWVv0sjURDHP7MmJxjBVpOAYUUE8T+w8Ed5tdUVlgdWgnHxrFyvEnNXWlhYWVlZ6T8gXC9Y2GQvniZBG/HACxeTzBWbQjYv2eda3bd8uzOfmfk+3sD/LokeuPv1j4geAvnBobobeDk/DuD0Ii2SCxWQHbdUTQCIrRxosaBKEELqe28FxCr4kv0lHZZCiG4NgiQCvAWSGGALeRfABIl+TyVJ6pZqGj3rue9dWXUgsB54WQGugBfaTKKs2MRaARS2s35tRNFDRI4Dd6KK8NUm1nZE48OjsqZDf4/opC/cm9oqyKxNoLXJorpZeSr8/lnMX4L0mNlP9iaLnBeofMgcPKQaf1pnwAyAODpfLuZ+9A2LHphuCNAQR2dQZ7mDpoebndNm2ikDY13z+8pyRLpf3sjeqeqmKN51M/cowrdoYabiLEck01Pf6ycKcwBupt5WU58G2Xrw6XXCuLm/VqKnwjY5mE2+JWYnPOOM3nvjz+HCkR3zX+HG6+lARD4Dt4MAI6JHi76mwpWpu/2SGztIIrdU3QLpPtXiB96EAfp+iG/a0f8Ajj+0Auj2X9kAAAAASUVORK5CYII="
                           alt="Export to Excel" style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }}
                        />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={refreshSchoolGradeGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
            {grid}
            <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                <Column field="custnmbr" title="Account Number" />
                <Column field="grade" title="Grade" />
                <Column field="type" title="Type" />
                <Column field="status" title="Status" />
                {grid}
            </GridPDFExport>
            <ExcelExport data={data} ref={(exporter) => { _export = exporter; }}>
                <Column field="custnmbr" title="Account Number" />
                <Column field="grade" title="Grade" />
                <Column field="type" title="Type" />
                <Column field="status" title="Status" />
            </ExcelExport>
        </div>
    );
};

export default SchoolGrade;